import {
	DateTimeFormatter,
	LocalDateTime,
	Instant,
	convert,
} from '@js-joda/core';
import moment from 'moment';

//-----------------------------
// FECHAS fns

export function getDate(date) {
	let _date = date;
	if (date && !(date instanceof Date)) _date = new Date(date);
	return _date;
}

export function getLocalDateNow() {
	return LocalDateTime.ofInstant(Instant.now());
}

export const sortArrayByKey = (key) => (a, b) => a[key] > b[key] ? 1 : -1;

export const removeLoginFromLabel = (str) => str.replace(/ *\([^)]*\) */g, '');

export function parseFullDate(date) {
	if (!date) return '';
	return (
		date.getFullYear() +
		'-' +
		('0' + (date.getMonth() + 1)).slice(-2) +
		'-' +
		date.getDate()
	);
}

export function parseDateTimeFromString(date, pattern = 'dd/MM/yy - HH:mm:ss') {
	if (!date) return '';
	const dateParsed = LocalDateTime.parse(date);
	return dateParsed.format(DateTimeFormatter.ofPattern(pattern));
}

export function mergeDateAndTime(dateString, timeString) {
	const datestr = dateString.split('T');
	return datestr[0] + 'T' + timeString + ':00';
}

export function spanishDate(dateString, wDay) {
	if (!dateString) return '';
	const ldt = LocalDateTime.parse(dateString);
	const jsDate = convert(ldt).toDate();
	var options = wDay
		? { weekday: 'long', month: 'long', day: 'numeric' }
		: { month: 'long', day: 'numeric' };
	return jsDate.toLocaleDateString('es-ES', options);
}

export function spanishLongDate(dateString) {
	if (!dateString) return '';
	const ldt = LocalDateTime.parse(dateString);
	const jsDate = convert(ldt).toDate();
	return jsDate.toLocaleDateString('es-ES', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	});
}

export function compareHours(minTo, maxTo) {
	const date1 = new Date(minTo);
	const date2 = new Date(maxTo);
	return date1 > date2 ? true : false;
}

export function strToTimestamp(dateString) {
	return dateString + 'T00:00:00';
}

export const isBeforeTime = (startTime, endTime) => {
	return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
};

//-----------------------------
// DEFAULTs

export const IMAGE_TYPES_ALLOWED =
	'image/png, image/gif, image/jpeg, image/jpg';

export const SEAT_TYPES = [
	{ label: 'Texto', value: 'text' },
	{ label: 'Asiento', value: 'seat' },
	{ label: 'Espacio', value: 'space' },
	{ label: 'Pantalla', value: 'screen' },
];

export const EVENT_TYPES = [
	{ label: 'Anunciantes', value: '1' },
	{ label: 'Premiere', value: '0' },
];

export const SI_NO_OPTS = [
	{ label: 'Si', value: '1' },
	{ label: 'No', value: '0' },
];

export const ASIST_OPT_TEXT = [
	{ label: 'Han asistido', value: 'si' },
	{ label: 'No han asistido', value: 'no' },
];

export const USER_ROLE = {
	rol_event: 'Eventos',
	rol_contact: 'Contactos',
	rol_sites: 'Lugares',
	admin_auditor: 'Auditoría',
	admin_users: 'Usuarios',
};

//-----------------------------
// FILTROS

//filtros invitaciones
export const defInvitationFilter = [
	{ label: 'Enviada', value: 'sent' },
	{ label: 'En borrador', value: 'draft' },
	{ label: 'Sin invitación', value: 'none' },
];

//filtros entradas
export const defEntradasFilter = [
	{ label: 'Enviada', value: 'enviada' },
	{ label: 'En borrador', value: 'borrador' },
	{ label: 'Sin entrada', value: 'none' },
];

//filtros respuesta
export const defRespuestaFilter = [
	{ label: 'Confirmado', value: 'confirmada' },
	{ label: 'Pendiente', value: 'pendiente' },
	{ label: 'Rechazado', value: 'rechazada' },
];

//filtros invitados
export const defInvitadosPhoto = [
	{ label: 'Con photocall', value: true },
	{ label: 'Sin photocall', value: false },
];

export const defInvitadosSitting = [
	{ label: 'Con sitting', param: 'sitting', value: true },
	{ label: 'Sin sitting', param: 'sitting', value: false },
	{ label: 'Asignados en mapa', param: 'asignadosEnMapa', value: true },
	{ label: 'Pendientes asignar', param: 'pendientesAsignar', value: true },
];

export const filtroInvitadosToolbar = [
	{ label: 'Con sitting', param: 'sitting', value: true },
	{ label: 'Sin sitting', param: 'sitting', value: false },
];

export const defInvitadosInvitacion = [
	{ label: 'Abierta', value: 'open' },
	{ label: 'No abierta', value: 'notopen' },
	{ label: 'Rebotada', value: 'bounced' },
];

export const defInvitadosAsist = [
	{ label: 'Observaciones', param: 'observaciones', value: true },
	{ label: 'Entrada enviada', param: 'entrada', value: 'enviada' },
	{ label: 'Entrada no enviada', param: 'entrada', value: 'noenviada' },
	// { label: 'Entrada en borrador', param: 'entrada', value: 'borrador' },
	{ label: 'Entrada modificada', param: 'entrada', value: 'modificada' },
];

//filtros notificaciones
export const defNotificationFilter = [
	{ label: 'Enviada', value: 'sent' },
	{ label: 'En borrador', value: 'draft' },
	{ label: 'Sin invitación', value: 'none' },
];

//-----------------------------
// COLOR CLASS fns

export const COLORS_NOCOLOR = [
	'transparent',
	'#EC108A',
	'#FF60B2',
	'#BC60E6',
	'#8E2BA0',
	'#671C59',
	'#2950BA',
	'#1376A5',
	'#3EBAD8',
	'#09727E',
	'#288B69',
	'#2BA000',
	'#6DD845',
	'#95B213',
	'#B1961C',
	'#FFB134',
	'#FF6F2F',
	'#D90A44',
	'#981024',
	'#9B9B9B',
	'#464646',
];

export const COLORS_PLAIN = [
	'#EC108A',
	'#FF60B2',
	'#BC60E6',
	'#8E2BA0',
	'#671C59',
	'#2950BA',
	'#1376A5',
	'#3EBAD8',
	'#09727E',
	'#288B69',
	'#2BA000',
	'#6DD845',
	'#95B213',
	'#B1961C',
	'#FFB134',
	'#FF6F2F',
	'#D90A44',
	'#981024',
	'#9B9B9B',
	'#464646',
];

export const colorToClass = (color) => {
	switch (color) {
		case '#EC108A': return 'magenta';
		case '#FF60B2': return 'rose';
		case '#BC60E6': return 'lilac';
		case '#8E2BA0': return 'purple';
		case '#671C59': return 'eggplant';
		case '#2950BA': return 'electricblue';
		case '#1376A5': return 'airforce';
		case '#3EBAD8': return 'skyblue';
		case '#09727E': return 'bluishgreen';
		case '#288B69': return 'turquoise';
		case '#2BA000': return 'greenforest';
		case '#6DD845': return 'green';
		case '#95B213': return 'limegreen';
		case '#B1961C': return 'oldgold';
		case '#FFB134': return 'mustard';
		case '#FF6F2F': return 'orange';
		case '#D90A44': return 'red';
		case '#981024': return 'garnet';
		case '#9B9B9B': return 'gray';
		case '#464646': return 'charcoalgray';
		default: return 'transparent';
	}
};

export const classCssToColor = (css) => {
	switch (css) {
			case 'magenta': return '#EC108A';
			case 'rose': return '#FF60B2';
			case 'lilac': return '#BC60E6';
			case 'purple': return '#8E2BA0';
			case 'eggplant': return '#671C59';
			case 'electricblue': return '#2950BA';
			case 'airforce': return '#1376A5';
			case 'skyblue': return '#3EBAD8';
			case 'bluishgreen': return '#09727E';
			case 'turquoise': return '#288B69';
			case 'greenforest': return '#2BA000';
			case 'green': return '#6DD845';
			case 'limegreen': return '#95B213';
			case 'oldgold': return '#B1961C';
			case 'mustard': return '#FFB134';
			case 'orange': return '#FF6F2F';
			case 'red': return '#D90A44';
			case 'garnet': return '#981024';
			case 'gray': return '#9B9B9B';
			case 'charcoalgray': return '#464646';
			default: return 'transparent';
	}
};

export const invitationSentClass = (status) => {
	switch (status) {
		case 1:
			return 'st-enviada';
		case 2:
			return 'st-rebotada';
		case 3:
			return 'st-abierta';
		case 4:
			return 'st-sinabrir';
		default:
			return 'st-sinabrir';
	}
};

export const statusSentClass = (statusRaw) => {
	let status = statusRaw ? noSpaceString(statusRaw).toLowerCase() : null;
	switch (status) {
		case 'enviada':
			return 'st-enviada';
		case 'rebotada':
			return 'st-rebotada';
		case 'abierta':
			return 'st-abierta';
		case 'sinabrir':
			return 'st-sinabrir';
		case 'incompleta':
			return 'st-incompleta';
		case 'error':
			return 'st-errorenv';
		case 'enviando':
			return 'st-enviando';
		default:
			return 'st-borrador';
	}
};

//-----------------------------
// GENERICAS

export const truncateText = (str, n = 200, word = true) => {
	if (str.length <= n) {
		return str;
	}
	const subString = str.slice(0, n - 1);
	return (
		(word ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '…'
	);
};

export const noSpaceString = (str, lower = false) => {
	return str && lower
		? str.replace(/\s/g, '').toLowerCase()
		: str.replace(/\s/g, '');
};

export const sanitizeString = (str) => {
	return str.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
};

export const randomHash = () => {
	return (Math.random() + 1).toString(36).substring(2);
}