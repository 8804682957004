//router
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

//mSal
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './context/msalConfig';


//main app
import App from './App';

export const mainMsalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.getElementById('root');

//helpers mSal defaultAccount
if (!mainMsalInstance.getActiveAccount() && mainMsalInstance.getAllAccounts().length > 0) {
  mainMsalInstance.setActiveAccount(mainMsalInstance.getAllAccounts()[0]);
}

mainMsalInstance.enableAccountStorageEvents();

mainMsalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		mainMsalInstance.setActiveAccount(account);
	}
});
//------------------------------

const root = ReactDOM.createRoot(rootElement);

root.render(
  <MsalProvider instance={mainMsalInstance}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MsalProvider>
);