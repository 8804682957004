import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

import {
	Col,
	Row,
	Button,
	ModalBody,
	ModalFooter,
	ButtonGroup,
} from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';
import InputForm from 'components/common/forms/InputForm';
import InputStep from 'components/common/forms/InputStep';
import InputSelect from 'components/common/forms/InputSelect';
import InputSticker from 'components/common/forms/InputSticker';
import WhiteBox from 'components/common/elems/WhiteBox';
import Medal from 'components/common/elems/Medal';
import Sticker from 'components/common/elems/Sticker';

//forms
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';

//services
import * as eventsApi from 'api/eventsApi';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';
import ButtonLoad from 'components/common/forms/ButtonLoad';

const InvitadoDetailsForm = ({
	modalBarOpen,
	toggleModal,
	invitadoDetails,
	tipoAnunciante,
	procedencias,
	deleteInvitadoFn,
	saveInvitadoFn,
	updateContactFn,
	downloadEntradasFn,
	companies,
	tagList,
}) => {
	const formikRef = useRef();
	const formikRefEdit = useRef();
	const MySwal = withReactContent(Swal);

	const [invitadoData, setInvitadoData] = useState(invitadoDetails);
	const [contactData, setContactData] = useState(null);
	const [editContactMode, setEditContactMode] = useState(false);

	useEffect(() => {
		invitadoData &&
			setContactData(
				tipoAnunciante
					? invitadoData?.contactoAnunciante
					: invitadoData?.contacto
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invitadoData]);

	const closeModalEdit = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModal();
			});
		} else {
			toggleModal();
		}
	};

	const editContactSchema = Yup.object().shape({
		nombre: Yup.string().required(),
		emails: Yup.array()
			.of(
				Yup.object().shape({
					mail: Yup.string().email().required(),
				})
			)
			.min(1),
	});

	const closeEditContact = () => {
		if (formikRefEdit.current && formikRefEdit.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleEditContact();
			});
		} else {
			toggleEditContact();
		}
	};

	const toggleEditContact = () => {
		setEditContactMode(!editContactMode);
	};

	const updateContactData = async (values) => {
		const formEmpresa = values.empresa
			? values.empresa.__isNew__
				? _.omit(values.empresa, ['value'])
				: values.empresa
			: null;

		const formEtiquetas = values.etiquetasContactos
			? values.etiquetasContactos.map((item) =>
					item.__isNew__ ? _.omit(item, ['value']) : item
			  )
			: null;

		const updatedValues = {
			...values,
			empresa: formEmpresa,
			etiquetas: formEtiquetas,
			etiquetasContactos: [],
		};

		const data = await eventsApi.editContactAdvertiser(updatedValues);
		if (data && data.succeeded) {
			notify.success('El invitado se ha actualizado correctamente.');
			setInvitadoData({ ...invitadoData, contactoAnunciante: data.data });
			updateContactFn();
			toggleEditContact();
		} else {
			notify.error(
				`Ha ocurrido un error actualizando el invitado. ${data.message}`
			);
		}
	};

	const deleteMailHelper = (mail, listDeleted = [], setFieldValue) => {
		if (mail && mail.id) {
			listDeleted.push(mail);
			const cleanList = _.uniqBy(listDeleted, 'mail');
			setFieldValue('deletedMails', cleanList);
		}
	};

	const sittingSwitch = (values, setFieldValue) => {
		if (values.sitting) {
			if (values.asistentesSinSitting === 0) {
				setFieldValue('asistentesSinSitting', 1);
			}
			if (values.asistentesConSitting !== 0) {
				setFieldValue('asistentesConSitting', 0);
			}
		}
		if (!values.sitting) {
			if (values.asistentesSinSitting !== 0) {
				setFieldValue('asistentesSinSitting', values.asistentesSinSitting);
			}
			if (values.asistentesConSitting === 0) {
				setFieldValue('asistentesConSitting', 1);
			}
		}
	};

	return (
		<>
			{contactData && (
				<LateralModal isOpen={modalBarOpen} toggleModal={toggleModal} noHead>
					{editContactMode ? (
						<Formik
							innerRef={formikRefEdit}
							enableReinitialize
							initialValues={
								tipoAnunciante
									? invitadoData?.contactoAnunciante
									: invitadoData?.contacto
							}
							validateOnChange={false}
							validateOnBlur={false}
							validationSchema={editContactSchema}
							onSubmit={updateContactData}
						>
							{({ values, setFieldValue, isSubmitting }) => {
								const moreMails = values.esPersona;
								return (
									<>
										<Form autoComplete="off">
											<div className="modal-header">
												<div className="title">{`${contactData.nombre} ${
													contactData.apellidos ? contactData.apellidos : ''
												}`}</div>
												<div className="close" onClick={closeEditContact}>
													<i className="fticon-close"></i>
												</div>
											</div>
											<ModalBody className="dashboard-modal-contact">
												<div className="contact-form">
													<Row>
														{values.esPersona ? (
															<>
																<Col md="12">
																	<Field
																		label="Nombre*"
																		placeholder="Nombre*"
																		name="nombre"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Apellidos"
																		placeholder="Apellidos"
																		name="apellidos"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Empresa"
																		placeholder="Empresa"
																		name="empresa"
																		className="input-create w-arrow"
																		options={companies}
																		component={InputSelect}
																		createLabel={'Crear empresa'}
																		isClearable
																		canCreate
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Cargo"
																		placeholder="Cargo"
																		name="cargo"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Etiqueta/s"
																		placeholder="Etiqueta/s"
																		name="etiquetasContactos"
																		className="input-create-select"
																		component={InputSticker}
																		options={tagList}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Teléfono"
																		placeholder="Teléfono"
																		name="telefono"
																		component={InputForm}
																		maxLength="9"
																		noSpace
																	/>
																</Col>
															</>
														) : (
															<>
																<Col md="12">
																	<Field
																		label="Nombre*"
																		placeholder="Nombre*"
																		name="nombre"
																		component={InputForm}
																	/>
																</Col>

																<Col md="12">
																	<Field
																		label="Etiqueta/s"
																		placeholder="Etiqueta/s"
																		name="etiquetasContactos"
																		className="input-create-select"
																		component={InputSticker}
																		options={tagList}
																	/>
																</Col>
															</>
														)}

														<FieldArray
															name="emails"
															render={(arrayHelpers) => (
																<div>
																	{values.emails &&
																		values.emails.length > 0 &&
																		values.emails.map((paramList, index) => (
																			<div key={index} className="input-delete">
																				<Field
																					component={InputForm}
																					name={`emails.${index}.mail`}
																					placeholder="Email*"
																					label="Email*"
																					disabled={
																						index === 0 &&
																						invitadoData.asistenciaConfirmada
																					}
																				/>

																				{index !== 0 && (
																					<Button
																						onClick={() => {
																							arrayHelpers.remove(index);
																							values.id &&
																								deleteMailHelper(
																									paramList,
																									values.deletedMails,
																									setFieldValue
																								);
																						}}
																						type="button"
																						className="btn-sec-link btn-icon"
																					>
																						<i className="fticon-close"></i>
																					</Button>
																				)}
																			</div>
																		))}
																	{moreMails && (
																		<Col md="12" className="text-end">
																			<Button
																				type="button"
																				className="btn-prim-light mt-1"
																				onClick={() =>
																					arrayHelpers.push({ mail: '' })
																				}
																			>
																				Añadir otro email
																			</Button>
																		</Col>
																	)}
																</div>
															)}
														/>
													</Row>
												</div>
												<ModalFooter>
													<div className="remove"></div>
													<div className="actions">
														<Button
															type="button"
															className="btn-sec-light"
															disabled={isSubmitting}
															onClick={closeEditContact}
														>
															Salir
														</Button>
														<ButtonLoad
															isLoading={isSubmitting}
															text="Guardar"
															className="btn-sec-dark"
														/>
													</div>
												</ModalFooter>
											</ModalBody>
										</Form>
									</>
								);
							}}
						</Formik>
					) : (
						<>
							<Formik
								innerRef={formikRef}
								enableReinitialize
								initialValues={invitadoData}
								validateOnChange={false}
								validateOnBlur={false}
								onSubmit={saveInvitadoFn}
							>
								{({ values, setFieldValue, isSubmitting }) => {
									return (
										<>
											<Form autoComplete="off">
												<ModalBody className="dashboard-modal-contact">
													<div className="contact-read">
														<WhiteBox>
															<Medal
																text={`${contactData.nombre} ${
																	contactData.apellidos
																		? contactData.apellidos
																		: ''
																}`}
																color={
																	contactData.color
																		? contactData.color.claseCss
																		: 'blue'
																}
															/>
															<h3>{`${contactData.nombre} ${
																contactData.apellidos
																	? contactData.apellidos
																	: ''
															}`}</h3>
															<div className="contact-companydetails">
																{contactData.empresa && (
																	<p>{contactData.empresa.label}</p>
																)}
																{contactData.cargo && (
																	<p>{contactData.cargo}</p>
																)}
															</div>
															{contactData.etiquetasContactos &&
																contactData.etiquetasContactos.length > 0 &&
																contactData.etiquetasContactos.map(
																	(etq, index) => (
																		<Sticker
																			key={index}
																			text={etq.etiqueta.label}
																			color={etq.etiqueta.color.claseCss}
																		/>
																	)
																)}
															<div className="contact-details">
																{contactData.telefono && (
																	<p>{contactData.telefono}</p>
																)}
																{contactData.emails &&
																	contactData.emails.length > 0 &&
																	contactData.emails.map((eml, index) => (
																		<p key={index}>{eml.mail}</p>
																	))}
															</div>
															{values.canEdit && tipoAnunciante && (
																<div className="contact-edit">
																	<Button
																		type="button"
																		className="btn-prim-light"
																		disabled={isSubmitting}
																		onClick={toggleEditContact}
																	>
																		Editar
																	</Button>
																</div>
															)}
														</WhiteBox>
													</div>
													<div className="contact-form">
														<Field
															label="Procedencia"
															placeholder="Procedencia"
															name="procedencia"
															options={procedencias}
															component={InputSelect}
															className="input-create w-arrow"
															createLabel={'Crear nueva'}
															isClearable
															canCreate
														/>
														<div className="input-row">
															<div className="icon">
																<i className="fticon-star"></i>
															</div>
															<div className="field">
																<Field
																	component={InputForm}
																	baseLabel
																	type="switch"
																	label={'Destacado'}
																	name="esVIP"
																	reverse
																/>
															</div>
														</div>
														<div className="input-row">
															<div className="icon">
																<i className="fticon-camera"></i>
															</div>
															<div className="field">
																<Field
																	component={InputForm}
																	baseLabel
																	type="switch"
																	label={'Photocall'}
																	name="photocall"
																	reverse
																/>
															</div>
														</div>

														<div className="input-row">
															<div className="icon">
																<i className="fticon-sitting"></i>
															</div>
															<div className="field">
																<Field
																	component={InputForm}
																	baseLabel
																	type="switch"
																	label={'Sitting'}
																	name="sitting"
																	reverse
																	onChange={(e) => {
																		setFieldValue('sitting', e.target.checked);
																		sittingSwitch(values, setFieldValue);
																	}}
																/>
															</div>
														</div>
														<div className="input-row">
															<div className="head">
																<div className="icon">
																	<i className="fticon-user"></i>
																</div>
																<div className="field">
																	Invitados
																	<div className="count">
																		{values?.asistentesConSitting +
																			values?.asistentesSinSitting || 0}
																	</div>
																</div>
															</div>
															<Field
																label="Sin sitting"
																placeholder="Sin sitting"
																name="asistentesSinSitting"
																component={InputStep}
																canWrite
															/>
															{values.sitting && (
																<Field
																	label="Con sitting"
																	placeholder="Con sitting"
																	name="asistentesConSitting"
																	component={InputStep}
																	canWrite
																/>
															)}
														</div>
														{values.canEdit && (
															<div className="input-row">
																<div className="head">
																	<div className="icon">
																		<i className="fticon-tick"></i>
																	</div>
																	<div className="field">Asistencia</div>
																</div>
																<Row>
																	<Col>
																		<ButtonGroup className="buttons-groups mb-3">
																			<Button
																				className={`btn-sec-light${
																					values.asistenciaConfirmada
																						? ' active'
																						: ''
																				}`}
																				type="button"
																				onClick={(e) => {
																					setFieldValue('asistencia', true);
																					setFieldValue(
																						'asistenciaConfirmada',
																						true
																					);
																					setFieldValue(
																						'asistenciaDeclinada',
																						false
																					);
																				}}
																			>
																				Confirmar
																			</Button>
																			<Button
																				className={`btn-sec-light${
																					values.asistenciaDeclinada
																						? ' active'
																						: ''
																				}`}
																				type="button"
																				onClick={(e) => {
																					setFieldValue('asistencia', true);
																					setFieldValue(
																						'asistenciaConfirmada',
																						false
																					);
																					setFieldValue(
																						'asistenciaDeclinada',
																						true
																					);
																				}}
																			>
																				Cancelar
																			</Button>
																		</ButtonGroup>
																	</Col>
																</Row>
															</div>
														)}

														{values.dataInvitacion &&
															values.dataEntrada &&
															values.dataEntrada.canDownload && (
																<div className="input-row mt-4">
																	<div className="head">
																		<div className="icon">
																			<i className="fticon-ticket"></i>
																		</div>
																		<div className="field">Entradas</div>
																	</div>
																	<Row>
																		<Col>
																			<Button
																				type="button"
																				className="btn-prim-light mb-3"
																				onClick={() =>
																					downloadEntradasFn(
																						values.dataInvitacion.id
																					)
																				}
																			>
																				Descargar entradas
																			</Button>
																		</Col>
																	</Row>
																</div>
															)}
													</div>

													<ModalFooter>
														{values.canEdit && (
															<div className="remove">
																{values.invitaciones &&
																	values.invitaciones.length === 0 && (
																		<Button
																			type="button"
																			className="btn-sec-link p-0"
																			disabled={isSubmitting}
																			onClick={() =>
																				deleteInvitadoFn(values.id)
																			}
																		>
																			Eliminar invitado
																		</Button>
																	)}
															</div>
														)}
														<div className="actions">
															<Button
																type="button"
																className="btn-sec-light"
																disabled={isSubmitting}
																onClick={closeModalEdit}
															>
																Salir
															</Button>
															{values.canEdit && (
																<Button
																	type="submit"
																	className="btn-sec-dark"
																	disabled={isSubmitting}
																>
																	Guardar
																</Button>
															)}
														</div>
													</ModalFooter>
												</ModalBody>
											</Form>
										</>
									);
								}}
							</Formik>
						</>
					)}
				</LateralModal>
			)}
		</>
	);
};

export default InvitadoDetailsForm;
