import React, { useEffect, useState } from 'react';

function calculateTimeLeft(date) {
	const difference = +new Date(date) - +new Date();
	let timeLeft = {};

	if (difference > 0) {
		timeLeft = {
			días: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
			min: Math.floor((difference / 1000 / 60) % 60),
			s: Math.floor((difference / 1000) % 60),
		};
	}

	return timeLeft;
}

const CountDown = ({ finishDate }) => {
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(finishDate));

	const timerComponents = Object.keys(timeLeft).map((interval, index) => {
		return (
			<div key={index}>
				<span className='num'>{timeLeft[interval]}</span><span className='txt'>{interval}</span>
			</div>
		);
	});

	useEffect(() => {
		const id = setTimeout(() => {
			setTimeLeft(calculateTimeLeft(finishDate));
		}, 1000);
		return () => {
			clearTimeout(id);
		};
	});

	return (
		<div className='event-counter'>
			{timerComponents.length ? timerComponents : <span>El evento ha finalizado</span>}
		</div>
	);
};

export default CountDown;
