import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

//Components
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';

//forms
import { Formik, Form, Field } from 'formik';

//helpers
import { ASIST_OPT_TEXT } from 'utils/helpers';

const InvitadosFilter = ({
	tagList,
	submitFilter,
	companies,
	fromOrigin = null,
	vipFilter,
	photoFilter,
	sittingFilter,
	asistFilter,
	invitFilter,
	orderFilter,
	orderDateFilter,
	statusFilter,
	eventoPasado=false,
	disabled=false
}) => {
	const fmFilterRef = useRef();

	const initialValues = {
		nombreCompleto: '',
		etiquetaId: [],
		etiqueta: {},
		empresaId: 0,
		empresa: {},
		procedenciaId: 0,
		procedencia: {},
		observaciones: null,
		entrada: null,
	};

	useEffect(() => {
		if (!_.isNull(vipFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('esVIP', vipFilter);
			fmFilterRef.current.submitForm();
		}
	}, [vipFilter]);

	useEffect(() => {
		if (!_.isNull(photoFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('photocall', photoFilter.value);
			fmFilterRef.current.submitForm();
		}
	}, [photoFilter]);

	useEffect(() => {
		if (!_.isNull(sittingFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue(
				sittingFilter.param,
				sittingFilter.value
			);
			fmFilterRef.current.submitForm();
		}
	}, [sittingFilter]);

	useEffect(() => {
		if (!_.isNull(orderFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('order', orderFilter);
			fmFilterRef.current.submitForm();
		}
	}, [orderFilter]);

	useEffect(() => {
		if (!_.isNull(orderDateFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('orderDate', orderDateFilter);
			fmFilterRef.current.submitForm();
		}
	}, [orderDateFilter]);

	useEffect(() => {
		if (!_.isNull(asistFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue(asistFilter.param, asistFilter.value);
			fmFilterRef.current.submitForm();
		}
	}, [asistFilter]);

	useEffect(() => {
		if (!_.isNull(statusFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('estado', statusFilter);
			fmFilterRef.current.submitForm();
		}
	}, [statusFilter]);

	return (
		<Formik
			innerRef={fmFilterRef}
			enableReinitialize
			initialValues={initialValues}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={submitFilter}
		>
			{({ handleSubmit, setFieldValue }) => (
				<>
					<Form
						autoComplete="off"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmit();
							}
						}}
					>
						<div className="contact-filters">
							<Row>
								<Col md="3">
									<Field
										label="Nombre y apellidos"
										placeholder="Nombre y apellidos"
										name="nombreCompleto"
										component={InputForm}
										isClearable
										extraClearFunction={handleSubmit}
										disabled={disabled}
									/>
								</Col>
								<Col md="3">
									<Field
										label="Etiqueta"
										placeholder="Etiqueta"
										name="etiqueta"
										options={tagList}
										component={InputSelect}
										onChange={(e) => {
											setFieldValue('etiquetaId', e);
											handleSubmit();
										}}
										isClearable
										isMulti
										selectables
										grouped
										canSearch
										disabled={disabled}
									/>
								</Col>
								<Col md="2">
									<Field
										label="Empresa"
										placeholder="Empresa"
										name="empresa"
										options={companies}
										component={InputSelect}
										onChange={(e) => {
											setFieldValue('empresaId', e ? e.value : 0);
											handleSubmit();
										}}
										isClearable
										disabled={disabled}
										canSearch
									/>
								</Col>

								<Col md="2">
									<Field
										label="Procedencia"
										placeholder="Procedencia"
										name="procedencia"
										options={fromOrigin}
										component={InputSelect}
										onChange={(e) => {
											setFieldValue('procedenciaId', e ? e.value : 0);
											handleSubmit();
										}}
										isClearable
										disabled={disabled}
										canSearch
									/>
								</Col>

								{eventoPasado && (
									<Col md="2">
										<Field
											label="Asistencia"
											placeholder="Asistencia"
											name="asistenciaEvento"
											options={ASIST_OPT_TEXT}
											component={InputSelect}
											disabled={disabled}
											onChange={(e) => {
											setFieldValue('asistenciaEvento', e ? e.value : null);
											handleSubmit();
										}}
											isClearable
										/>
									</Col>
								)}
							</Row>
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default InvitadosFilter;
