import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import _ from 'lodash';

//services
import * as userApi from 'api/usersAdminApi';

//Components
import PageHeader from 'components/common/elems/PageHeader';
import WhiteBox from 'components/common/elems/WhiteBox';
import Sticker from 'components/common/elems/Sticker';
import TableLoader from 'components/loaders/TableLoader';
import AdminUsersFilter from './AdminUsersFilter';
import AdminUsersForm from './AdminUsersForm';

//helpers
import { parseDateTimeFromString } from 'utils/helpers';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

const AdminUsersPage = () => {
	const [modalBarOpen, setModalBarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentFilter, setCurrentFilter] = useState({ pagina: 1 });

	const [modalData, setModalData] = useState({});
	const [modalEdit, setModalEdit] = useState(false);

	const [usersData, setUsersData] = useState([]);
	const [rolList, setRolList] = useState();
	const [moduleList, setModuleList] = useState();

	useEffect(() => {
		getRoles();
		getModules();

		setCurrentFilter({ pagina: 1 });
		getUsers();
	}, []);

	const getRoles = async () => {
		const data = await userApi.getRoles();
		setRolList(data.data);
	};

	const getModules = async () => {
		const data = await userApi.getModules();
		setModuleList(data.data);
	};

	const getUsers = async (
		filters = currentFilter,
		more = false
	) => {
		!more && setIsLoading(true);
		const data = await userApi.getUsers(filters);

		more
			? setUsersData(_.concat(usersData, data.data))
			: setUsersData(data.data);

		setCurrentFilter(filters);

		!more && setIsLoading(false);
	};

	const reloadUsers = () => {
		getRoles();
		getModules();
		getUsers();
	};

	const handleSubmitFilter = (filters) => {
		searchUser({ ...filters, pagina: 1 });
	};

	const searchUser = async (filters = currentFilter) => {
		setIsLoading(true);
		const data = await userApi.getUsers(filters);

		setUsersData(data.data);
		setCurrentFilter(filters);
		setIsLoading(false);
	};

	const MySwal = withReactContent(Swal);
	const removeUser = (userId) => {
		MySwal.fire({
			title: '¿Quieres eliminar el usuario?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await userApi.deleteUser(userId);
				if (data && data.succeeded) {
					notify.success('El usuarios se ha eliminado correctamente.');
					reloadUsers();
				} else {
					notify.error('Ha ocurrido un error eliminando el usuario.');
				}
			}
		});
	};

	const toggleModalBar = () => {
		setModalBarOpen(!modalBarOpen);
	};

	const toggleModalEdit = (val) => {
		setModalEdit(val);
	};

	const showUser = (data, edit) => {
		setModalData(data);
		setModalEdit(edit);
		setModalBarOpen(true);
	};

	const handleSubmitForm = async (values, formProps) => {
		const formRol = values.rol ? values.rol.value :0;
			
		const updatedValues = {
			...values,
			rolId: formRol
		};

		const data = await userApi.setUser(updatedValues);
		if (data && data.succeeded) {
			if (values.reload) {
				formProps.resetForm({ values: defModelForm });
				setModalData({});
			} else {
				setModalBarOpen(false);
			}

			reloadUsers();
			notify.success(
				`El usuario se ha ${values.id ? 'actualizado' : 'creado'
				} correctamente.`
			);
		} else {
			notify.error(
				`Ha ocurrido un error ${values.id ? 'actualizando' : 'creando'
				} el usuario. ${data.message}`
			);
		}
	};

	const defModelForm = {
		nombre: '',
	};

	return (
		<>
			<div className="users-page">
				<PageHeader title={'Usuarios'}>
					<Button
						className="btn-prim-dark"
						onClick={() => showUser({}, true)}
					>
						Nuevo usuario
					</Button>
				</PageHeader>				
				<Row className="mt-4">
					<Col lg="12">
						<WhiteBox containerClass="user-list">
							<Row className="pb-3">
								<Col md="12">
									<AdminUsersFilter
										rolList={rolList}
										moduleList={moduleList}
										submitFilter={handleSubmitFilter}
									/>
								</Col>
							</Row>
							<Row className="table-header pb-3">
								<Col md="4">Usuario</Col>
								<Col md="1">Rol</Col>
								<Col md="5">Modulos</Col>
								<Col md="1" className="text-left">F. Alta</Col>
								<Col md="1"></Col>
							</Row>
							{isLoading ? (
								<TableLoader />
							) : (
								<Col lg="12">
									{usersData && usersData.length > 0 ? (
										usersData.map((user, index) => {
											const fullName = `${user.nombre} ${user.apellidos ? user.apellidos : ''} (${user.login ? user.login : ''})`;
											return (
												<Row
													key={index}
													className="table-row"
													onClick={() => showUser(user, false)}
												>													
													<Col md="4" className="col-name">
														<div className="name">{fullName}</div>
													</Col>
													<Col md="1" className="name">
														{user.rol && user.rol.label}
													</Col>
													<Col md="5" className="name">
														{user.rol &&
															user.rol.rolModulos && user.rol.rolModulos.length > 0 
															&& user.rol.rolModulos.map((rolModulo, index) => (
																<Sticker
																	key={index}
																	text={rolModulo.modulo.nombre}
																	color="blue"
																/>																
															)
														)}
													</Col>
													<Col md="1">
														<div className="date">
															{parseDateTimeFromString(
																user.createdAt,
																'dd-MM-yyyy'
															)}
														</div>
													</Col>													
													<Col md="1" className="col-actions text-end">
														<div>
															<Button
																className="btn-sec-link btn-icon"
																onClick={(e) => {
																	e.stopPropagation();
																	showUser(user, true);
																}}
															>
																<i className="fticon-edit"></i>
															</Button>
															<Button
																className="btn-sec-link btn-icon"
																onClick={(e) => {
																	e.stopPropagation();
																	removeUser(user.id);
																}}
															>
																<i className="fticon-trash"></i>
															</Button>
														</div>
													</Col>
												</Row>
											);
										})
									) : (
										<WhiteBox>
											<Col className="text-center py-5">Sin resultados</Col>
										</WhiteBox>
									)}
								</Col>
							)}							
						</WhiteBox>
					</Col>
				</Row>
				<AdminUsersForm
					modalBarOpen={modalBarOpen}
					toggleModalBar={toggleModalBar}
					modalEdit={modalEdit}
					modalData={modalData}
					defModelForm={defModelForm}
					handleSubmitForm={handleSubmitForm}
					toggleModalEdit={toggleModalEdit}
					rolList={rolList}
				/>
			</div>
		</>
	);
};

export default AdminUsersPage;
