import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

import {
	Col,
	Row,
	Button,
	ModalBody,
	ModalFooter,
	AccordionItem,
	AccordionHeader,
	AccordionBody,
} from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';
import WhiteBox from 'components/common/elems/WhiteBox';
import Medal from 'components/common/elems/Medal';

//helpers
import { classCssToColor } from 'utils/helpers';

//forms
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';
import InputColor from 'components/common/forms/InputColor';
import Acordeon from 'components/common/elems/Acordeon';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//services
import * as sitesApi from 'api/sitesApi';

const SitesForm = ({
	modalBarOpen,
	toggleModalBar,
	modalEdit,
	modalData,
	defModelForm,
	handleSubmitForm,
	//mapList,
	toggleModalEdit,
	mapTagsList,
}) => {
	const formikRef = useRef();
	const MySwal = withReactContent(Swal);
	const isEditing = modalData && modalData.id ? true : false;

	const emptyRoom = {
		nombre: '',
		mapa: {},
		color: { hex: '', claseCss: '' },
		etiquetaTemp: null,
	};
	const emptyAccess = { nombre: '', direccion: '', salas: [emptyRoom] };

	const [defRoomOpen, setDefRoomOpen] = useState('0');
	const [defAccessOpen, setDefAccessOpen] = useState('0');
	const [isLoadingMaps, setIsLoadingMaps] = useState(false);

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required(),
		direccion: Yup.string().required(),
		accesos: Yup.array()
			.of(
				Yup.object().shape({
					nombre: Yup.string().required(),
					direccion: Yup.string().required(),
					salas: Yup.array()
						.of(
							Yup.object().shape({
								nombre: Yup.string().required(),
								etiquetaTemp: Yup.object().shape({
									value: Yup.number().required(),
								}),
								mapa: Yup.object().shape({
									label: Yup.string().required(),
									value: Yup.number().required(),
									areas: Yup.array().of(
										Yup.object().shape({
											color: Yup.object().shape({
												hex: Yup.string().required(),
											}),
										})
									),
								}),
								color: Yup.object().shape({
									hex: Yup.string().required(),
								}),
							})
						)
						.min(1),
				})
			)
			.min(1),
	});

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModalBar();
			});
		} else {
			toggleModalBar();
		}
	};

	const deleteRoomHelper = (sala, deletedRooms = [], setFieldValue) => {
		if (sala && sala.id) {
			deletedRooms.push(sala);
			const cleanList = _.uniqBy(deletedRooms, 'id');
			setFieldValue('deletedrooms', cleanList);
		}
	};

	const [mapListSelect, setMapListSelect] = useState({});

	useEffect(() => {
		//carga todos los mapas de los accesos
		modalData &&
			modalData.accesos &&
			modalData.accesos.map(
				(acc, ixacc) =>
					acc.salas &&
					acc.salas.map(
						(sala, ixsala) =>
							sala.etiquetaTemp &&
							handleStickerChangeNew(sala.etiquetaTemp.value, ixacc, ixsala, false, true)
					)
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStickerChangeNew = async (
		stickerValue,
		indexAcceso,
		indexSala,
		reloadMap = false,
		firstLoad = false,
	) => {
		if (stickerValue) {
			setIsLoadingMaps(true);
			const data = await sitesApi.getMapsByTag(stickerValue);
			if (data && data.succeeded) {
				//limpia mapa y areas
				if(!firstLoad) {
					if (reloadMap) {
						formikRef.current &&
							formikRef.current.setFieldValue(
								`accesos.${indexAcceso}.salas.${indexSala}.mapa`,
								null
							);
					} else {
						formikRef.current &&
							formikRef.current.setFieldValue(
								`accesos.${indexAcceso}.salas.${indexSala}.mapa.areas`,
								[]
							);
					}
				}
				
				const newMapList = {[`ac${indexAcceso}-sl${indexSala}`]: data.data};
				const updatedRooms = _.mergeWith(mapListSelect, newMapList, (a, b) => _.isArray(b) ? b : undefined);
				setMapListSelect(updatedRooms);

			}
			setIsLoadingMaps(false);
		}
	};

	const handleMapSelectArea = async (mapValue, indexAcceso, indexSala) => {
		setIsLoadingMaps(true);
		const data = await sitesApi.getAreasByMap(mapValue);
		const newAreas =
			data.data && data.data.length > 0
				? data.data.map((area) => ({
						...area,
						color: { hex: null },
				  }))
				: [];
		formikRef.current &&
			formikRef.current.setFieldValue(
				`accesos.${indexAcceso}.salas.${indexSala}.mapa.areas`,
				newAreas
			);
		setIsLoadingMaps(false);
	};


	const openRoomAcd = (ixAcceso, ixSala) => {
		if (
			modalData.accesos[ixAcceso] &&
			modalData.accesos[ixAcceso].salas[ixSala] &&
			!formikRef.current.values.accesos[ixAcceso].salas[ixSala].etiquetaTemp
		) {
			handleStickerChangeNew(
				modalData.accesos[ixAcceso].salas[ixSala].etiquetaTemp.value,
				ixAcceso,
				ixSala
			).then(() => {
				handleMapSelectArea(
					formikRef.current.values.accesos[ixAcceso].salas[ixSala].mapa.value,
					ixAcceso,
					ixSala
				);
			});
		}
	};

	const deleteAccessHelper = (acceso, deletedAccess = [], setFieldValue) => {
		if (acceso && acceso.id) {
			deletedAccess.push(acceso);
			const cleanList = _.uniqBy(deletedAccess, 'id');
			setFieldValue('deletedAccess', cleanList);
		}
	};

	return (
		<>
			<LateralModal
				title={isEditing ? modalData.nombre : 'Nuevo lugar'}
				isOpen={modalBarOpen}
				toggleModal={closeModalMenu}
			>
				{modalEdit && modalData ? (
					<>
						<Formik
							innerRef={formikRef}
							enableReinitialize
							initialValues={
								modalData && modalData.id ? modalData : defModelForm
							}
							validationSchema={validationSchema}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={(values, actions) => handleSubmitForm(values, actions)}
						>
							{({
								values,
								setFieldValue,
								isSubmitting,
								submitForm,
								validateForm,
							}) => {
								const checkForSubmit = () => {
									validateForm().then((res) => {
										if (!_.isEmpty(res)) {
											if (res.accesos) {
												if (!_.isArray(res.accesos)) {
													notify.error(
														'Debes añadir al menos un acceso al lugar.'
													);
												} else {
													res.accesos.map(
														(err) =>
															!_.isArray(err.salas) &&
															notify.error(
																'Debes añadir al menos una sala al acceso.'
															)
													);
												}
											}
										} else {
											submitForm();
										}
									});
								};

								return (
									<>
										<Form autoComplete="off">
											<ModalBody>
												<div className="site-edit">
													<div className="site-form">
														<Row>
															<Col md="12">
																<Field
																	label="Nombre del lugar*"
																	placeholder="Nombre del lugar*"
																	name="nombre"
																	component={InputForm}
																/>
															</Col>
															<Col md="12">
																<Field
																	label="Dirección*"
																	placeholder="Dirección*"
																	name="direccion"
																	component={InputForm}
																/>
															</Col>

															<Col md="12">
																<Acordeon openID={`${defAccessOpen}`}>
																	<FieldArray
																		name="accesos"
																		render={(arrayHelpers) => (
																			<>
																				{values.accesos &&
																					values.accesos.length > 0 &&
																					values.accesos.map(
																						(acceso, index) => (
																							<AccordionItem key={index}>
																								<AccordionHeader
																									targetId={`${index}`}
																									onClick={() =>
																										openRoomAcd(index, 0)
																									}
																								>
																									<div>
																										{acceso.nombre
																											? acceso.nombre
																											: 'Acceso'}
																									</div>
																									<div className="delete-div">
																										<i
																											onClick={(e) => {
																												e.stopPropagation();
																												arrayHelpers.remove(
																													index
																												);
																												acceso.id &&
																													deleteAccessHelper(
																														acceso,
																														values.deleteAccess,
																														setFieldValue
																													);
																											}}
																											className="fticon-trash"
																										></i>
																									</div>
																								</AccordionHeader>
																								<AccordionBody
																									accordionId={`${index}`}
																								>
																									<Field
																										component={InputForm}
																										name={`accesos.${index}.nombre`}
																										placeholder="Nombre del acceso*"
																										label="Nombre del acceso*"
																									/>

																									<Field
																										component={InputForm}
																										name={`accesos.${index}.direccion`}
																										placeholder="Dirección*"
																										label="Dirección*"
																									/>

																									<Col md="12">
																										<Acordeon
																											openID={`${defRoomOpen}`}
																										>
																											<FieldArray
																												name={`accesos.${index}.salas`}
																												render={(
																													arrayHelpers
																												) => (
																													<>
																														{acceso.salas
																															.length > 0 &&
																															acceso.salas.map(
																																(
																																	sala,
																																	indexSala
																																) => (
																																	<AccordionItem
																																		key={
																																			indexSala
																																		}
																																	>
																																		<AccordionHeader
																																			targetId={`${indexSala}`}
																																			onClick={() =>
																																				openRoomAcd(
																																					index,
																																					indexSala
																																				)
																																			}
																																		>
																																			<div>
																																				{sala.nombre
																																					? sala.nombre
																																					: 'Sala'}
																																			</div>
																																			<div className="delete-div">
																																				<i
																																					onClick={(
																																						e
																																					) => {
																																						e.stopPropagation();
																																						arrayHelpers.remove(
																																							indexSala
																																						);
																																						sala.id &&
																																							deleteRoomHelper(
																																								sala,
																																								values.deletedRooms,
																																								setFieldValue
																																							);
																																					}}
																																					className="fticon-trash"
																																				></i>
																																			</div>
																																		</AccordionHeader>
																																		<AccordionBody
																																			accordionId={`${indexSala}`}
																																		>
																																			<Field
																																				component={
																																					InputForm
																																				}
																																				name={`accesos.${index}.salas.${indexSala}.nombre`}
																																				placeholder="Nombre de la sala*"
																																				label="Nombre de la sala*"
																																			/>
																																			<Field
																																				component={
																																					InputSelect
																																				}
																																				name={`accesos.${index}.salas.${indexSala}.etiquetaTemp`}
																																				placeholder="Etiqueta Mapas*"
																																				options={
																																					mapTagsList
																																				}
																																				label="Etiqueta Mapas*"
																																				isLoading={
																																					isLoadingMaps
																																				}
																																				disabled={
																																					isLoadingMaps
																																				}
																																				onChange={(
																																					e
																																				) => {
																																					setFieldValue(
																																						`accesos.${index}.salas.${indexSala}.etiquetaTemp`,
																																						e
																																					);
																																					handleStickerChangeNew(
																																						e.value,
																																						index,
																																						indexSala,
																																						true
																																					);
																																				}}
																																				canSearch
																																				findByValue
																																			/>
																																			<Field
																																				component={
																																					InputSelect
																																				}
																																				name={`accesos.${index}.salas.${indexSala}.mapa`}
																																				placeholder="Nombre del mapa*"
																																				options={
																																					mapListSelect &&
																																					mapListSelect[
																																						`ac${index}-sl${indexSala}`
																																					]
																																						? mapListSelect[
																																						`ac${index}-sl${indexSala}`
																																					]
																																						: []
																																				}
																																				label="Nombre del mapa*"
																																				isLoading={
																																					isLoadingMaps
																																				}
																																				disabled={
																																					isLoadingMaps
																																				}
																																				onChange={(
																																					e
																																				) => {
																																					setFieldValue(
																																						`accesos.${index}.salas.${indexSala}.mapa`,
																																						e
																																					);
																																					handleMapSelectArea(
																																						e.value,
																																						index,
																																						indexSala
																																					);
																																				}}
																																				canSearch
																																				findByValue
																																			/>
																																			<Field
																																				component={
																																					InputColor
																																				}
																																				name={`accesos.${index}.salas.${indexSala}.color.hex`}
																																				placeholder="Color para entradas*"
																																				label="Color para entradas*"
																																				colorZone="Sala"
																																				colorName="Color para entradas"
																																				classContainer="no-m-bt"
																																			/>

																																			{sala.mapa &&
																																				sala
																																					.mapa
																																					.areas &&
																																				sala.mapa.areas.map(
																																					(
																																						area,
																																						ixArea
																																					) => (
																																						<Field
																																							key={
																																								ixArea
																																							}
																																							component={
																																								InputColor
																																							}
																																							name={`accesos.${index}.salas.${indexSala}.mapa.areas.${ixArea}.color.hex`}
																																							placeholder=""
																																							colorZone="Zona"
																																							colorName={
																																								area.title
																																							}
																																							classContainer="no-m-bt"
																																							idValue={
																																								area.id
																																							}
																																							nameValue={`accesos.${index}.salas.${indexSala}.mapa.areas.${ixArea}.id`}
																																						/>
																																					)
																																				)}
																																		</AccordionBody>
																																	</AccordionItem>
																																)
																															)}
																														<Col
																															md="12"
																															className="text-end mt-3"
																														>
																															<Button
																																type="button"
																																className="btn-prim-link p-0 mt-1"
																																onClick={() => {
																																	setDefRoomOpen(
																																		acceso.salas
																																			.length
																																	);
																																	arrayHelpers.push(
																																		emptyRoom
																																	);
																																}}
																															>
																																Nueva sala
																															</Button>
																														</Col>
																													</>
																												)}
																											/>
																										</Acordeon>
																									</Col>
																								</AccordionBody>
																							</AccordionItem>
																						)
																					)}

																				<Col md="12" className="text-end my-4">
																					<Button
																						type="button"
																						className="btn-prim-light mt-1"
																						onClick={() => {
																							setDefAccessOpen(
																								values.accesos.length
																							);
																							setDefRoomOpen(0);
																							arrayHelpers.push(emptyAccess);
																						}}
																					>
																						Nuevo acceso
																					</Button>
																				</Col>
																			</>
																		)}
																	/>
																</Acordeon>
															</Col>
														</Row>
													</div>
												</div>
											</ModalBody>
											<ModalFooter>
												<Button
													type="button"
													onClick={() => {
														setFieldValue('reload', false);
														checkForSubmit();
													}}
													className={
														modalData.id ? 'btn-sec-dark' : 'btn-sec-light'
													}
													disabled={isSubmitting}
												>
													Guardar
												</Button>
												{!modalData.id && (
													<Button
														type="button"
														onClick={() => {
															setFieldValue('reload', true);
															checkForSubmit();
														}}
														className="btn-sec-dark"
														disabled={isSubmitting}
													>
														Guardar y nuevo
													</Button>
												)}
											</ModalFooter>
										</Form>
									</>
								);
							}}
						</Formik>
					</>
				) : (
					<>
						<ModalBody>
							<div className="site-read">
								<WhiteBox>
									<Medal
										text={modalData.nombre}
										color={
											modalData && modalData.color
												? modalData.color.claseCss
												: 'blue'
										}
									/>
									<h3>{modalData.nombre}</h3>
									<div className="site-details">
										{modalData.direccion && <p>{modalData.direccion}</p>}
										{modalData.aforo && (
											<p>
												<i className="fticon-user"></i>
												<span>{modalData.aforo}</span>
											</p>
										)}
									</div>
								</WhiteBox>
								<div className="site-accesos">
									<h4>Accesos</h4>
									{modalData.accesos &&
										modalData.accesos.length > 0 &&
										modalData.accesos.map((acc, index) => (
											<div key={index}>
												<div className="acceso">
													<div className="tit">{acc.nombre}</div>
													<div className="dir">{acc.direccion}</div>
												</div>
											</div>
										))}
								</div>
								<div className="site-salas">
									<h4>Salas</h4>
									{modalData.accesos &&
										modalData.accesos.length > 0 &&
										modalData.accesos.map(
											(acc) =>
												acc.salas &&
												acc.salas.length > 0 &&
												acc.salas.map((sala, index3) => (
													<div
														key={index3}
														className={
															sala.mapa &&
															sala.mapa.areas &&
															sala.mapa.areas.length > 0
																? 'salaWithMap'
																: 'sala'
														}
														style={
															sala.mapa &&
															sala.mapa.areas &&
															sala.mapa.areas.length > 0
																? { borderLeftColor: 'none' }
																: { borderLeftColor: sala.color.hex }
														}
													>
														<div className="tit">
															{sala.nombre}
															<span>{sala.aforo}</span>
														</div>
														<div className="dir">{acc.nombre}</div>
														{sala.mapa &&
															sala.mapa.areas &&
															sala.mapa.areas.length > 0 && (
																<div className="zonas">
																	{sala.mapa.areas.map(
																		(area, indexAr) =>
																			area.type &&
																			area.type.type === 'seat' && (
																				<div className="tit-zona" key={indexAr}>
																					{area.color && (
																						<div
																							className="bullet-sala"
																							style={{
																								backgroundColor:
																									classCssToColor(
																										area.color.claseCss
																									),
																							}}
																						></div>
																					)}
																					<span className="nombre-sala">
																						{area.title}
																					</span>
																					<span className="seats-sala">
																						{area.capacity}
																					</span>
																				</div>
																			)
																	)}
																</div>
															)}
													</div>
												))
										)}
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								className="btn-sec-dark"
								onClick={() => toggleModalEdit(true)}
							>
								Editar
							</Button>
						</ModalFooter>
					</>
				)}
			</LateralModal>
		</>
	);
};

export default SitesForm;
