import React from 'react';
import { Row, Col } from 'reactstrap';

//Components
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';
import InputDate from 'components/common/forms/InputDate';

//forms
import { Formik, Form, Field } from 'formik';


const AdminAuditsFilter = ({ levelList, userList, submitFilter }) => {
	const initialValues = {
		message: '',
		level: null,
		dateFrom: null,
		dateTo: null,
		userList: null
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={submitFilter}
		>
			{({ handleSubmit, setFieldValue }) => (
				<>
					<Form
						autoComplete="off"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmit();
							}
						}}
					>
						<div className="audit-filters">
							<Row>
								<Col md="3">
									<Field
										label="Acción"
										placeholder="Acción"
										name="message"
										component={InputForm}
										isClearable
										extraClearFunction={handleSubmit}
									/>
								</Col>
								<Col md="2">
									<Field
										label="Tipo"
										placeholder="Tipo"
										name="level"
										options={levelList}
										component={InputSelect}
										onChange={(e) => {
											setFieldValue('level', e ? parseInt(e.value) : null);
											handleSubmit();
										}}
										isClearable									
									/>
								</Col>
								<Col md="2">
									<Field
										label="Fecha desde"
										placeholder="Fecha desde"
										name="dateFrom"
										type="date"
										component={InputDate}
										isClearable
										extraFnChange={handleSubmit}
									/>
								</Col>
								<Col md="2">
									<Field
										label="Fecha hasta"
										placeholder="Fecha hasta"
										name="dateTo"
										type="date"
										component={InputDate}
										isClearable
										extraFnChange={handleSubmit}
									/>
								</Col>
								<Col md="3">
									<Field
										label="Usuario"
										placeholder="Usuario"
										name="userid"
										options={userList}
										component={InputSelect}
										onChange={(e) => {
											setFieldValue('userid', e ? parseInt(e.value) : null);
											handleSubmit();
										}}
										isClearable
										canSearch								
									/>
								</Col>
							</Row>							
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default AdminAuditsFilter;
