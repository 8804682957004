import React, { useState, useEffect } from 'react';

//Enrutado
import { Route } from 'react-router-dom';

//Notificaciones
import { ToastContainer } from 'react-toastify';

//service
import * as usersApi from 'api/usersApi';

//Loader
import LoadingLayer from './loaders/LoadingLayer';

//Componentes
import { Container, Row, Col } from 'reactstrap';
import NavBar from './NavBar';
import VerticalBar from './VerticalBar';

//helpers
import { USER_ROLE } from 'utils/helpers';

//mSal
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { handleMsalLogin } from 'context/msalConfig';

//denegado
import AccessDeniedPage from 'pages/error/AccessDeniedPage';

const Layout = ({
	dev,
	role,
	component: Component,
	isEvent = false,
	isMap = false,
	...props
}) => {

	const [isLoading, setIsLoading] = useState(true);
	const { instance, inProgress, accounts } = useMsal();
	const [userApp, setUserApp] = useState(null);

	useEffect(() => {
		if (inProgress === InteractionStatus.None) {
			const usernameAAD = accounts[0] && accounts[0].username;
			if (usernameAAD) {
				getCurrentUser(usernameAAD);
			} else {
				handleMsalLogin(instance);
			}
		}
	}, [accounts, inProgress, instance]);

	const getCurrentUser = async (userEmail) => {
		const data = await usersApi.getCurrentUser(userEmail);

		const showAdminOps =
			data.data && data.data.modulos
				? data.data.modulos.find(
						(modl) =>
							modl === USER_ROLE.admin_auditor || modl === USER_ROLE.admin_users
				  )
					? true
					: false
				: false;
		setUserApp({ ...data.data, adminMenu: showAdminOps });
		setIsLoading(false);
	};

	const canView =
		userApp && userApp.id
			? userApp.modulos.find((md) => md === role)
				? true
				: false
			: false;

	return isLoading ? (
		<LoadingLayer visible={isLoading} />
	) : (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeButton={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{canView ? (
				isEvent ? (
					<>
						<Container fluid>
							<Row>
								<VerticalBar />
								<div className="col-sm min-vh-100">
									<Route
										render={(properties) => (
											<Component {...props} userData={userApp} />
										)}
									/>
								</div>
							</Row>
						</Container>
					</>
				) : isMap ? (
					<>
						<Container fluid className="map-container bg-gray">
							<Row>
								<Col lg="12">
									<Route
										render={(properties) => (
											<Component {...props} userData={userApp} />
										)}
									/>
								</Col>
							</Row>
						</Container>
					</>
				) : (
					<>
						<NavBar isAdmin={userApp && userApp.adminMenu} />
						<Container fluid className="main-container">
							<Row>
								<Col lg="12 py-4">
									<Route
										render={(properties) => (
											<Component {...props} userData={userApp} />
										)}
									/>
								</Col>
							</Row>
						</Container>
					</>
				)
			) : (
				<Container fluid className="main-container">
					<AccessDeniedPage />
				</Container>
			)}
		</>
	);
};

export default Layout;
