import React from 'react';
import { Modal, Button } from 'reactstrap';

const FullLateralModal = ({
	size = 'xxl',
	title,
	toggleModal,
	isOpen,
	stepBackFn,
	showBackButton,
	cancelButtonFn,
	hideCancel,
	nextButtonFn,
	disabledButtons,
	hiddenActions,
	isConfirming,
	confirmLabel = 'Crear evento',
	disableConfirm,
	isEditing,
	...props
}) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				modalClassName={`right size-${size}`}
				wrapClassName="modal-sidebar modal-fullwidth"
				toggle={toggleModal}
			>
				<div className="modal-header">
					{showBackButton && (
						<div
							className={`step-back ${disabledButtons ? 'step-disabled':''}`}
							onClick={() => !disabledButtons && (stepBackFn ? stepBackFn() : toggleModal())}
						>
							<i className="fticon-arrow-left"></i>
						</div>
					)}
					<div className="title">{title}</div>
					<div className="action-buttons">
						{!hiddenActions && !hideCancel && cancelButtonFn && (
							<Button
								type="submit"
								onClick={cancelButtonFn}
								className="btn-sec-light"
								disabled={disabledButtons}
							>
								Cancelar
							</Button>
						)}

						{!hiddenActions && nextButtonFn && (
							<Button
								type="submit"
								onClick={nextButtonFn}
								className="btn-sec-dark"
								disabled={disableConfirm ? disableConfirm : disabledButtons}
							>
								{isConfirming ? confirmLabel : isEditing ? 'Guardar':'Siguiente'}
							</Button>
						)}
					</div>
				</div>
				{props.children}
			</Modal>
		</>
	);
};

export default FullLateralModal;
