import React, { useState,useEffect } from 'react';

import { Accordion } from 'reactstrap';

const Acordeon = ({openID = null, ...props}) => {

   const [isOpen, setIsOpen] = useState(openID);
	const handleClick = (event) =>  isOpen !== event ? setIsOpen(event) : setIsOpen(null);

   useEffect(() => {
      setIsOpen(openID);
   }, [openID]);

	return (
		<>
			<Accordion toggle={handleClick} open={isOpen}>
				{props.children}
			</Accordion>
		</>
	);
};

export default Acordeon;
