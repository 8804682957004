import React from 'react';
import { Modal } from 'reactstrap';

const LateralModal = ({
	size = 'sm',
	title,
	toggleModal,
	isOpen,
	noHead,
	noCloseOutside,
	...props
}) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				modalClassName={`right size-${size}`}
				wrapClassName="modal-sidebar"
				toggle={toggleModal}
				backdrop={noCloseOutside ? 'static' : true}
			>
				{!noHead && (
					<div className="modal-header">
						<div className="title">{title}</div>
						<div className="close" onClick={toggleModal}>
							<i className="fticon-close"></i>
						</div>
					</div>
				)}
				{props.children}
			</Modal>
		</>
	);
};

export default LateralModal;
