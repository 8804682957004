import React from 'react';
import _ from 'lodash';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { parseFullDate, getDate } from 'utils/helpers';

import { FormGroup, Label } from 'reactstrap';

const InputDate = ({
	label,
	baseLabel,
	noSpace,
	reverse,
	disabled,
	//Formik
	field,
	form,
	innerRef,
	//Others
	classContainer = '',
	isClearable,
	extraClearFunction,
	extraFnChange,
	autoFill,
	...props
}) => {
	const error = form && form.errors && _.get(form.errors, field.name);
	const isDisabled = disabled ? true : false;

	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	};

	const handleCalendarOpen = () => {
		autoFill && field && !field.value && form.setFieldValue(field.name, parseFullDate(new Date()));
	}
	return (
		<div
			className={`input-type-date ${classContainer}${
				isClearable && field.value ? ' input-group' : ''
			}${isDisabled ? ' disabled' : ''}`}
		>
			<FormGroup
				className={`${!baseLabel ? 'form-floating flex-grow-1' : 'form-group'}${
					isDisabled ? ' disabled' : ''
				}${error && typeof error === 'string' ? ' has-error' : ''}`}
			>
				<DatePicker
					selected={field && field.value ? getDate(field.value) : ''}
					onChange={(date) => {
						form.setFieldValue(field.name, parseFullDate(date));
						form.setFieldTouched(field.name, true);
						extraFnChange && extraFnChange();
					}}
					locale={es}
					dateFormat="dd/MM/yyyy"
					className={`form-control ${
						field && field.value ? 'has-value' : 'no-value'
					} ${error && typeof error === 'string' ? 'border-error' : ''}`}
					onChangeRaw={handleDateChangeRaw}
					onBlur={() => form.setFieldTouched(field.name, true)}
					placeholderText={label}
					calendarClassName="calendar-dates"
					todayButton="Hoy"
					onCalendarOpen={handleCalendarOpen}
					disabled={disabled}
					id={`input-date-${field.name}`}
				/>

				<Label htmlFor={`input-date-${field.name}`} className={`${field && field.value ? 'has-value' : 'no-value'}`}>
					{label}
				</Label>
				<span className="input-date-icon"></span>
			</FormGroup>
			{isClearable && field.value && (
				<span
					className="input-group-text clear-input mb-3"
					onClick={() => {
						form.setFieldValue(field.name, '');
						extraClearFunction && extraClearFunction();
						extraFnChange && extraFnChange();
					}}
				>
					<i className="fticon-close"></i>
				</span>
			)}
		</div>
	);
};

export default InputDate;
