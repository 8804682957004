import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';

//services
import * as eventsApi from 'api/eventsApi';

//Components
import PageHeader from 'components/common/elems/PageHeader';
import TabsPage from 'components/common/elems/TabsPage';
import ItemEvent from 'components/common/elems/ItemEvent';
import EventLoader from 'components/loaders/EventLoader';
import EventsFilter from './EventsFilter';
import EventsForm from './EventsForm';
import LoadingLayer from 'components/loaders/LoadingLayer';

//helpers

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

const EventsPage = () => {
	let match = useRouteMatch('/eventos/:type');
	const viewType = match ? match.params.type : 'proximos';

	const MySwal = withReactContent(Swal);

	const history = useHistory();

	const tabsDef = [
		{ tabTitle: 'Próximos eventos', tabUrl: 'proximos', tabCount: 0 },
		{ tabTitle: 'Eventos anteriores', tabUrl: 'anteriores', tabCount: 0 },
	];

	const [modalBarOpen, setModalBarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [pageLoading, setPageLoading] = useState(false);
	const [currentFilter, setCurrentFilter] = useState({ pagina: 1 });

	const [modalData, setModalData] = useState({});

	const [eventsData, setEventsData] = useState([]);
	const [eventSites, setEventSites] = useState([]);
	const [uploadExcelId, setUploadExcelId] = useState(null);

	useEffect(() => {
		getSites();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCurrentFilter({ pagina: 1 });
		getEvents(viewType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const getSites = async () => {
		const data = await eventsApi.getEventSites();
		setEventSites(data.data);
	};

	const getEvents = async (
		typeEvent,
		filters = currentFilter,
		more = false
	) => {
		!more && setIsLoading(true);
		const data =
			typeEvent === 'proximos'
				? await eventsApi.getCurrentEvents(filters)
				: await eventsApi.getPastEvents(filters);

		more
			? setEventsData(_.concat(eventsData, data.data))
			: setEventsData(data.data);
		setCurrentFilter(filters);

		!more && setIsLoading(false);
	};

	const handleSubmitFilter = (filters) => {
		searchEvent({ ...filters, pagina: 1 });
	};

	const searchEvent = async (filters = currentFilter) => {
		setIsLoading(true);
		const data =
			viewType === 'proximos'
				? await eventsApi.getCurrentEvents(filters)
				: await eventsApi.getPastEvents(filters);

		setEventsData(data.data);
		setCurrentFilter(filters);
		setIsLoading(false);
	};

	const toggleModalBar = () => {
		setModalBarOpen(!modalBarOpen);
	};

	const activateEvent = async (eventId) => {
		const data = await eventsApi.activateEvent(eventId);
		if (data && data.succeeded) {
			notify.success('El evento se ha activado.');
			getSites();
			getEvents(viewType);
		} else {
			notify.error(`Ha ocurrido un error activando el evento. ${data.message}`);
		}
	};

	const showEvent = (eventId, newEvent) => {
		if (newEvent) {
			setModalData({});
			setModalBarOpen(true);
		} else {
			history.push(`/evento/${eventId}/dashboard`);
		}
	};

	const handleSubmitForm = async (values, formProps) => {
		if (values.invitados && values.invitados.length > 0) {
			submitFormSubmit(values, formProps);
		} else {
			const MySwal = withReactContent(Swal);
			MySwal.fire({
				title: 'No has añadido ningún invitado',
				text: '¿Quieres continuar creando el evento y añadirlos posteriormente?',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) submitFormSubmit(values, formProps);
			});
		}
	};

	const submitFormSubmit = async (values, formProps) => {
		setPageLoading(true);
		const eventForm = new FormData();
		eventForm.append('nombre', values.nombre);
		eventForm.append('descripcion', values.descripcion || '');
		eventForm.append('fechaEvento', values.fechaEventoDate);
		eventForm.append('horaEvento', values.horaEvento);
		eventForm.append('horaPhotoCall', values.horaPhotoCall);
		eventForm.append('horaApertura', values.horaApertura);
		eventForm.append('horaLimiteAcceso', values.horaLimiteAcceso);
		eventForm.append('fechaLimiteConfirm', values.fechaLimiteConfirmDate);
		eventForm.append('horaLimiteConfirm', values.horaLimiteConfirm);
		eventForm.append('documento', values.urlImagen);
		eventForm.append('salasId', values.salasIdArray.join(','));
		eventForm.append('lugarOrigenId', values.lugarOrigenId);
		eventForm.append('activo', values.activo);
		eventForm.append('esAnunciante', values.esAnunciante);
		eventForm.append('defaultInvitations', values.defaultInvitations);

		values.invitados.forEach((invitado, index) => {
			values.esAnunciante
				? eventForm.append(
						`invitados[${index}].contactoAnuncianteId`,
						invitado.contactoAnuncianteId || 0
				  )
				: eventForm.append(
						`invitados[${index}].contactoId`,
						invitado.contactoId || 0
				  );
		});

		const data = values.esAnunciante
			? await eventsApi.setAdvertEvent(eventForm)
			: await eventsApi.setPremiere(eventForm);

		if (data && data.succeeded) {
			formProps.resetForm({ values: defModelForm });
			history.push(`/evento/${data.data}/dashboard`);
		} else {
			notify.error(`Ha ocurrido un error creando el evento. ${data.message}`);
		}

		setPageLoading(false);
	};

	const defModelForm = {
		nombre: '',
		descripcion: '',
		fechaEventoDate: '',
		canEditData: true,
		canEditPlace: true,
		horaEvento: '',
		horaPhotoCall: '',
		horaApertura: '',
		horaLimiteAcceso: '',
		fechaLimiteConfirmDate: '',
		horaLimiteConfirm: '',
		urlImagen: null,
		invitados: [],
		salasIdArray: [],
		eventoLugar: {},
		lugarOrigenId: null,
		activo: true,
		esAnunciante: false,
		canDelete: true,
		defaultInvitations: 1,
	};

	const handleUploadExcel = async (event) => {
		event.preventDefault();
		setPageLoading(true);
		setUploadExcelId(null);

		const eventAux = event.target.files[0];

		if (eventAux) {
			const formData = new FormData();
			formData.append('file', eventAux);
			formData.append('fileName', eventAux.name);
			const data = await eventsApi.uploadContacts(formData);
			if (data && data.succeeded && data.message) {
				if (data.data.totalContactos > 0) {
					MySwal.fire({
						title: `Los siguientes contactos no se han cargado porque están dados de baja`,
						text: ` ${data.message} `,
						cancelButtonText: 'Cancelar',
						showCancelButton: false,
						confirmButtonText: 'Aceptar',
						focusConfirm: false,
						focusCancel: false,
						reverseButtons: true,
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn-prim-dark btn',
							cancelButton: 'btn-prim-light btn',
						},
					}).then(async (result) => {
						if (result && result.isConfirmed) {
							notify.success(
								`Se han importado ${data.data.totalContactos} contactos.`
							);
							setUploadExcelId(data.data.archivoId);
						}
					});
				} else {
					MySwal.fire({
						title: `Todos los contactos del archivo están dados de baja.`,
						cancelButtonText: 'Cancelar',
						showCancelButton: false,
						confirmButtonText: 'Aceptar',
						focusConfirm: false,
						focusCancel: false,
						reverseButtons: true,
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn-prim-dark btn',
							cancelButton: 'btn-prim-light btn',
						},
					}).then(async (result) => {});
				}
			} else {
				if (data && data.succeeded) {
					if (data.data.totalContactos > 0) {
						notify.success(
							`Se han importado ${data.data.totalContactos} contactos.`
						);
						setUploadExcelId(data.data.archivoId);
					} else {
						notify.error('No se ha podido importar ningún contacto.');
					}
				} else {
					notify.error(
						`Ha ocurrido un error al importar los contactos. ${data.message}`
					);
				}
			}
		}
		setPageLoading(false);
	};

	const handleDownloadExcel = async () => {
		try {
			const data = await eventsApi.getExcelTemplate();
			if (data && data.succeeded) {
				const link = document.createElement('a');
				link.href = data.data;
				link.setAttribute('download', 'fileName.xlsx');
				document.body.appendChild(link);
				link.click();
			} else {
				notify.error(
					`Ha ocurrido un error al descargar la plantilla. ${data.message}`
				);
			}
		} catch (ex) {
			notify.error(
				`Ha ocurrido un error al descargar la plantilla. ${ex.message}`
			);
		}
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			<div className="events-page">
				<PageHeader title={'Eventos'}>
					<Button
						className="btn-prim-dark"
						onClick={() => showEvent(null, true)}
					>
						Nuevo evento
					</Button>
				</PageHeader>

				<TabsPage selectedTab={viewType} tabList={tabsDef} baseUrl="eventos" />

				<Row>
					<Col lg="12">
						<Row className="pb-3">
							<Col md="12">
								<EventsFilter
									filterType={viewType}
									sitesList={eventSites}
									submitFilter={handleSubmitFilter}
								/>
							</Col>
						</Row>

						{isLoading ? (
							<Row>
								<Col md="3">
									<EventLoader />
								</Col>
							</Row>
						) : (
							<Row>
								<Col md="12">
									<div className="row events-grid">
										{eventsData && eventsData.length > 0 ? (
											eventsData.map((event, index) => {
												return (
													<ItemEvent
														item={event}
														key={index}
														onClickFn={() => showEvent(event.id, false)}
														onActivate={() => activateEvent(event.id)}
													/>
												);
											})
										) : (
											<div className="text-center py-5">
												No hay eventos disponibles
											</div>
										)}
									</div>
								</Col>
							</Row>
						)}
					</Col>
				</Row>

				{modalBarOpen && (
					<EventsForm
						modalBarOpen={modalBarOpen}
						toggleModalBar={toggleModalBar}
						modalData={modalData}
						defModelForm={defModelForm}
						handleSubmitForm={handleSubmitForm}
						handleUploadExcel={handleUploadExcel}
						uploadExcelId={uploadExcelId}
						handleDownloadExcel={handleDownloadExcel}
					/>
				)}
			</div>
		</>
	);
};

export default EventsPage;
