import React, { useEffect, useState } from 'react';

import { Col, Row, Container, ModalBody } from 'reactstrap';

//componentes
import WhiteBox from 'components/common/elems/WhiteBox';
import InvitationMailPreview from 'components/common/mail/InvitationMailPreview';
import TabsPage from 'components/common/elems/TabsPage';
import ContactsFilter from '../../../contacts/ContactsFilter';
import TableLoader from 'components/loaders/TableLoader';
import LoadingLayer from 'components/loaders/LoadingLayer';
import Sticker from 'components/common/elems/Sticker';
import TableVipFilter from 'components/common/elems/tables/TableVipFilter';
import TableRowOrder from 'components/common/elems/tables/TableRowOrder';
import EntradaSentHeader from '../tables/EntradaSentHeader';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroller';

//helpers
import {
	parseDateTimeFromString,
	statusSentClass,
} from 'utils/helpers';

//services
import * as eventsApi from 'api/eventsApi';

const EntradaSentDetails = ({ entradaData, eventDataSimple }) => {
	const [entradasList, setEntradasList] = useState([]);
	const [entradasTotal, setEntradasTotal] = useState(0);
	const [entradasTotalFilter, setEntradasTotalFilter] = useState(0);
	const [tableLoading, setTableLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [entradaTotalQRCode, setEntradaTotalQRCode] = useState(0);

	const [tagList, setTagList] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [origins, setOrigins] = useState([]);

	const [currentTab, setCurrentTab] = useState('actividad');

	const [filterStatus, setFilterStatus] = useState();
	const [filterVip, setFilterVip] = useState(null);
	const [filterOrder, setFilterOrder] = useState(null);
	const [currentFilter, setCurrentFilter] = useState({});

	const defHeader = [
		{ titulo: 'Sin abrir', count: 0, css: 'sinabrir' },
		{ titulo: 'Abiertas', count: 0, css: 'abiertas' },
		{ titulo: 'Rebotadas', count: 0, css: 'rebotadas' },
		{ titulo: 'Enviando', count: 0, css: 'enviando' },
		{ titulo: 'Error de envío', count: 0, css: 'errorenv' },
	];

	const [entradasMainHeader, setentradasMainHeader] = useState(defHeader);

	useEffect(() => {
		getSentInvitations({}, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tabsDef = [
		{ tabTitle: 'Actividad', tabUrl: 'actividad', tabCount: 0 },
		{ tabTitle: 'Datos generales', tabUrl: 'datos', tabCount: 0 },
	];

	const getSentInvitations = async (
		filters = currentFilter,
		inPage = false
	) => {
		inPage ? setPageLoading(true) : setTableLoading(true);

		const data = await eventsApi.getReceiversEntradas({
			...filters,
			entradaId: parseInt(entradaData.id),
			pagina: 1,
		});

		if (data && data.succeeded) {
			setEntradasList(data.data.receiverList);
			setentradasMainHeader(data.data.receiversMainStatus);

			setEntradasTotal(data.data.totalReceiverEntrada);
			setEntradasTotalFilter(data.data.totalReceiver);
			setEntradaTotalQRCode(data.data.totalQRCode);

			setCompanies(data.data.selectEmpresas);
			setOrigins(data.data.selectProcedencias);
			setTagList(data.data.selectEtiquetas);

			setCurrentFilter({ ...currentFilter, pagina: 1 });
		}

		inPage ? setPageLoading(false) : setTableLoading(false);
	};

	//---------------------------------

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreDestinatarios = async (newPage) => {
		if (
			currentFilter &&
			currentFilter.pagina &&
			newPage > currentFilter.pagina &&
			!isLoadingMore
		) {
			setIsLoadingMore(true);
			const data = await eventsApi.getReceiversEntradas({
				...currentFilter,
				pagina: newPage,
				entradaId: parseInt(entradaData.id),
			});
			if (data && data.succeeded) {
				setEntradasList((prevList) => [...prevList, ...data.data.receiverList]);
				setCurrentFilter({ ...currentFilter, pagina: newPage });
				setIsLoadingMore(false);
			}
		}
	};

	const totalPages = Math.ceil((entradasTotalFilter || 0) / 50);
	const currentPage = currentFilter?.pagina || 1;

	//---------------------------------

	const handleSubmitFilter = (values) => {
		getSentInvitations(values);
	};

	const handleStatusFilter = (newState) => {
		//setFilterStatus(newState)
		filterStatus === newState
			? setFilterStatus('todos')
			: setFilterStatus(newState);
	};

	return (
		<ModalBody className={currentTab === 'actividad' ? '' : 'p-0'}>
			{pageLoading && <LoadingLayer visible={pageLoading} />}

			<div
				className={
					currentTab === 'actividad' ? 'bg-gray' : 'bg-white padded-head'
				}
			>
				<Container fluid>
					<Row className="row-qr-header">
						<Col lg="8">
							<TabsPage
								selectedTab={currentTab}
								tabList={tabsDef}
								updateSelectedFn={setCurrentTab}
								noUrlChange
							/>
						</Col>
						<Col lg="4">
							<div className="div-qr">
								<div className="qrs-generados me-1">
									<span className="texto-qr">
										<i className="fticon-qr"></i>
										<span className="pl-4 ms-4 me-4">QR enviados</span>
										<span>
											<strong>{entradaTotalQRCode}</strong>
										</span>
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			{currentTab === 'actividad' ? (
				<Container fluid>
					<Row className="header-sentfilter mb-4">
						<Col md="12">
							<EntradaSentHeader
								categorias={entradasMainHeader}
								filterStatus={filterStatus}
								handleStatusFilter={handleStatusFilter}
							/>
						</Col>
					</Row>

					<Row>
						<Col lg="12">
							{entradasTotal ? (
								<WhiteBox containerClass="full-box invitados-list">
									<Row>
										<Col md="12">
											<ContactsFilter
												tagList={tagList}
												companies={companies}
												fromOrigin={origins}
												submitFilter={handleSubmitFilter}
												vipFilter={filterVip}
												orderFilter={filterOrder}
												statusFilter={filterStatus}
												disabled={tableLoading}
											/>
										</Col>
									</Row>

									<Row className="table-header mt-2 pb-3">
										<Col md="5">
											<div className="col-star">
												<TableVipFilter
													filterVip={filterVip}
													setFilterVip={setFilterVip}
												/>
												<TableRowOrder
													selectedOp={filterOrder}
													selectFn={setFilterOrder}
												/>
											</div>
										</Col>
										<Col md="5">Email</Col>
										<Col md="2" className="text-right">
											Estado
										</Col>
									</Row>

									<Row>
										<Col md="12">
											<div className="contact-list">
												{tableLoading ? (
													<TableLoader />
												) : entradasList && entradasList.length > 0 ? (
													<div className="scrollable-contacts">
														<InfiniteScroll
															pageStart={0}
															loadMore={() =>
																loadMoreDestinatarios(currentPage + 1)
															}
															hasMore={currentPage < totalPages}
															useWindow={false}
															loader={
																<div key={0} style={{ clear: 'both' }}>
																	<TableLoader size="xs" />
																</div>
															}
														>
															{entradasList.map((contact, index) => {
																return (
																	<Row key={index} className="table-row">
																		<Col md="5">
																			<div className="col-star">
																				<div className="vip">
																					{contact.destacado && (
																						<i className="fticon-star-solid"></i>
																					)}
																				</div>
																				<div className="name">
																					{contact.nombreContacto}
																					{contact.invitado?.procedencia && (
																						<div className="sub-proc">
																							{
																								contact.invitado.procedencia
																									.label
																							}
																						</div>
																					)}
																				</div>
																			</div>
																		</Col>
																		<Col md="5" className="col-center">
																			<div className="mail-list">
																				{contact.email}
																			</div>
																		</Col>
																		<Col
																			md="2"
																			className="col-center col-sticker"
																		>
																			<div
																				data-tip
																				data-for={`ststat-${contact.invitadoId}`}
																			>
																				<Sticker
																					text={
																						contact.estadoEnvio?.estado ||
																						'Sin abrir'
																					}
																					color={statusSentClass(
																						contact.estadoEnvio?.estado
																					)}
																				/>
																			</div>

																			{contact.estadoEnvio &&
																				contact.estadoEnvio.tooltip && (
																					<ReactTooltip
																						id={`ststat-${contact.invitadoId}`}
																					>
																						<div className="tt-into">
																							<p className="mb-0">
																								{contact.estadoEnvio.tooltip}
																							</p>
																						</div>
																					</ReactTooltip>
																				)}
																		</Col>
																	</Row>
																);
															})}
														</InfiniteScroll>
													</div>
												) : (
													<div className="no-results py-5">
														No se han encontrado resultados.
													</div>
												)}
											</div>
										</Col>
									</Row>
								</WhiteBox>
							) : (
								<WhiteBox containerClass="full-box empty">
									<div className="no-results">
										<h3>No hay resultados disponibles</h3>
									</div>
								</WhiteBox>
							)}
						</Col>
					</Row>
				</Container>
			) : (
				<>
					<div className="bg-white padded-body">
						<Container fluid>
							<Row className="pt-4 info-mail">
								<Col lg="12">
									<Row className="mb-2">
										<Col md="2">Fecha de envío:</Col>
										<Col md="10">
											{parseDateTimeFromString(entradaData?.fechaEnvio)}
										</Col>
									</Row>

									<Row className="mb-2">
										<Col md="2">Asunto:</Col>
										<Col md="10">{entradaData?.asunto}</Col>
									</Row>

									<Row className="mb-2">
										<Col md="2">De:</Col>
										<Col md="10">{`${entradaData?.sendFromNombre} · ${entradaData?.sendFromEmail}`}</Col>
									</Row>

									<Row className="mb-3">
										<Col md="2">Enviado a:</Col>
										<Col md="10">{`${entradaData?.totalEnviadas} destinatarios`}</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</div>
					<Container fluid>
						<Row className="preview-container">
							<Col lg="12 mt-5">
								<InvitationMailPreview
									invitationEvent={eventDataSimple}
									invitationData={entradaData}
									previewEntrada
								/>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</ModalBody>
	);
};

export default EntradaSentDetails;
