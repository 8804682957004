import React from 'react';
import ContentLoader from 'react-content-loader';

const AsistentLoader = (props) => (
	<ContentLoader
		viewBox="0 0 420 160"
		backgroundColor="#eceef4"
		foregroundColor="#e1e1ed"
		title="Cargando"
		{...props}
	>
		<rect x="0" y="0" rx="4" ry="4" width="420" height="12" />
		<rect x="0" y="20" rx="4" ry="4" width="380" height="12" />
		<rect x="0" y="40" rx="4" ry="4" width="380" height="12" />

		<rect x="0" y="80" rx="4" ry="4" width="420" height="12" />
		<rect x="0" y="100" rx="4" ry="4" width="380" height="12" />
		<rect x="0" y="120" rx="4" ry="4" width="380" height="12" />
	</ContentLoader>
);

export default AsistentLoader;
