//Global CSS
import './css/app.scss';

//Enrutado
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from 'react-router-dom';
import Layout from './components/Layout';

//-----------------------------------------------

import { USER_ROLE } from './utils/helpers';

//Contactos
import ContactsPage from './pages/contacts/ContactsPage';

//Luares
import SitesPage from './pages/sites/SitesPage';

//Eventos
import EventsPage from './pages/events/EventsPage';
import EventPage from './pages/events/EventPage';

//Admin
import AdminUsersPage from './pages/admin/AdminUsersPage';
import AdminAuditsPage from './pages/admin/AdminAuditsPage';
import MapEditor from './pages/maps/MapEditor';

//Genericos
import NotFoundPage from './pages/error/NotFoundPage';


const App = () => {
	return (
		 <Router>
				<Switch>

					<Route exact path="/"><Redirect to="/eventos" /></Route>

					<Layout path="/contactos/:type" component={ContactsPage} role={USER_ROLE.rol_contact} />
					<Layout path="/contactos" component={ContactsPage} role={USER_ROLE.rol_contact} />

					<Layout path="/evento/:idevent/planos" component={EventPage} role={USER_ROLE.rol_event} isMap />
					<Layout path="/evento/:idevent/:idsection" component={EventPage} role={USER_ROLE.rol_event} isEvent />
					
					<Layout path="/eventos/:type" component={EventsPage} role={USER_ROLE.rol_event} />
					<Layout path="/eventos" component={EventsPage} role={USER_ROLE.rol_event} />

					<Layout path="/lugares/:type" component={SitesPage} role={USER_ROLE.rol_sites} />
					<Layout path="/lugares" component={SitesPage} role={USER_ROLE.rol_sites} />
					<Layout path="/mapa/:idmap" component={MapEditor} role={USER_ROLE.rol_sites} isMap />

					<Layout path="/auditoria" component={AdminAuditsPage} role={USER_ROLE.admin_auditor} dev />
					<Layout path="/usuarios" component={AdminUsersPage} role={USER_ROLE.admin_users} />

					<Layout component={NotFoundPage} />
					
				</Switch>
			</Router>
	);
 }

 export default App;


