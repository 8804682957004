import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import {
	Button,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//services
import * as eventsApi from 'api/eventsApi';

//components
import TabsPage from 'components/common/elems/TabsPage';
import WhiteBox from 'components/common/elems/WhiteBox';
import TableLoader from 'components/loaders/TableLoader';
import LoadingLayer from 'components/loaders/LoadingLayer';
import EventDBnuevaNotificacion from './EventDBnuevaNotificacion';

// helpers
import {
	parseDateTimeFromString,
	statusSentClass,
} from 'utils/helpers';
const EventDBNotificaciones = ({ eventDataSimple }) => {
	let match = useRouteMatch('/evento/:idevent/notificaciones/:type?');
	const eventID = match.params ? match.params.idevent : null;
	const viewType =
		match && match.params.type !== undefined ? match.params.type : 'todas';

	const [pageLoading, setPageLoading] = useState(false);

	const MySwal = withReactContent(Swal);

	const tabsDef = [
		{ tabTitle: 'Todas', tabUrl: 'todas', tabCount: 0 },
		{ tabTitle: 'Borradores', tabUrl: 'borradores', tabCount: 0 },
		{ tabTitle: 'Enviadas', tabUrl: 'enviadas', tabCount: 0 },
	];

	const [tabList, setTabList] = useState(tabsDef);
	const [notificacionesData, setNotificacionesData] = useState([]);
	const [notificacionesTodas, setNotificacionesTodas] = useState([]);
	const [notificacionesEnviadas, setNotificacionesEnviadas] = useState([]);
	const [notificacionesBorrador, setNotificacionesBorrador] = useState([]);

	const [companiesLogoList, setCompaniesLogoList] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [modalNuevaOpen, setModalNuevaOpen] = useState(false);
	const [tipoAnunciante, setTipoAnunciante] = useState(false);

	const [modalNotificationData, setModalNotificationData] = useState(null);

	useEffect(() => {
		getNotificaciones();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		selectNotificaciones();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const getNotificaciones = async () => {
		setIsLoading(true);
		const data = await eventsApi.getNotifications(eventID);
		if (data && data.succeeded && data.data.notificationsList) {
			setNotificacionesTodas(data.data.notificationsList);
			setCompaniesLogoList(data.data.companyLogos);
			const notEnviadas = data.data.notificationsList.filter(
				(x) => x.fechaEnvio !== null
			);
			const notBorrador = data.data.notificationsList.filter(
				(x) => x.fechaEnvio === null
			);
			setNotificacionesEnviadas(notEnviadas);
			setNotificacionesBorrador(notBorrador);
			setTipoAnunciante(data.data.tipoAnunciante);

			const updatedTabs = tabsDef.map((tab, index) => ({
				...tab,
				tabCount:
					index === 0
						? data.data.totalNotifications
						: index === 1
						? data.data.totalTemplate
						: data.data.totalSent,
			}));

			switch (viewType) {
				case 'borradores':
					setNotificacionesData(notBorrador);
					break;
				case 'enviadas':
					setNotificacionesData(notEnviadas);
					break;
				default:
					setNotificacionesData(data.data.notificationsList);
			}
			setTabList(updatedTabs);
		}
		setIsLoading(false);
	};

	const selectNotificaciones = () => {
		switch (viewType) {
			case 'borradores':
				setNotificacionesData(notificacionesBorrador);
				break;
			case 'enviadas':
				setNotificacionesData(notificacionesEnviadas);
				break;
			default:
				setNotificacionesData(notificacionesTodas);
		}
	};

	const reloadNotificaciones = () => {
		getNotificaciones();
	};

	const handelDuplicate = async (notificationId, notificacionNombre) => {
		const data = await eventsApi.getCurrentEvents({});
		let options = {};
		if (data.data.length > 0) {
			_.map(data.data, function (item) {
				options[item.id] =
					item.id.toString() === eventID ? 'Este mismo evento' : item.nombre;
			});
		}

		MySwal.fire({
			title: 'Duplicar Notificacion',
			html: `Selecciona el evento en el que quieres duplicar <b>${notificacionNombre}</b>`,
			input: 'select',
			inputOptions: options,
			inputValue: eventID,
			inputPlaceholder: 'Evento',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			confirmButtonText: 'Aceptar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (result && result.value) {
					const data = await eventsApi.cloneNotification(
						notificationId,
						result.value
					);
					if (data && data.succeeded) {
						reloadNotificaciones();
						notify.success('La notificación se ha duplicado correctamente.');
					} else {
						notify.error(
							`Ha ocurrido un error duplicando la notificación. ${data.message}`
						);
					}
				} else {
					notify.error('No has seleccionado ningún evento en el desplegable.');
				}
			}
		});
	};

	const handleRemove = (notificacionId) => {
		MySwal.fire({
			title: '¿Quieres eliminar la Notificación?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.deleteNotification(notificacionId);
				if (data && data.succeeded) {
					notify.success('La notificación se ha eliminado correctamente.');
					reloadNotificaciones();
				} else {
					notify.error('Ha ocurrido un error eliminando la notificación.');
				}
			}
		});
	};

	const showNotification = (editData) => {
		setModalNotificationData(editData);
		toggleModalBar();
	};

	const toggleModalBar = () => {
		modalNuevaOpen && setModalNotificationData(null);
		setModalNuevaOpen(!modalNuevaOpen);
	};

	const handleSubmitForm = async (values, formProps) => {
		let formData = new FormData();
		Object.keys(values).forEach((key) => {
			//filtra nulos
			if (values[key]) {
				if (_.isObject(values[key])) {
					//limpia objetos y file
					if (values[key].size) {
						formData.append(key, values[key]);
					} else {
						Object.keys(values[key]).forEach((subkey) =>
							formData.append(`${key}[${subkey}]`, values[key][subkey])
						);
					}
				} else if (_.isArray(values[key])) {
					//limpia arrays
					values[key].map((elem, index) =>
						formData.append(`${key}[${index}]`, values[key][index])
					);
				} else {
					//single value
					formData.append(key, values[key]);
				}
			}
		});

		var data = await eventsApi.setNotification(formData);
		if (data && data.succeeded) {
			setModalNotificationData(data.data);
			reloadNotificaciones();
		} else {
			notify.error(
				`Ha ocurrido un error creando la notificación. ${data.message}`
			);
		}
	};

	const handleSendNotification = (totalDest, notificacionId) => {
		MySwal.fire({
			title: `Estás a punto de enviar la notificación a ${totalDest} contacto${
				totalDest !== 1 ? 's' : ''
			}`,
			text: 'Por favor, asegúrate de haberla comprobado y revisado cuidadosamente. No se podrá cancelar el envío una vez se haya iniciado.',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			confirmButtonText: 'Enviar notificaciones',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				container: 'send-invitation-modal',
				confirmButton: 'btn-sec-dark btn',
				cancelButton: 'btn-sec-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.sendNotification(notificacionId);
				if (data && data.succeeded) {
					notify.success('La notificación se ha enviado correctamente.');
					toggleModalBar();
					reloadNotificaciones();
				} else {
					notify.error(
						`Ha ocurrido un error enviando la notificación. ${data.message}`
					);
				}
			}
		});
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			<div className="dashboard-page dashboard-invitaciones">
				<Row className="header-dash">
					<Col md="10">
						<h3>Notificaciones</h3>
					</Col>
					<Col md="2" className="text-end">
						<Button
							className="btn-prim-dark mt-1"
							onClick={() => showNotification(null)}
						>
							Nueva notificación
						</Button>
					</Col>
				</Row>
				<Container fluid className="main-container bg-gray">
					<div className="ml-4">
						<TabsPage
							selectedTab={viewType}
							tabList={tabList}
							baseUrl={`evento/${eventID}/notificaciones`}
						/>
					</div>
					<Row>
						<Col lg="12">
							<WhiteBox containerClass="user-list">
								<Row className="table-header mt-2 pb-3">
									<Col md="4" className="">
										<div>Notificación</div>
									</Col>
									<Col md="2" className="text-center">
										Etiqueta
									</Col>
									<Col md="1" className="text-center">
										Enviadas
									</Col>
									<Col md="1" className="text-center">
										Abiertas
									</Col>
									<Col md="1" className="text-center">
										Rebotadas
									</Col>
									<Col md="2" className="text-center">
										Fecha de envío
									</Col>
								</Row>
								<Row>
									<Col md="12">
										{isLoading ? (
											<TableLoader />
										) : (
											<Col lg="12">
												{notificacionesData && notificacionesData.length > 0 ? (
													notificacionesData.map((notificacion, index) => {
														const color = _.isNull(notificacion.fechaEnvio)
															? 'st-borrador'
															: notificacion.estadoEnvio &&
															  notificacion.estadoEnvio.estado
															? statusSentClass(notificacion.estadoEnvio.estado)
															: notificacion.fechaEnvio
															? 'st-enviada'
															: 'st-sinenviar';
														const textoEtiqueta = _.isNull(
															notificacion.fechaEnvio
														)
															? 'Borrador'
															: notificacion.estadoEnvio &&
															  notificacion.estadoEnvio.estado
															? notificacion.estadoEnvio.estado
															: notificacion.fechaEnvio
															? 'Enviada'
															: 'Sin enviar';
														return (
															<Row
																key={index}
																className="table-row"
																onClick={() => showNotification(notificacion)}
															>
																<Col md="4" className="col-name">
																	<div className="name">
																		{notificacion.nombre}
																	</div>
																</Col>
																<Col md="2" className="text-center">
																	<div className={`sticker sticker-${color}`}>
																		{textoEtiqueta}
																	</div>
																</Col>
																<Col md="1" className="text-center">
																	<div className="name">
																		{notificacion.totalSent !== 0
																			? notificacion.totalSent
																			: '-'}
																	</div>
																</Col>
																<Col md="1" className="text-center">
																	<div className="name">
																		{notificacion.totalOpened !== 0
																			? notificacion.totalOpened
																			: '-'}
																	</div>
																</Col>
																<Col md="1" className="text-center">
																	<div className="name">
																		{notificacion.totalReturned !== 0
																			? notificacion.totalReturned
																			: '-'}
																	</div>
																</Col>
																<Col md="2" className="text-center">
																	<div className="date">
																		{notificacion.fechaEnvio
																			? parseDateTimeFromString(
																					notificacion.fechaEnvio,
																					'dd/MM/yy - HH:mm'
																			  )
																			: '-'}
																	</div>
																</Col>
																<Col md="1" className="text-end">
																	<UncontrolledDropdown
																		onClick={(e) => e.stopPropagation()}
																	>
																		<DropdownToggle color="submenu">
																			<i className="fticon-submenu"></i>
																		</DropdownToggle>
																		<DropdownMenu end>
																			<DropdownItem
																				onClick={() =>
																					handelDuplicate(
																						notificacion.id,
																						notificacion.nombre
																					)
																				}
																			>
																				Duplicar
																			</DropdownItem>
																			{notificacion &&
																				notificacion.fechaEnvio == null && (
																					<DropdownItem
																						onClick={() =>
																							handleRemove(notificacion.id)
																						}
																					>
																						Eliminar
																					</DropdownItem>
																				)}
																		</DropdownMenu>
																	</UncontrolledDropdown>
																</Col>
															</Row>
														);
													})
												) : (
													<Row>
														<Col className="text-center py-5">
															No se han encontrado notificaciones
														</Col>
													</Row>
												)}
											</Col>
										)}
									</Col>
								</Row>
							</WhiteBox>
						</Col>
					</Row>
				</Container>
				{modalNuevaOpen && (
					<EventDBnuevaNotificacion
						modalNuevaOpen={modalNuevaOpen}
						toggleModalBar={toggleModalBar}
						notificationData={modalNotificationData}
						setNotificationData={setModalNotificationData}
						tipoAnunciante={tipoAnunciante}
						handleSubmitForm={handleSubmitForm}
						handleSendNotification={handleSendNotification}
						eventDataSimple={eventDataSimple}
						companyLogos={companiesLogoList}
					/>
				)}
			</div>
		</>
	);
};

export default EventDBNotificaciones;
