import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import {
	Button,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//services
import * as eventsApi from 'api/eventsApi';

//components
import TabsPage from 'components/common/elems/TabsPage';
import WhiteBox from 'components/common/elems/WhiteBox';
import TableLoader from 'components/loaders/TableLoader';
import LoadingLayer from 'components/loaders/LoadingLayer';
import EventDBnuevaInvitacion from './EventDBnuevaInvitacion';

// helpers
import { parseDateTimeFromString } from 'utils/helpers';

const EventDBInvitaciones = ({ eventDataSimple }) => {
	let match = useRouteMatch('/evento/:idevent/invitaciones/:type?');
	const eventID = match.params ? match.params.idevent : null;
	const viewType =
		match && match.params.type !== undefined ? match.params.type : 'todas';

	const [pageLoading, setPageLoading] = useState(false);

	const MySwal = withReactContent(Swal);

	const tabsDef = [
		{ tabTitle: 'Todas', tabUrl: 'todas', tabCount: 0 },
		{ tabTitle: 'Borradores', tabUrl: 'borradores', tabCount: 0 },
		{ tabTitle: 'Enviadas', tabUrl: 'enviadas', tabCount: 0 },
	];

	const [tabList, setTabList] = useState(tabsDef);
	const [invitacionesData, setInvitacionesData] = useState([]);
	const [invitacionesTodas, setInvitacionesTodas] = useState([]);
	const [invitacionesEnviadas, setInvitacionesEnviadas] = useState([]);
	const [invitacionesBorrador, setInvitacionesBorrador] = useState([]);

	const [companiesLogoList, setCompaniesLogoList] = useState([]);

	const [isLoading, setIsLoading] = useState(false);
	const [modalNuevaOpen, setModalNuevaOpen] = useState(false);
	const [tipoAnunciante, setTipoAnunciante] = useState(false);

	const [modalInvitationData, setModalInvitationData] = useState(null);

	useEffect(() => {
		getInvitaciones();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		selectInvitaciones();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const getInvitaciones = async () => {
		setIsLoading(true);
		const data = await eventsApi.getInvitations(eventID);
		if (data && data.succeeded && data.data.invitationsList) {
			setInvitacionesTodas(data.data.invitationsList);
			setCompaniesLogoList(data.data.companyLogos);
			const invEnviadas = data.data.invitationsList.filter(
				(x) => x.fechaEnvio !== null
			);
			const invBorrador = data.data.invitationsList.filter(
				(x) => x.fechaEnvio === null
			);
			setInvitacionesEnviadas(invEnviadas);
			setInvitacionesBorrador(invBorrador);
			setTipoAnunciante(data.data.tipoAnunciante);

			const updatedTabs = tabsDef.map((tab, index) => ({
				...tab,
				tabCount:
					index === 0
						? data.data.totalInvitations
						: index === 1
						? data.data.totalTemplate
						: data.data.totalSent,
			}));

			switch (viewType) {
				case 'borradores':
					setInvitacionesData(invBorrador);
					break;
				case 'enviadas':
					setInvitacionesData(invEnviadas);
					break;
				default:
					setInvitacionesData(data.data.invitationsList);
			}
			setTabList(updatedTabs);
		}
		setIsLoading(false);
	};

	const selectInvitaciones = () => {
		switch (viewType) {
			case 'borradores':
				setInvitacionesData(invitacionesBorrador);
				break;
			case 'enviadas':
				setInvitacionesData(invitacionesEnviadas);
				break;
			default:
				setInvitacionesData(invitacionesTodas);
		}
	};

	const reloadInvitaciones = () => {
		getInvitaciones();
	};

	const handelDuplicate = async (invitationId, invitacionNombre) => {
		const data = await eventsApi.getCurrentEvents({});
		let options = {};
		if (data.data.length > 0) {
			_.map(data.data, function (item) {
				options[item.id] =
					item.id.toString() === eventID ? 'Este mismo evento' : item.nombre;
			});
		}

		MySwal.fire({
			title: 'Duplicar Invitación',
			html: `Selecciona el evento en el que quieres duplicar <b>${invitacionNombre}</b>`,
			input: 'select',
			inputOptions: options,
			inputValue: eventID,
			inputPlaceholder: 'Evento',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			confirmButtonText: 'Aceptar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (result && result.value) {
					const data = await eventsApi.cloneInvitation(
						invitationId,
						result.value
					);
					if (data && data.succeeded) {
						reloadInvitaciones();
						notify.success('La invitacion se ha duplicado correctamente.');
					} else {
						notify.error(
							`Ha ocurrido un error duplicando la invitacion. ${data.message}`
						);
					}
				} else {
					notify.error('No has seleccionado ningún evento en el desplegable.');
				}
			}
		});
	};

	const handleRemove = (invitacionId) => {
		MySwal.fire({
			title: '¿Quieres eliminar Invitación?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.deleteInvitation(invitacionId);
				if (data && data.succeeded) {
					notify.success('La invitación se ha eliminado correctamente.');
					reloadInvitaciones();
				} else {
					notify.error('Ha ocurrido un error eliminando La invitación.');
				}
			}
		});
	};

	const showInvitation = (editData) => {
		setModalInvitationData(editData);
		toggleModalBar();
	};

	const toggleModalBar = () => {
		modalNuevaOpen && setModalInvitationData(null);
		setModalNuevaOpen(!modalNuevaOpen);
	};

	const handleSubmitForm = async (values, formProps) => {
		setPageLoading(true);
		let formData = new FormData();
		Object.keys(values).forEach((key) => {
			//filtra nulos
			if (values[key]) {
				if (_.isObject(values[key])) {
					//limpia objetos y file
					if (values[key].size) {
						formData.append(key, values[key]);
					} else {
						Object.keys(values[key]).forEach((subkey) =>
							formData.append(`${key}[${subkey}]`, values[key][subkey])
						);
					}
				} else if (_.isArray(values[key])) {
					//limpia arrays
					values[key].map((elem, index) =>
						formData.append(`${key}[${index}]`, values[key][index])
					);
				} else {
					//single value
					formData.append(key, values[key]);
				}
			}
		});

		var data = await eventsApi.setInvitation(formData);
		if (data && data.succeeded) {
			setModalInvitationData(data.data);
			reloadInvitaciones();
		} else {
			notify.error(
				`Ha ocurrido un error creando la invitación. ${data.message}`
			);
		}
		setPageLoading(false);
	};

	const handleSendInvitation = (totalDest, invitacionId) => {
		MySwal.fire({
			title: `Estás a punto de enviar la invitación a ${totalDest} contacto${
				totalDest !== 1 ? 's' : ''
			}`,
			text: 'Por favor, asegúrate de haberla comprobado y revisado cuidadosamente. No se podrá cancelar el envío una vez se haya iniciado.',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			confirmButtonText: 'Enviar invitaciones',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				container: 'send-invitation-modal',
				confirmButton: 'btn-sec-dark btn',
				cancelButton: 'btn-sec-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.sendInvitation(invitacionId);
				if (data && data.succeeded) {
					notify.success('La invitación se ha enviado correctamente.');
					toggleModalBar();
					reloadInvitaciones();
				} else {
					notify.error(
						`Ha ocurrido un error enviando La invitación. ${data.message}`
					);
				}
			}
		});
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			<div className="dashboard-page dashboard-invitaciones">
				<Row className="header-dash">
					<Col md="10">
						<h3>Invitaciones</h3>
					</Col>
					<Col md="2" className="text-end">
						<Button
							className="btn-prim-dark mt-1"
							onClick={() => showInvitation(null)}
						>
							Nueva invitacion
						</Button>
					</Col>
				</Row>
				<Container fluid className="main-container bg-gray">
					<div className="ml-4">
						<TabsPage
							selectedTab={viewType}
							tabList={tabList}
							baseUrl={`evento/${eventID}/invitaciones`}
						/>
					</div>
					<Row>
						<Col lg="12">
							<WhiteBox containerClass="user-list">
								<Row className="table-header mt-2 pb-3">
									<Col md="4" className="">
										<div>Invitación</div>
									</Col>
									<Col md="2" className="text-center">
										Etiqueta
									</Col>
									<Col md="1" className="text-center">
										Enviadas
									</Col>
									<Col md="1" className="text-center">
										Abiertas
									</Col>
									<Col md="1" className="text-center">
										Rebotadas
									</Col>
									<Col md="2" className="text-center">
										Fecha de envío
									</Col>
								</Row>
								<Row>
									<Col md="12">
										{isLoading ? (
											<TableLoader />
										) : (
											<Col lg="12">
												{invitacionesData && invitacionesData.length > 0 ? (
													invitacionesData.map((invitacion, index) => {
														const color = _.isNull(invitacion.fechaEnvio)
															? 'st-borrador'
															: 'st-enviada';
														const textoEtiqueta = _.isNull(
															invitacion.fechaEnvio
														)
															? 'Borrador'
															: 'Enviada';

														return (
															<Row
																key={index}
																className="table-row"
																onClick={() => showInvitation(invitacion)}
															>
																<Col md="4" className="col-name">
																	<div className="name">
																		{invitacion.nombre}
																	</div>
																</Col>
																<Col md="2" className="text-center">
																	<div className={`sticker sticker-${color}`}>
																		{textoEtiqueta}
																	</div>
																</Col>
																<Col md="1" className="text-center">
																	<div className="name">
																		{invitacion.totalSent !== 0
																			? invitacion.totalSent
																			: '-'}
																	</div>
																</Col>
																<Col md="1" className="text-center">
																	<div className="name">
																		{invitacion.totalOpened !== 0
																			? invitacion.totalOpened
																			: '-'}
																	</div>
																</Col>
																<Col md="1" className="text-center">
																	<div className="name">
																		{invitacion.totalReturned !== 0
																			? invitacion.totalReturned
																			: '-'}
																	</div>
																</Col>
																<Col md="2" className="text-center">
																	<div className="date">
																		{invitacion.fechaEnvio
																			? parseDateTimeFromString(
																					invitacion.fechaEnvio,
																					'dd/MM/yy - HH:mm'
																			  )
																			: '-'}
																	</div>
																</Col>
																<Col md="1" className="text-end">
																	<UncontrolledDropdown
																		onClick={(e) => e.stopPropagation()}
																	>
																		<DropdownToggle color="submenu">
																			<i className="fticon-submenu"></i>
																		</DropdownToggle>
																		<DropdownMenu end>
																			<DropdownItem
																				onClick={() =>
																					handelDuplicate(
																						invitacion.id,
																						invitacion.nombre
																					)
																				}
																			>
																				Duplicar
																			</DropdownItem>
																			{invitacion &&
																				invitacion.fechaEnvio == null && (
																					<DropdownItem
																						onClick={() =>
																							handleRemove(invitacion.id)
																						}
																					>
																						Eliminar
																					</DropdownItem>
																				)}
																		</DropdownMenu>
																	</UncontrolledDropdown>
																</Col>
															</Row>
														);
													})
												) : (
													<Row>
														<Col className="text-center py-5">
															No se han encontrado invitaciones
														</Col>
													</Row>
												)}
											</Col>
										)}
									</Col>
								</Row>
							</WhiteBox>
						</Col>
					</Row>
				</Container>
				{modalNuevaOpen && (
					<EventDBnuevaInvitacion
						modalNuevaOpen={modalNuevaOpen}
						toggleModalBar={toggleModalBar}
						invitationData={modalInvitationData}
						setInvitationData={setModalInvitationData}
						tipoAnunciante={tipoAnunciante}
						handleSubmitForm={handleSubmitForm}
						handleSendInvitation={handleSendInvitation}
						eventDataSimple={eventDataSimple}
						companyLogos={companiesLogoList}
					/>
				)}
			</div>
		</>
	);
};

export default EventDBInvitaciones;
