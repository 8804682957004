import React from 'react';
import { Button, ModalBody, Row, Col } from 'reactstrap';

//forms
import { Form, Field } from 'formik';
import InputForm from 'components/common/forms/InputForm';

const MapNumericForm = ({
	toggleModal,
	areaData,
	isOpen,
	formikValues,
	formikSetField,
}) => {
	return (
		<>
			<div className={`overlay-bottom-map ${isOpen ? 'show' : ''}`}>
				<Form autoComplete="off">
					<div className="modal-header">
						<div className="title">
							Edición de la {areaData.type === 'row' ? 'fila' : 'columna'}
						</div>
						<div className="action-buttons">
							<Button
								type="button"
								onClick={toggleModal}
								className="btn-sec-light"
							>
								Cancelar
							</Button>
							<Button type="submit" className="btn-sec-dark">
								Guardar
							</Button>
						</div>
					</div>

					<ModalBody>
						<div className="area-edit-form pt-4 px-4">
							<Row>
								<Col md="2">
									<Field
										label={`Número de ${
											areaData.type === 'row' ? 'fila' : 'asiento'
										}`}
										placeholder={`Número de ${
											areaData.type === 'row' ? 'fila' : 'asiento'
										}`}
										name="valueNumber"
										component={InputForm}
										type="number"
										min={0}
										max={999}
										disabled={formikValues.isEmpty
												? true
												: false
										}
									/>
								</Col>

								<Col md="10">
									<div className="custom-inputs">
										<div className="box-input">
											<i className="fticon-sitting"></i>
											<Field
												component={InputForm}
												baseLabel
												type="switch"
												label={`${
													areaData.type === 'row' ? 'Fila' : 'Columna'
												} vacia`}
												name="isEmpty"
												reverse
												onChange={(e) => {
													formikSetField('isEmpty', e.target.checked);
														e.target.checked &&
														formikSetField('valueNumber', null);
												}}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</ModalBody>
				</Form>
			</div>
		</>
	);
};

export default MapNumericForm;
