import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

import { Col, Row, ModalBody, Button, ModalFooter } from 'reactstrap';

//componentes
import FullLateralModal from 'components/common/modals/FullLateralModal';
import LateralModal from 'components/common/modals/LateralModal';
import WhiteBox from 'components/common/elems/WhiteBox';
import NotificationMailPreview from 'components/common/mail/NotificationMailPreview';
import ContactsFilter from 'pages/contacts/ContactsFilter';
import TableLoader from 'components/loaders/TableLoader';
import LoadingLayer from 'components/loaders/LoadingLayer';
import TableVipFilter from 'components/common/elems/tables/TableVipFilter';
import TableRowOrder from 'components/common/elems/tables/TableRowOrder';
import NotificationSentDetails from './modals/NotificationSentDetails';
import InfiniteScroll from 'react-infinite-scroller';
import { Portal } from '@mui/base';

//services
import * as eventsApi from 'api/eventsApi';

//forms
import { Formik, Form, Field } from 'formik';
import { IMAGE_TYPES_ALLOWED } from 'utils/helpers';
import InputImage from 'components/common/forms/InputImage';
import CheckboxNF from 'components/common/forms/CheckboxNF';
import InputForm from 'components/common/forms/InputForm';
import InputUploadFile from 'components/common/forms/InputUploadFile';
import InputSelect from 'components/common/forms/InputSelect';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//helpers

const EventDBnuevaNotificacion = ({
	modalNuevaOpen,
	toggleModalBar,
	notificationData,
	setNotificationData,
	tipoAnunciante,
	handleSubmitForm,
	handleSendNotification,
	eventDataSimple,
	companyLogos
}) => {
	useEffect(() => {
		setchangeName(false);
		setchangeAsunto(false);
		setchangeDe(false);
	}, []);

	const formikRef = useRef();
	const MySwal = withReactContent(Swal);

	const [notificationStep, setNotificationStep] = useState(
		notificationData && notificationData.fechaEnvio ? 3 : 0
	);

	const [changeName, setchangeName] = useState(false);
	const [changeAsunto, setchangeAsunto] = useState(false);
	const [changeCompany, setChangeCompany] = useState(false);
	const [changeDe, setchangeDe] = useState(false);

	const [tagList, setTagList] = useState([]);
	const [tagListModal, setTagListModal] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [companiesModal, setCompaniesModal] = useState([]);
	const [origins, setOrigins] = useState([]);
	const [originsModal, setOriginsModal] = useState([]);

	//destinatarios
	const [currentFilter, setCurrentFilter] = useState({});
	const [destinatariosList, setDestinatariosList] = useState([]);
	const [destinatariosTotal, setDestinatariosTotal] = useState(0);
	const [destinatariosTotalPage, setDestinatariosTotalPage] = useState(0);
	const [showDestinatariosModal, setShowDestinatariosModal] = useState(false);
	const [destinatariosModalList, setDestinatariosModalList] = useState([]);
	const [destinatariosModalTotalFilter, setDestinatariosModalTotalFilter] =
		useState(0);

	const [tempDestinatariosSelected, setTempDestinatariosSelected] = useState(
		[]
	);

	const [destinatariosGuardadosList, setDestinatarioGuardadosList] = useState(
		[]
	);
	const [noFilterDestinatList, setNoFilterDestinatList] = useState([]);

	const [allDestSelected, setAllDestSelected] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [isLoadingDestinat, setIsLoadingDestinat] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [actionsDisabled, setActionsDisabled] = useState(false);

	useEffect(() => {
		if (notificationStep === 0) {
			//home notificacion
		} else if (notificationStep === 1) {
			//preview
		} else if (notificationStep === 2) {
			//destinatarios

			//reset de listados
			setDestinatariosTotal(0);
			setDestinatariosList([]);
			setDestinatariosModalList([]);

			setDestinatarioGuardadosList([]);
			setNoFilterDestinatList([]);

			//api calls
			resetFilters();
			getDestinatarios(currentFilter, true, true);
		} else if (notificationStep === 3) {
			//dashboard
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationStep]);

	const newNotification = {
		id: 0,
		nombre: '',
		asunto: '',
		sendFromNombre: '',
		sendFromEmail: '',
		eventoId: eventDataSimple.id,
		companyLogo:'',
	};

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) {
					formikRef.current.resetForm();
					toggleModalBar();
				}
			});
		} else {
			toggleModalBar();
		}
	};

	const reloadNotification = async (startStep = false) => {
		setPageLoading(true);
		const data = await eventsApi.getNotification(notificationData.id);
		setNotificationData(data.data);
		startStep && setNotificationStep(0);
		setPageLoading(false);
	};

	const cancelEditMail = async () => {
		setPageLoading(true);
		const data = await eventsApi.cancelEditNotification(notificationData.id);
		if (data && data.succeeded) {
			setNotificationData(data.data);
			setNotificationStep(0);
		}
		setPageLoading(false);
	};

	//preview
	const templateIsReadyToSend = (values) => {
		if (
			values &&
			values.titulo &&
			values.firmaCargo &&
			values.firmaTextoLegal &&
			values.textoPrincipal
		) {
			return true;
		} else {
			return false;
		}
	};

	const notificationIsReadyToSend = (values) => {
		if (
			values &&
			values.asunto &&
			values.companyLogo &&
			values.sendFromNombre &&
			values.sendFromEmail &&
			values.titulo &&
			values.firmaCargo &&
			values.firmaTextoLegal &&
			values.textoPrincipal &&
			values.totalDestinatariosUpdate > 0
		) {
			return true;
		} else {
			return false;
		}
	};

	//destinatarios
	const destinatariosPortalRef = useRef(null);
	const [disableHandleAdd, setDisableHandleAdd] = useState(false);

	//filtros
	const [filterVip, setFilterVip] = useState(null);
	const [filterOrder, setFilterOrder] = useState(null);

	const [filterVipModal, setFilterVipModal] = useState(null);
	const [filterOrderModal, setFilterOrderModal] = useState(null);

	useEffect(() => {
		showDestinatariosModal && getDestinatariosModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDestinatariosModal]);

	const getDestinatarios = async (
		filters = currentFilter,
		table = false,
		firstLoad = false
	) => {
		table ? setPageLoading(true) : setIsLoadingDestinat(true);
		setActionsDisabled(true);

		const data = await eventsApi.getNotificationReceivers({
			...filters,
			notificacionId: parseInt(notificationData.id),
			pagina: 1,
		});

		if (data && data.succeeded) {
			setDestinatariosList(data.data.receiverList);
			setDestinatariosTotal(data.data.totalReceiverNotification);
			setDestinatariosTotalPage(data.data.totalReceiver);

			setNoFilterDestinatList(data.data.receiverListSinFiltrar);
			firstLoad &&
				setDestinatarioGuardadosList(data.data.receiverListSinFiltrar);

			setTagList(data.data.selectEtiquetas);
			setCompanies(data.data.selectEmpresas);
			setOrigins(data.data.selectProcedencias);
			setCurrentFilter({ ...filters, pagina: 1 });
		}
		setActionsDisabled(false);
		table ? setPageLoading(false) : setIsLoadingDestinat(false);
	};

	//---------------------------------

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreDestinatarios = async (newPage) => {
		if (
			currentFilter &&
			currentFilter.pagina &&
			newPage > currentFilter.pagina &&
			!isLoadingMore
		) {
			setIsLoadingMore(true);
			const data = await eventsApi.getNotificationReceivers({
				...currentFilter,
				pagina: newPage,
				notificacionId: parseInt(notificationData.id),
			});
			if (data && data.succeeded) {
				setDestinatariosList((prevList) => [
					...prevList,
					...data.data.receiverList,
				]);
				setCurrentFilter({ ...currentFilter, pagina: newPage });
				setIsLoadingMore(false);
			}
		}
	};

	const totalPages = Math.ceil((destinatariosTotalPage || 0) / 50);
	const currentPage = currentFilter?.pagina || 1;

	//---------------------------------

	const getDestinatariosModal = async (filters) => {
		setIsLoadingModal(true);
		const data = await eventsApi.getInvitedGuests({
			...filters,
			notificacionId: parseInt(notificationData.id),
		});
		if (data && data.succeeded) {
			setDestinatariosModalList(data.data.guestsList);
			setDestinatariosModalTotalFilter(data.data.totalGuests);
			setTagListModal(data.data.selectEtiquetas);
			setCompaniesModal(data.data.selectEmpresas);
			setOriginsModal(data.data.selectProcedencias);
		}

		setIsLoadingModal(false);
	};

	const toggleModalDestinatarios = () => {
		showDestinatariosModal && resetFilters();
		showDestinatariosModal && getDestinatarios(currentFilter, true);
		showDestinatariosModal && setTempDestinatariosSelected([]);
		setShowDestinatariosModal(!showDestinatariosModal);
	};

	const resetFilters = () => {
		setDisableHandleAdd(false);
	};

	const handleFilterDestinatarios = (values) => {
		getDestinatarios(values, false);
	};

	const handleFilterModalDestinatarios = (values) => {
		getDestinatariosModal(values);
	};

	const handleAddDestinatarios = async () => {
		setDisableHandleAdd(true);
		const updatedDest = _.unionBy(
			noFilterDestinatList,
			tempDestinatariosSelected,
			'eventoContactoId'
		);
		const arrayDest = updatedDest.map((inv) => inv.eventoContactoId);
		const data = await eventsApi.setNotificationReceivers({
			arrDestinatarios: arrayDest,
			eventoId: eventDataSimple.id,
			id: notificationData.id,
		});

		if (data && data.succeeded) {
			toggleModalDestinatarios();
		} else {
			notify.error('Ha ocurrido un error añadiendo los destinatarios.');
		}

		setDisableHandleAdd(false);
	};

	const restoreDestinatarios = async () => {
		setActionsDisabled(true);
		const arrayDest = destinatariosGuardadosList.map(
			(inv) => inv.eventoContactoId
		);
		const data = await eventsApi.setNotificationReceivers({
			arrDestinatarios: arrayDest,
			eventoId: eventDataSimple.id,
			id: notificationData.id,
		});

		if (data && data.succeeded) {
			setTempDestinatariosSelected([]);
			reloadNotification(true);
		} else {
			notify.error('Ha ocurrido un error cancelando los cambios.');
		}
		setActionsDisabled(false);
	};

	const handleDeleteDestinatario = (destID) => {
		MySwal.fire({
			title: '¿Quieres eliminar el destinatario?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				setActionsDisabled(true);

				const cleanShow = destinatariosList.filter(
					(item) => item.id !== destID
				);
				const cleanFilter = noFilterDestinatList.filter(
					(item) => item.id !== destID
				);
				const arrayDest = cleanShow.map((inv) => inv.eventoContactoId);

				const data = await eventsApi.setNotificationReceivers({
					arrDestinatarios: arrayDest,
					eventoId: eventDataSimple.id,
					id: notificationData.id,
				});

				if (data && data.succeeded) {
					setDestinatariosList(cleanShow);
					setNoFilterDestinatList(cleanFilter);
					setDestinatariosTotal(destinatariosTotal - 1);
				} else {
					notify.error('Ha ocurrido un error eliminando el destinatario.');
				}

				setActionsDisabled(false);
			}
		});
	};

	useEffect(() => {
		setAllDestSelected(
			destinatariosModalTotalFilter !== 0 &&
				destinatariosModalTotalFilter ===
					_.countBy(destinatariosModalList, (o) => o.isSelected === true).true
				? true
				: false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destinatariosModalList]);

	const handleSelectDestinatario = (index, dest, status) => {
		let arraySelecteds = tempDestinatariosSelected;
		status
			? arraySelecteds.push({
					...dest,
					eventoId: parseInt(notificationData.id),
			  })
			: _.remove(arraySelecteds, (item) => item.id === dest.id);
		setTempDestinatariosSelected(arraySelecteds);

		let newState = [...destinatariosModalList];
		newState[index].isSelected = status;
		setDestinatariosModalList(newState);
	};

	const handleSelectAllDestinatarios = (status) => {
		const updatedContacts = destinatariosModalList.map((inv) => ({
			...inv,
			isSelected: status,
			notificacionId: parseInt(notificationData.id),
		}));
		setDestinatariosModalList(updatedContacts);

		status
			? setTempDestinatariosSelected(
					_.unionBy(tempDestinatariosSelected, updatedContacts, 'id')
			  )
			: setTempDestinatariosSelected(
					tempDestinatariosSelected.filter(
						(ar) => !updatedContacts.find((rm) => rm.id === ar.id)
					)
			  );
	};

	const handleFileUpload = async (event, notifyId, eventId, attchds) => {
		setPageLoading(true);
		const file = event.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('notificacionId', notifyId);
			formData.append('eventoId', eventId);
			formData.append('file', file);
			formData.append('nombreArchivo', file.name);
			const data = await eventsApi.saveFileToAttachment(formData);
			if (data && data.succeeded) {
				let tmpAdjuntos = [...attchds, data.data];
				formikRef.current.setFieldValue('adjuntos', tmpAdjuntos);
				notify.success('El archivo adjunto ha sido añadido.');
			} else {
				notify.error(
					`Ha ocurrido un error al subir el archivo adjunto. ${data.message}`
				);
			}
		}
		formikRef.current.setFieldValue('tmpFileAttach', null);
		setPageLoading(false);
	};

	const handleDeleteAttach = async (atchId, attchds, eventoId) => {
		setPageLoading(true);
		const data = await eventsApi.deleteFileToAttachment({
			adjuntoId: atchId,
			eventoId: eventoId,
		});
		if (data && data.succeeded) {
			let tmpAdjuntos = attchds.filter((item) => item.id !== atchId);
			formikRef.current.setFieldValue('adjuntos', tmpAdjuntos);
			notify.success('El archivo adjunto ha sido eliminado.');
		} else {
			notify.error(
				`Ha ocurrido un error al subir el archivo adjunto. ${data.message}`
			);
		}
		setPageLoading(false);
	};

	return (
		<>
			<Formik
				innerRef={formikRef}
				enableReinitialize
				initialValues={
					notificationData == null ? newNotification : notificationData
				}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={(values, actions) => handleSubmitForm(values, actions)}
			>
				{({
					values,
					setFieldValue,
					isSubmitting,
					validateForm,
					submitForm,
				}) => {
					return (
						<FullLateralModal
							title={
								notificationData
									? notificationData.nombre
									: 'Nueva notificación'
							}
							isOpen={modalNuevaOpen}
							toggleModal={closeModalMenu}
							showBackButton
							nextButtonFn={() => {
								if (notificationStep === 0) {
									if (notificationIsReadyToSend(values)) {
										handleSendNotification(
											values.totalDestinatariosUpdate,
											values.id
										);
									} else {
										notify.error(
											'Debes revisar que todos los pasos estén completados.'
										);
									}
								} else if (notificationStep === 1) {
									//email
									submitForm().then(() => {
										reloadNotification(true);
									});
								} else if (notificationStep === 2) {
									setActionsDisabled(true);
									//destinatarios
									const arrayDest = noFilterDestinatList.map(
										(inv) => inv.eventoContactoId
									);
									formikRef.current.setFieldValue(
										'arrDestinatarios',
										arrayDest
									);
									submitForm().then(() => {
										reloadNotification(true);
										setActionsDisabled(false);
									});
								}
							}}
							hideCancel={notificationStep === 0 ? true : false}
							hiddenActions={notificationStep === 3 ? true : false}
							cancelButtonFn={() => {
								if (notificationStep === 1) {
									//email
									cancelEditMail();
								} else if (notificationStep === 2) {
									//destinatarios
									restoreDestinatarios();
								}
							}}
							isEditing
							isConfirming={notificationStep === 0 ? true : false}
							confirmLabel="Enviar notificación"
							disableConfirm={values?.id === 0 ? true : false}
							stepBackFn={() => {
								if (notificationStep > 0) {
									if (notificationData.fechaEnvio) {
										closeModalMenu();
									} else if (notificationStep === 1) {
										cancelEditMail();
									} else if (notificationStep === 2) {
										restoreDestinatarios();
									} else {
										setNotificationStep(0);
									}
								} else {
									closeModalMenu();
								}
							}}
							disabledButtons={isSubmitting ? true : actionsDisabled}
						>
							<>
								{pageLoading && <LoadingLayer visible={pageLoading} />}
								<Form autoComplete="off">
									<>
										{notificationStep === 0 ? (
											<ModalBody>
												<div className="nueva-invitacion">
													{!changeName ? (
														<Row className="mb-4 px-3 d-flex align-items-center">
															<Col md="11">
																{notificationData
																	? notificationData.nombre
																	: 'Notificación sin nombre'}
															</Col>
															<Col md="1" className="text-end">
																<Button
																	className="btn-prim-light btn-icon"
																	onClick={() => {
																		changeAsunto || changeDe || changeCompany
																			? setchangeName(false)
																			: setchangeName(true);
																	}}
																>
																	<i className="fticon-edit"></i>
																</Button>
															</Col>
														</Row>
													) : (
														<Row className="mb-3 pr-4">
															<Col md="10">
																<Field
																	name="nombre"
																	label="Nombre"
																	placeholder="Notificación sin nombre"
																	className="nueva-invitacion-nombre"
																	component={InputForm}
																/>
															</Col>
															<Col md="2" className="text-end">
																<Button
																	className="btn-prim-close btn-icon"
																	onClick={() => setchangeName(false)}
																>
																	<i className="fticon-close tick-close"></i>
																</Button>
																<Button
																	className="btn-prim-check btn-icon"
																	onClick={() => {
																		submitForm();
																		setchangeName(false);
																	}}
																>
																	<i className="fticon-tick tick-close"></i>
																</Button>
															</Col>
														</Row>
													)}

													<Row
														className={
															values.id === 0
																? 'deshabilitar-campos box-list'
																: 'box-list'
														}
													>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				notificationData &&
																				notificationData.companyLogo
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Empresa:</strong>
																	</Col>
																	<Col md="9">
																		{!changeCompany ? (
																			<Row>
																				<Col md="11">
																					{notificationData &&
																					notificationData.companyLogo
																						? values.companyLogo?.label
																						: '¿Qué empresa organiza el evento?'}
																				</Col>
																				<Col md="1">
																					<Button
																						className="btn-prim-light btn-icon"
																						onClick={() => {
																							changeName ||
																							changeDe ||
																							changeAsunto
																								? setChangeCompany(false)
																								: setChangeCompany(true);
																						}}
																					>
																						<i className="fticon-edit"></i>
																					</Button>
																				</Col>
																			</Row>
																		) : (
																			<Row>
																				<Col md="5">
																					<Field
																						label="Empresa"
																						placeholder="Empresa"
																						options={companyLogos || []}
																						name="companyLogo"
																						component={InputSelect}
																						findByValue
																						help={
																							'No hay empresas disponibles.'
																						}
																					/>
																				</Col>
																				
																				<Col md="7" className="text-end">
																					<Button
																						className="btn-prim-close btn-icon"
																						onClick={() =>
																							setChangeCompany(false)
																						}
																					>
																						<i className="fticon-close tick-close"></i>
																					</Button>
																					<Button
																						className="btn-prim-check btn-icon"
																						onClick={() => {
																							submitForm();
																							setChangeCompany(false);
																						}}
																					>
																						<i className="fticon-tick tick-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		)}
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				notificationData &&
																				notificationData.asunto
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Asunto:</strong>
																	</Col>
																	<Col md="9">
																		{!changeAsunto ? (
																			<Row>
																				<Col md="11">
																					{notificationData &&
																					notificationData.asunto
																						? values.asunto
																						: '¿Cuál es el asunto de la notificación?'}
																				</Col>
																				<Col md="1">
																					<Button
																						className="btn-prim-light btn-icon"
																						onClick={() => {
																							changeName || changeDe || changeCompany
																								? setchangeAsunto(false)
																								: setchangeAsunto(true);
																						}}
																					>
																						<i className="fticon-edit"></i>
																					</Button>
																				</Col>
																			</Row>
																		) : (
																			<Row>
																				<Col md="7">
																					<Field
																						name="asunto"
																						label="Asunto"
																						placeholder="¿Cuál es el asunto de la notificación?"
																						component={InputForm}
																					/>
																				</Col>
																				<Col md="3">
																					<button
																						type="button"
																						className="variable-nombre p-0"
																						onClick={() =>
																							setFieldValue(
																								'asunto',
																								values.asunto
																									? `${values.asunto} [name]`
																									: '[name]'
																							)
																						}
																					>
																						<span>
																							Insertar variable nombre
																						</span>
																					</button>
																				</Col>
																				<Col md="2" className="text-center">
																					<Button
																						className="btn-prim-close btn-icon"
																						onClick={() =>
																							setchangeAsunto(false)
																						}
																					>
																						<i className="fticon-close tick-close"></i>
																					</Button>
																					<Button
																						className="btn-prim-check btn-icon"
																						onClick={() => {
																							submitForm();
																							setchangeAsunto(false);
																						}}
																					>
																						<i className="fticon-tick tick-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		)}
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				notificationData &&
																				notificationData.sendFromNombre
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>De:</strong>
																	</Col>
																	<Col md="9">
																		{!changeDe ? (
																			<Row>
																				<Col md="11">
																					{notificationData &&
																					notificationData.sendFromNombre ? (
																						<>
																							{notificationData.sendFromNombre}
																							<span className="bullet-sep"></span>
																							{notificationData.sendFromEmail}
																						</>
																					) : (
																						'¿Quién envía la notificación?'
																					)}
																				</Col>
																				<Col md="1">
																					<Button
																						className="btn-prim-light btn-icon"
																						onClick={() => {
																							changeName || changeAsunto || changeCompany
																								? setchangeDe(false)
																								: setchangeDe(true);
																						}}
																					>
																						<i className="fticon-edit"></i>
																					</Button>
																				</Col>
																			</Row>
																		) : (
																			<Row>
																				<Col md="5">
																					<Field
																						type="text"
																						name="sendFromNombre"
																						label="Nombre"
																						placeholder="Nombre"
																						component={InputForm}
																					/>
																				</Col>
																				<Col md="5">
																					<Field
																						type="text"
																						name="sendFromEmail"
																						label="Email"
																						placeholder="Email"
																						component={InputForm}
																					/>
																				</Col>
																				<Col md="2" className="text-center">
																					<Button
																						className="btn-prim-close btn-icon"
																						onClick={() => setchangeDe(false)}
																					>
																						<i className="fticon-close tick-close"></i>
																					</Button>
																					<Button
																						className="btn-prim-check btn-icon"
																						onClick={() => {
																							submitForm();
																							setchangeDe(false);
																						}}
																					>
																						<i className="fticon-tick tick-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		)}
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				notificationData &&
																				templateIsReadyToSend(values)
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Email</strong>
																	</Col>
																	<Col md="6">Contenido del email</Col>
																	<Col md="3" className="text-end">
																		<Button
																			className="btn-prim-light"
																			type="button"
																			onClick={() => setNotificationStep(1)}
																		>
																			Editar email
																		</Button>
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
														<Col md="12" className="mb-3">
															<WhiteBox>
																<Row>
																	<Col md="1" className="text-center">
																		<i
																			className={
																				notificationData &&
																				notificationData.totalDestinatariosUpdate !==
																					0
																					? 'fticon-confirm check-invitacion cambio-check'
																					: 'fticon-confirm check-invitacion'
																			}
																		></i>
																	</Col>
																	<Col md="2">
																		<strong>Destinatarios</strong>
																	</Col>
																	<Col md="6">
																		{notificationData &&
																		notificationData.totalDestinatariosUpdate !==
																			0
																			? `${notificationData.totalDestinatariosUpdate} destinatarios seleccionados`
																			: '¿A quién se enviará la notificación?'}
																	</Col>
																	<Col md="3" className="text-end">
																		<Button
																			className="btn-prim-light"
																			onClick={() => setNotificationStep(2)}
																		>
																			Seleccionar destinarios
																		</Button>
																	</Col>
																</Row>
															</WhiteBox>
														</Col>
													</Row>
												</div>
											</ModalBody>
										) : notificationStep === 1 ? (
											<div className="preview-invitacion">
												<ModalBody>
													<Row>
														<Col md="3" className="bg-white">
															<Row className="preview-data-mail">
																<Col md="12">
																	<div className="tit">Título</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="titulo"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="4"
																	/>
																</Col>
																<Col md="12">
																	<div className="tit">Texto principal</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="textoPrincipal"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="4"
																	/>
																	<Button
																		className="variable-nombre"
																		onClick={() =>
																			setFieldValue(
																				'textoPrincipal',
																				values.textoPrincipal
																					? `${values.textoPrincipal} [name]`
																					: '[name]'
																			)
																		}
																	>
																		Insertar variable Nombre
																	</Button>
																</Col>
																<Col md="12">
																	<div className="tit">Texto secundario</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="textoAdicional"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="4"
																	/>
																	<Button
																		className="variable-nombre"
																		onClick={() =>
																			setFieldValue(
																				'textoAdicional',
																				values.textoAdicional
																					? `${values.textoAdicional} [num_guests]`
																					: '[num_guests]'
																			)
																		}
																	>
																		Insertar variable Núm. personas
																	</Button>
																</Col>
																<Col md="12">
																	<div className="tit">Imagen</div>
																</Col>
																<Col md="12">
																	<Field
																		name="documento"
																		component={InputImage}
																		accept={IMAGE_TYPES_ALLOWED}
																		image={
																			values.base64
																				? values.base64
																				: values.urlImagen
																				? values.urlImagen
																				: null
																		}
																		field_base64="base64"
																		field_filename={`filename`}
																	/>
																</Col>
																<Col md="12">
																	<div className="tit check mt-5">
																		<Field
																			component={InputForm}
																			baseLabel
																			reverse
																			type="switch"
																			label={'Incluir fecha y lugar del evento'}
																			name="incluirFechaLugarEvento"
																		/>
																	</div>
																</Col>
																<Col md="12">
																	<div className="tit">
																		Información adicional
																	</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="textoLimiteConfirm"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="4"
																	/>
																	<Button
																		className="variable-nombre"
																		onClick={() =>
																			setFieldValue(
																				'textoLimiteConfirm',
																				values.textoLimiteConfirm
																					? `${values.textoLimiteConfirm} [confirm_date]`
																					: '[confirm_date]'
																			)
																		}
																	>
																		Insertar variable fecha
																	</Button>
																</Col>
																<Col md="12">
																	<div className="tit">Firma</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Remitente"
																		placeholder="Remitente"
																		name="firmaCargo"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<Field
																		label="Teléfono"
																		placeholder="Teléfono"
																		name="firmaTelefono"
																		component={InputForm}
																	/>
																</Col>
																<Col md="12">
																	<div className="tit">Texto legal</div>
																</Col>
																<Col md="12">
																	<Field
																		label="Texto"
																		placeholder="Texto"
																		name="firmaTextoLegal"
																		component={InputForm}
																		type="textarea"
																		className="textarea"
																		rows="8"
																	/>
																</Col>
																<Col md="12">
																	<div className="Rectngulo-271"></div>
																</Col>
																<Col md="12">
																	<div className="tit">Archivos adjuntos</div>
																</Col>
																<Col md="12">
																	{values.adjuntos &&
																		values.adjuntos.map((atch, ixAtch) => (
																			<Row
																				key={ixAtch}
																				className="file-attached"
																			>
																				<Col md="10">
																					<div className="file-name">
																						{atch.nombreArchivo}
																					</div>
																				</Col>
																				<Col md="2" className="text-end">
																					<Button
																						className="btn btn-sec-link btn-icon"
																						onClick={() =>
																							handleDeleteAttach(
																								atch.id,
																								values.adjuntos,
																								values.eventoId
																							)
																						}
																					>
																						<i className="fticon-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		))}
																</Col>
																<Col md="12" className="my-4">
																	<Field
																		name="tmpFileAttach"
																		component={InputUploadFile}
																		label={'Adjuntar nuevo archivo'}
																		onChangeFn={(e) =>
																			handleFileUpload(
																				e,
																				values.id,
																				values.eventoId,
																				values.adjuntos
																			)
																		}
																	/>
																</Col>
															</Row>
														</Col>
														<Col md="9">
															<NotificationMailPreview
																notificationEvent={eventDataSimple}
																notificationData={values}
															/>
														</Col>
													</Row>
												</ModalBody>
											</div>
										) : notificationStep === 2 ? (
											<div ref={destinatariosPortalRef}></div>
										) : (
											<div className="dashboard-sent-invitation">
												<NotificationSentDetails
													notificationData={notificationData}
													tipoAnunciante={tipoAnunciante}
													eventDataSimple={eventDataSimple}
												/>
											</div>
										)}
									</>
								</Form>

								{showDestinatariosModal && (
									<LateralModal
										size="xl"
										isOpen={showDestinatariosModal}
										toggleModal={toggleModalDestinatarios}
										title="Destinatarios"
										noCloseOutside
									>
										<ModalBody className="event-contacts-modal">
											<Row>
												<Col md="12">
													<ContactsFilter
														tagList={tagListModal}
														companies={companiesModal}
														submitFilter={handleFilterModalDestinatarios}
														fromOrigin={originsModal}
														vipFilter={filterVipModal}
														orderFilter={filterOrderModal}
														disabled={isLoadingModal}
													/>
												</Col>
											</Row>

											<Row className="table-header pb-3">
												<Col md="3" className="col-star">
													<TableVipFilter
														filterVip={filterVipModal}
														setFilterVip={setFilterVipModal}
													/>
													<TableRowOrder
														selectedOp={filterOrderModal}
														selectFn={setFilterOrderModal}
													/>
												</Col>
												<Col md="3">Empresa</Col>
												<Col md="5">Email</Col>
												<Col md="1">
													<div className="margin-check">
														<CheckboxNF
															value={allDestSelected}
															count={tempDestinatariosSelected.length}
															onChange={(e) =>
																handleSelectAllDestinatarios(e.target.checked)
															}
														/>
													</div>
												</Col>
											</Row>
											<Row className="contact-list">
												<Col md="12">
													{isLoadingModal ? (
														<TableLoader />
													) : (
														<div
															className={
																destinatariosModalList.length > 5
																	? 'scrollable-contacts'
																	: 'scrollable-contacts compact'
															}
														>
															{destinatariosModalList &&
															destinatariosModalList.length > 0 ? (
																destinatariosModalList.map(
																	(destinat, index) => {
																		const inviData = destinat.contactoAnunciante
																			? destinat.contactoAnunciante
																			: destinat.contacto;
																		const fullName = `${inviData.nombre} ${
																			inviData.apellidos
																				? inviData.apellidos
																				: ''
																		}`;
																		return (
																			<Row
																				key={index}
																				className="table-row w-proc"
																			>
																				<Col md="3" className="col-star">
																					<div className="vip">
																						{inviData.esVIP && (
																							<i className="fticon-star-solid"></i>
																						)}
																					</div>
																					<div className="name">
																						{fullName}
																						{inviData.procedencia && (
																							<div className="sub-proc">
																								{inviData.procedencia.label}
																							</div>
																						)}
																					</div>
																				</Col>

																				<Col md="3">
																					{inviData.empresa &&
																						inviData.empresa.label}
																				</Col>
																				<Col md="5">
																					<div className="mail-list">
																						{inviData.emails &&
																							inviData.emails.map(
																								(mail, ix) =>
																									`${ix !== 0 ? ', ' : ''}${
																										mail.mail
																									}`
																							)}
																					</div>
																				</Col>
																				<Col md="1" className="text-center">
																					<CheckboxNF
																						value={
																							_.find(
																								tempDestinatariosSelected,
																								{
																									id: destinat.id,
																								}
																							)
																								? true
																								: false
																						}
																						onChange={(e) =>
																							handleSelectDestinatario(
																								index,
																								destinat,
																								e.target.checked
																							)
																						}
																					/>
																				</Col>
																			</Row>
																		);
																	}
																)
															) : (
																<Col md="12" className="text-center py-5">
																	Sin resultados
																</Col>
															)}
														</div>
													)}
												</Col>
											</Row>
										</ModalBody>
										<ModalFooter>
											<Button
												className="btn-sec-dark"
												onClick={handleAddDestinatarios}
												disabled={
													isLoadingModal
														? true
														: tempDestinatariosSelected.length > 0
														? disableHandleAdd
														: true
												}
											>
												Añadir destinatarios
											</Button>
										</ModalFooter>
									</LateralModal>
								)}
							</>
						</FullLateralModal>
					);
				}}
			</Formik>
			<Portal container={destinatariosPortalRef.current}>
				<div className="destinatarios-invitacion">
					<ModalBody>
						<div className="container">
							<Row>
								<Col md="12">
									<div className="invitados-tit">
										<h3 className="mb-0">
											Destinatarios
											<span className="badge-count">
												<span>{destinatariosTotal}</span>
											</span>
										</h3>
										{destinatariosTotal > 0 && (
											<Button
												className="btn-prim-light mt-1"
												onClick={toggleModalDestinatarios}
											>
												Añadir destinatarios
											</Button>
										)}
									</div>
									{destinatariosTotal > 0 ? (
										<WhiteBox containerClass="full-box invitados-list">
											<Row>
												<Col md="12">
													<ContactsFilter
														tagList={tagList}
														companies={companies}
														submitFilter={handleFilterDestinatarios}
														fromOrigin={origins}
														vipFilter={filterVip}
														orderFilter={filterOrder}
														disabled={isLoadingDestinat}
													/>
												</Col>
											</Row>
											<Row className="table-header pb-3">
												<Col md="4" className="col-star">
													<TableVipFilter
														filterVip={filterVip}
														setFilterVip={setFilterVip}
													/>
													<TableRowOrder
														selectedOp={filterOrder}
														selectFn={setFilterOrder}
													/>
												</Col>
												<Col md="3">Empresa</Col>
												<Col md="5">Email</Col>
											</Row>
											<Row>
												<Col md="12">
													<div className="contact-list">
														{isLoadingDestinat ? (
															<TableLoader />
														) : destinatariosList &&
														  destinatariosList.length > 0 ? (
															<div className="scrollable-contacts">
																<InfiniteScroll
																	pageStart={0}
																	loadMore={() =>
																		loadMoreDestinatarios(currentPage + 1)
																	}
																	hasMore={currentPage < totalPages}
																	useWindow={false}
																	loader={
																		<div key={0} style={{ clear: 'both' }}>
																			<TableLoader size="xs" />
																		</div>
																	}
																>
																	{destinatariosList.map((destinat, index) => {
																		const baseContacto = destinat.contacto
																			? destinat.contacto
																			: destinat;
																		const inviData =
																			baseContacto.contactoAnunciante
																				? baseContacto.contactoAnunciante
																				: baseContacto.contacto;

																		const fullName = `${inviData.nombre} ${
																			inviData.apellidos
																				? inviData.apellidos
																				: ''
																		}`;

																		return (
																			<Row key={index} className="table-row">
																				<Col md="4" className="col-star">
																					<div className="vip">
																						{baseContacto.esVIP && (
																							<i className="fticon-star-solid"></i>
																						)}
																					</div>
																					<div className="name">
																						{fullName}
																						{baseContacto.procedencia && (
																							<div className="sub-proc">
																								{baseContacto.procedencia.label}
																							</div>
																						)}
																					</div>
																				</Col>

																				<Col md="3">
																					{inviData.empresa &&
																						inviData.empresa.label}
																				</Col>
																				<Col md="4">
																					<div className="mail-list">
																						{inviData.emails &&
																							inviData.emails.map(
																								(mail, ix) =>
																									`${ix !== 0 ? ', ' : ''}${
																										mail.mail
																									}`
																							)}
																					</div>
																				</Col>
																				<Col md="1" className="text-end">
																					<Button
																						className="btn-sec-link btn-icon"
																						onClick={() =>
																							handleDeleteDestinatario(
																								destinat.id
																							)
																						}
																					>
																						<i className="fticon-close"></i>
																					</Button>
																				</Col>
																			</Row>
																		);
																	})}
																</InfiniteScroll>
															</div>
														) : (
															<div className="no-results py-5">
																No se han encontrado resultados.
															</div>
														)}
													</div>
												</Col>
											</Row>
										</WhiteBox>
									) : (
										<WhiteBox containerClass="full-box empty">
											<div className="no-results">
												<h3>No hay destinatarios</h3>
												<p>
													Añade destinatarios a esta notificación de tu lista de
													invitados
												</p>
												<Button
													className="btn-prim-light mt-1"
													onClick={toggleModalDestinatarios}
												>
													Añadir destinatarios
												</Button>
											</div>
										</WhiteBox>
									)}
								</Col>
							</Row>
						</div>
					</ModalBody>
				</div>
			</Portal>
		</>
	);
};

export default EventDBnuevaNotificacion;
