import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';

//bs
import {
	Button,
	Col,
	Row,
	ModalBody,
	ModalFooter,
	Container,
	UncontrolledDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';

//comps
import WhiteBox from 'components/common/elems/WhiteBox';
import LoadingLayer from 'components/loaders/LoadingLayer';
import ContactsFilter from 'pages/contacts/ContactsFilter';
import TableLoader from 'components/loaders/TableLoader';
import Medal from 'components/common/elems/Medal';
import Sticker from 'components/common/elems/Sticker';
import CheckboxNF from 'components/common/forms/CheckboxNF';
import LateralModal from 'components/common/modals/LateralModal';
import InputUploadBtn from 'components/common/forms/InputUploadBtn';
import ContactsForm from 'pages/contacts/ContactsForm';
import InvitadoBulkUpdate from './modals/InvitadoBulkUpdate';
import InvitadoDetailsForm from './modals/InvitadoDetailsForm';
import InvitadosFilter from './tables/InvitadosFilter';
import TableRowFilter from 'components/common/elems/tables/TableRowFilter';
import TableVipFilter from 'components/common/elems/tables/TableVipFilter';
import TableRowOrder from 'components/common/elems/tables/TableRowOrder';
import InvitadosHeader from './tables/InvitadosHeader';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroller';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//helpers
import {
	defInvitadosPhoto,
	defInvitadosSitting,
	defInvitadosAsist,
	parseDateTimeFromString,
	noSpaceString,
} from 'utils/helpers';

//services
import * as eventsApi from 'api/eventsApi';
import * as contactsApi from 'api/contactsApi';

//download
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const EventDBInvitados = ({ eventID, eventDataSimple }) => {
	const MySwal = withReactContent(Swal);
	const [pageLoading, setPageLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);

	const [invitadosData, setInvitadosData] = useState([]);
	const [invitadosTotal, setInvitadosTotal] = useState(0);
	const [invitadosHeadInfo, setInvitadosHeadInfo] = useState([]);
	const [currentFilter, setCurrentFilter] = useState({});
	const [totalSelectables, setTotalSelectables] = useState(0);

	const [tempInvitadosSelectedList, setTempInvitadosSelectedList] = useState(
		[]
	);
	const [invitadosTotalFilter, setInvitadosTotalFilter] = useState(0);

	const [tagListAll, setTagListAll] = useState([]);
	const [tagList, setTagList] = useState([]);
	const [tagListModal, setTagListModal] = useState([]);
	const [companiesAll, setCompaniesAll] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [companiesModal, setCompaniesModal] = useState([]);
	const [originsAll, setOriginsAll] = useState([]);
	const [origins, setOrigins] = useState([]);
	const [tipoAnunciante, setTipoAnunciante] = useState(false);

	//selección invitados
	const [showModalInvitados, setShowModalInvitados] = useState(false);
	const [addInvitadosModal, setAddInvitadosModal] = useState([]);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [allSelectedModal, setAllSelectedModal] = useState(false);
	const [tempInvitadosSelectedModal, setTempInvitadosSelectedModal] = useState(
		[]
	);
	const [modalTotalFilter, setModalTotalFilter] = useState(0);

	//invitados anunciantes
	const hiddenFileInput = useRef(null);
	const [uploadExcelId, setUploadExcelId] = useState(null);

	//detalles
	const [showInvitadoDetails, setShowInvitadoDetails] = useState(false);
	const [invitadoDetails, setInvitadoDetails] = useState(null);

	//nuevo contacto
	const [showNewContactModal, setShowNewContactModal] = useState(false);

	//actualización masiva
	const [showBulkModal, setShowBulkModal] = useState(false);

	//common
	useEffect(() => {
		reloadInvitados();
		getTags();
		getCompanies();
		getAllOrigins();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const reloadInvitados = () => {
		getInvitados();
	};

	const getTags = async () => {
		const data = await contactsApi.getTags();
		setTagListAll(data.data);
	};

	const getCompanies = async () => {
		const data = await contactsApi.getCompanies();
		setCompaniesAll(data.data);
	};

	const getAllOrigins = async () => {
		const data = await eventsApi.getOrigins();
		setOriginsAll(data.data);
	};

	// listado de invitados
	const [filterVip, setFilterVip] = useState(null);
	const [filterPhotocall, setFilterPhotocall] = useState(null);
	const [filterSitting, setFilterSitting] = useState(null);
	const [filterAsistentes, setFilterAsistentes] = useState(null);
	const [filterOrder, setFilterOrder] = useState(null);
	const [filterStatus, setFilterStatus] = useState(null);
	const [filterOrderDate, setFilterOrderDate] = useState(null);

	const getInvitados = async (filters = currentFilter, table = false) => {
		table ? setTableLoading(true) : setPageLoading(true);

		const data = await eventsApi.getGuests({
			...filters,
			eventoId: parseInt(eventID),
			pagina: 1,
		});
		if (data && data.succeeded) {
			setInvitadosHeadInfo(data.data.dataGuest);
			setTipoAnunciante(data.data.esAnunciante);

			setInvitadosTotal(data.data.totalGuestEvent);
			setInvitadosTotalFilter(data.data.totalGuests);
			//TBD
			setTotalSelectables(data.data.totalGuests);

			setTagList(data.data.selectEtiquetas);
			setCompanies(data.data.selectEmpresas);
			setOrigins(data.data.selectProcedencias);
			setInvitadosData(data.data.guestsList);
			setCurrentFilter({ ...filters, pagina: 1 });
		}

		table ? setTableLoading(false) : setPageLoading(false);
	};

	//---------------------------------

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreGuests = async (newPage) => {
		if (
			currentFilter &&
			currentFilter.pagina &&
			newPage > currentFilter.pagina &&
			!isLoadingMore
		) {
			setIsLoadingMore(true);
			const data = await eventsApi.getGuests({
				...currentFilter,
				pagina: newPage,
				eventoId: parseInt(eventID),
			});
			if (data && data.succeeded) {
				setInvitadosHeadInfo(data.data.dataGuest);
				setTipoAnunciante(data.data.esAnunciante);

				setInvitadosTotal(data.data.totalGuestEvent);
				setInvitadosTotalFilter(data.data.totalGuests);

				//TBD
				setTotalSelectables(data.data.totalGuests);

				setTagList(data.data.selectEtiquetas);
				setCompanies(data.data.selectEmpresas);
				setOrigins(data.data.selectProcedencias);
				setInvitadosData((prevList) => [...prevList, ...data.data.guestsList]);
				setCurrentFilter({ ...currentFilter, pagina: newPage });
				setIsLoadingMore(false);
			}
		}
	};

	const totalPages = Math.ceil((invitadosTotalFilter || 0) / 50);
	const currentPage = currentFilter?.pagina || 1;

	//---------------------------------

	const handleSubmitFilter = (values) => {
		getInvitados(values, true);
	};

	// useEffect(() => {
	// 	showBulkModal
	// 		? tempInvitadosSelectedList.length < 2 && setShowBulkModal(false)
	// 		: tempInvitadosSelectedList.length > 1 && setShowBulkModal(true);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [tempInvitadosSelectedList]);

	// const selectContactList = (index, contact, state) => {
	// 	let arraySelecteds = tempInvitadosSelectedList;
	// 	state
	// 		? arraySelecteds.push(contact)
	// 		: _.remove(arraySelecteds, (item) => item.id === contact.id);
	// 	setTempInvitadosSelectedList(arraySelecteds);

	// 	let newState = [...invitadosData];
	// 	newState[index].isSelected = state;
	// 	setInvitadosData(newState);

	// 	showBulkModal
	// 		? arraySelecteds.length < 2 && setShowBulkModal(false)
	// 		: arraySelecteds.length > 1 && setShowBulkModal(true);
	// };

	//-----------------------------------------------

	const [allSelectedStatus, setAllSelectedStatus] = useState(false);
	//const [selectionCount, setSelectionCount] = useState(0);
	const [loadingChecks, setLoadingChecks] = useState(false);

	useEffect(() => {
		setAllSelectedStatus(
			tempInvitadosSelectedList.length === totalSelectables &&
				totalSelectables > 0
		);
		//setSelectionCount(tempInvitadosSelectedList.length);

		showBulkModal
			? tempInvitadosSelectedList.length < 2 && setShowBulkModal(false)
			: tempInvitadosSelectedList.length > 1 && setShowBulkModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempInvitadosSelectedList, invitadosTotal]);

	const handleSelectRow = (guestId) => {
		const updatedArray = tempInvitadosSelectedList
			? tempInvitadosSelectedList.includes(guestId)
				? tempInvitadosSelectedList.filter((item) => item !== guestId)
				: [...tempInvitadosSelectedList, guestId]
			: [guestId];
		setTempInvitadosSelectedList(updatedArray);
	};

	const handleSelectAll = async (newState) => {
		if (newState) {
			setLoadingChecks(true);
			const data = await eventsApi.getGuestsOnlyId({
				...currentFilter,
				eventoId: parseInt(eventID),
			});
			if (data && data.succeeded) {
				setTempInvitadosSelectedList(data.data);
			} else {
				notify.error('Ups, ha ocurrido un error.', data.message);
			}
			setLoadingChecks(false);
		} else {
			setTempInvitadosSelectedList([]);
		}
	};

	//-----------------------------------------------

	const handleStatusFilter = (newState) => {
		setFilterStatus(newState);
	};

	//--------------- invitados

	useEffect(() => {
		if (showModalInvitados)
			tipoAnunciante ? getExcelContacts() : getNoInvitados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalInvitados]);

	useEffect(() => {
		setAllSelectedModal(
			modalTotalFilter !== 0 &&
				modalTotalFilter ===
					_.countBy(addInvitadosModal, (o) => o.isSelected === true).true
				? true
				: false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addInvitadosModal]);

	const getNoInvitados = async (filters) => {
		setIsLoadingModal(true);

		const data = await eventsApi.getUninvitedContacts({
			...filters,
			eventoId: parseInt(eventID),
		});
		if (data && data.succeeded) {
			setAddInvitadosModal(data.data.contactsList);
			setModalTotalFilter(data.data.totalContacts);
			setTagListModal(
				data.data.selectEtiquetas ? data.data.selectEtiquetas : []
			);
			setCompaniesModal(
				data.data.selectEmpresas ? data.data.selectEmpresas : []
			);
		}
		setIsLoadingModal(false);
	};

	const toggleModalInvitados = () => {
		setTempInvitadosSelectedModal([]);
		setAllSelectedModal(false);
		setModalTotalFilter(0);
		setShowModalInvitados(!showModalInvitados);
	};

	const handleSubmitFilterModal = (values) => {
		tipoAnunciante ? getExcelContacts(values) : getNoInvitados(values);
	};

	const selectAllContactsModal = async (status) => {
		const updatedContacts = addInvitadosModal.map((inv) => ({
			...inv,
			isSelected: status,
			eventoId: parseInt(eventID),
		}));
		setAddInvitadosModal(updatedContacts);

		status
			? setTempInvitadosSelectedModal(
					_.unionBy(tempInvitadosSelectedModal, updatedContacts, 'id')
			  )
			: setTempInvitadosSelectedModal(
					tempInvitadosSelectedModal.filter(
						(ar) => !updatedContacts.find((rm) => rm.id === ar.id)
					)
			  );
	};

	const selectContactModal = (index, contact, state) => {
		let arraySelecteds = tempInvitadosSelectedModal;
		state
			? arraySelecteds.push({ ...contact, eventoId: parseInt(eventID) })
			: _.remove(arraySelecteds, (item) => item.id === contact.id);
		setTempInvitadosSelectedModal(arraySelecteds);

		let newState = [...addInvitadosModal];
		newState[index].isSelected = state;
		setAddInvitadosModal(newState);
	};

	const handleAddInvitados = async () => {
		if (tempInvitadosSelectedModal.length > 0) {
			setPageLoading(true);
			const arrayIdInvitados = tempInvitadosSelectedModal.map((inv) =>
				tipoAnunciante
					? { contactoAnuncianteId: inv.id, eventoId: parseInt(eventID) }
					: { contactoId: inv.id, eventoId: parseInt(eventID) }
			);
			const data = await eventsApi.addGuestsFromContacts(arrayIdInvitados);
			if (data && data.succeeded) {
				reloadInvitados();
				notify.success('Los contactos se han añadido correctamente.');
			} else {
				notify.error(
					`Ha ocurrido un error añadiendo los contactos. ${data.message}`
				);
			}
			setPageLoading(false);
			toggleModalInvitados();
		} else {
			const MySwal = withReactContent(Swal);
			MySwal.fire({
				title: 'No has seleccionado ningún invitado',
				text: '¿Quieres continuar?',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModalInvitados();
			});
		}
	};

	//----------- nuevo contacto
	const toggleNewContactModal = () => {
		setShowNewContactModal(!showNewContactModal);
	};

	const handleCreateNewContact = async (values, formProps) => {
		const formEmpresa = values.empresa
			? values.empresa.__isNew__
				? _.omit(values.empresa, ['value'])
				: values.empresa
			: null;

		const formEtiquetas = values.etiquetasContactos
			? values.etiquetasContactos.map((item) =>
					item.__isNew__ ? _.omit(item, ['value']) : item
			  )
			: null;

		const updatedValues = {
			...values,
			empresa: formEmpresa,
			etiquetas: formEtiquetas,
			etiquetasContactos: [],
			eventoId: parseInt(eventID),
		};

		const data = await eventsApi.addGuestAdvertiser(updatedValues);
		if (data && data.succeeded) {
			if (values.reload) {
				formProps.resetForm({ values: newContactModel });
			} else {
				setShowNewContactModal(false);
			}

			reloadInvitados();
			notify.success('El contacto se ha creado correctamente.');
		} else {
			notify.error(`Ha ocurrido un error creando el contacto. ${data.message}`);
		}
	};

	const newContactModel = {
		nombre: '',
		esPersona: true,
		emails: [{ mail: '' }],
		etiquetas: [],
		etiquetasContactos: [],
		empresa: null,
		deletedMails: [],
	};

	//detalles de invitado
	const showInvitado = (invDetails) => {
		setInvitadoDetails(invDetails);
		toggleInvitadoDetails();
	};

	const toggleInvitadoDetails = () => {
		setShowInvitadoDetails(!showInvitadoDetails);
	};

	const handleUpdateInvitado = async (values) => {
		setPageLoading(true);
		const formProcedencia = values.procedencia
			? values.procedencia.__isNew__
				? _.omit(values.procedencia, ['value'])
				: values.procedencia
			: null;

		const data = await eventsApi.updateGuests([
			{
				...values,
				procedencia: formProcedencia,
				asistentesConSitting: parseInt(values.asistentesConSitting) || 0,
				asistentesSinSitting: parseInt(values.asistentesSinSitting) || 0,
			},
		]);
		setPageLoading(false);
		if (data && data.succeeded) {
			toggleInvitadoDetails();
			reloadInvitados();
			notify.success('El invitado se ha actualizado correctamente.');
		} else {
			notify.error(
				`Ha ocurrido un error actualizando el invitado. ${data.message}`
			);
		}
	};

	const handleDeleteInvitado = (invitadoID) => {
		MySwal.fire({
			title: '¿Quieres eliminar el invitado?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.deleteInvitado(invitadoID);
				if (data && data.succeeded) {
					toggleInvitadoDetails();
					reloadInvitados();
					notify.success('El invitado se ha eliminado correctamente.');
				} else {
					notify.error(
						`Ha ocurrido un error eliminando el invitado. ${data.message}`
					);
				}
			}
		});
	};

	//actualización masiva
	const toggleBulkModal = () => {
		setShowBulkModal(!showBulkModal);
	};

	const handleBulkUpdate = async (guestData) => {
		setPageLoading(true);
		const data = await eventsApi.updateBulkGuests({
			...guestData,
			eventoId: parseInt(eventID),
		});
		if (data && data.succeeded) {
			toggleBulkModal();
			setTempInvitadosSelectedList([]);
			reloadInvitados();
			notify.success('Los invitados se ha actualizado correctamente.');
		} else {
			notify.error(
				`Ha ocurrido un error actualizando los invitados. ${data.message}`
			);
		}
		setPageLoading(false);
	};

	const handleBulkDelete = async (guestData) => {
		setPageLoading(true);
		const data = await eventsApi.deleteBulkGuests({
			...guestData,
			eventoId: parseInt(eventID),
		});

		if (data && data.succeeded && data.message) {
			MySwal.fire({
				title: `Los siguientes invitados no se han eliminado porque tienen invitaciones enviadas`,
				text: ` ${data.message} `,
				cancelButtonText: 'Cancelar',
				showCancelButton: false,
				confirmButtonText: 'Aceptar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then(async (result) => {
				if (result && result.isConfirmed) {
					toggleBulkModal();
					setTempInvitadosSelectedList([]);
					reloadInvitados();

					notify.success(
						`Los invitados seleccionados se han eliminado correctamente.`
					);
				}
			});
		} else {
			if (data && data.succeeded) {
				toggleBulkModal();
				setTempInvitadosSelectedList([]);
				reloadInvitados();

				notify.success(
					`Los invitados seleccionados se han eliminado correctamente.`
				);
			} else {
				notify.error(
					`Ha ocurrido un error eliminando los invitados. ${data.message}`
				);
			}
		}
		setPageLoading(false);
	};

	//excel anunciantes
	useEffect(() => {
		uploadExcelId && toggleModalInvitados();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadExcelId]);

	const getExcelContacts = async (filters) => {
		setIsLoadingModal(true);
		let filtersId = { ...filters, archivoId: uploadExcelId };
		const data = await eventsApi.getContactsAdversiter(filtersId);
		if (data && data.succeeded) {
			setAddInvitadosModal(data.data.contactsList);
			setModalTotalFilter(data.data.totalContacts);
			setTagListModal(
				data.data.selectEtiquetas ? data.data.selectEtiquetas : []
			);
			setCompaniesModal(
				data.data.selectEmpresas ? data.data.selectEmpresas : []
			);
		}
		setIsLoadingModal(false);
	};

	const handleUploadExcel = async (event) => {
		event.preventDefault();
		setPageLoading(true);
		setUploadExcelId(null);

		const eventAux = event.target.files[0];

		if (eventAux) {
			const formData = new FormData();
			formData.append('file', eventAux);
			formData.append('fileName', eventAux.name);
			const data = await eventsApi.uploadContacts(formData);
			if (data && data.succeeded && data.message) {
				if (data.data.totalContactos > 0) {
					MySwal.fire({
						title: `Los siguientes contactos no se han cargado porque están dados de baja`,
						text: ` ${data.message} `,
						cancelButtonText: 'Cancelar',
						showCancelButton: false,
						confirmButtonText: 'Aceptar',
						focusConfirm: false,
						focusCancel: false,
						reverseButtons: true,
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn-prim-dark btn',
							cancelButton: 'btn-prim-light btn',
						},
					}).then(async (result) => {
						if (result && result.isConfirmed) {
							notify.success(
								`Se han importado ${data.data.totalContactos} contactos.`
							);
							setUploadExcelId(data.data.archivoId);
						}
					});
				} else {
					MySwal.fire({
						title: `Todos los contactos del archivo están dados de baja.`,
						cancelButtonText: 'Cancelar',
						showCancelButton: false,
						confirmButtonText: 'Aceptar',
						focusConfirm: false,
						focusCancel: false,
						reverseButtons: true,
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn-prim-dark btn',
							cancelButton: 'btn-prim-light btn',
						},
					}).then(async (result) => {});
				}
			} else {
				if (data && data.succeeded) {
					if (data.data.totalContactos > 0) {
						notify.success(
							`Se han importado ${data.data.totalContactos} contactos.`
						);
						setUploadExcelId(data.data.archivoId);
					} else {
						notify.error('No se ha podido importar ningún contacto.');
					}
				} else {
					notify.error(
						`Ha ocurrido un error al guardar los contactos. ${data.message}`
					);
				}
			}
			hiddenFileInput.current.value = null;
		}
		setPageLoading(false);
	};

	const handleDownloadExcel = async () => {
		setPageLoading(true);
		try {
			const data = await eventsApi.getExcelTemplate();
			if (data && data.succeeded) {
				const link = document.createElement('a');
				link.href = data.data;
				link.setAttribute('download', 'fileName.xlsx');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				notify.error(
					`Ha ocurrido un error al descargar la plantilla. ${data.message}`
				);
			}
		} catch (ex) {
			notify.error(
				`Ha ocurrido un error al descargar la plantilla. ${ex.message}`
			);
		}
		setPageLoading(false);
	};

	const handleDownloadInvitatosEvento = async () => {
		setPageLoading(true);
		try {
			const url = `/eventos/downloadInvitadosEvento/${eventID}`;
			const fileName = `Invitados Evento ${eventDataSimple.nombreEvento}.xlsx`;
			await eventsApi.downloadExcelAuth(url, fileName);
		} catch (ex) {
			notify.error(
				`Ha ocurrido un error al descargar el listado de invitados. ${ex.message}`
			);
		}
		setPageLoading(false);
	};

	//download entradas
	const handleDownloadEntradas = async (invitacionID) => {
		setPageLoading(true);

		const data = await eventsApi.downloadEntradasInvitado({
			invitadoId: invitacionID,
			eventoId: parseInt(eventID),
		});

		if (data && data.succeeded) {
			const mainData = data.data;
			if (mainData.entradas.length > 1) {
				var zip = new JSZip();
				const remotePDFs = mainData.entradas.map(async (entrada) => {
					const responsePDF = await fetch(entrada.fileUrl);
					const dataPDF = await responsePDF.blob();
					zip.file(entrada.fileName, dataPDF);
					return dataPDF;
				});

				Promise.all(remotePDFs).then(() => {
					zip.generateAsync({ type: 'blob' }).then((content) => {
						saveAs(content, mainData.zipName);
					});
					setPageLoading(false);
				});
			} else {
				const response = await fetch(mainData.entradas[0].fileUrl);
				const dataBlob = await response.blob();
				saveAs(dataBlob, mainData.entradas[0].fileName);
				setPageLoading(false);
			}
		} else {
			notify.error(
				`Ha ocurrido un error generando las entradas. ${data.message}`
			);
			setPageLoading(false);
		}
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			<div className="dashboard-page dashboard-invitados">
				<Row className="header-dash">
					<Col md="8">
						<h3>Invitados</h3>
					</Col>
					<Col md="4" className="text-end">
						{invitadosData && invitadosData.length > 0 && (
							<Button
								className="btn-prim-light me-3 mt-1"
								onClick={() => handleDownloadInvitatosEvento()}
							>
								Exportar a excel
							</Button>
						)}

						{tipoAnunciante ? (
							<div className="multi-button mt-1">
								<UncontrolledDropdown onClick={(e) => e.stopPropagation()}>
									<DropdownToggle color="prim-dark">
										<span>Gestionar invitados</span>
										<i className="fticon-arrow-down"></i>
									</DropdownToggle>
									<DropdownMenu end>
										<DropdownItem onClick={() => toggleNewContactModal()}>
											Nuevo invitado
										</DropdownItem>
										<DropdownItem
											onClick={() => hiddenFileInput.current.click()}
										>
											Importar datos desde Excel
										</DropdownItem>
										<DropdownItem onClick={() => handleDownloadExcel()}>
											Descargar plantilla Excel
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<input
									type="file"
									className="d-none"
									ref={hiddenFileInput}
									onChange={handleUploadExcel}
								/>
							</div>
						) : (
							<Button
								className="btn-prim-dark mt-1"
								onClick={toggleModalInvitados}
							>
								Añadir invitados
							</Button>
						)}
					</Col>
				</Row>
				<Container fluid className="main-container bg-gray">
					<Row className="header-asist mb-4">
						<Col md="12">
							<InvitadosHeader
								categorias={invitadosHeadInfo}
								filterStatus={filterStatus}
								handleStatusFilter={handleStatusFilter}
							/>
						</Col>
					</Row>

					<Row>
						<Col lg="12">
							{invitadosTotal > 0 ? (
								<WhiteBox
									containerClass={`full-box invitados-list ${
										showBulkModal ? 'md-btm-is-open' : ''
									}`}
								>
									<Row>
										<Col md="12">
											<InvitadosFilter
												tagList={tagList}
												companies={companies}
												fromOrigin={origins}
												submitFilter={handleSubmitFilter}
												vipFilter={filterVip}
												photoFilter={filterPhotocall}
												sittingFilter={filterSitting}
												asistFilter={filterAsistentes}
												orderFilter={filterOrder}
												orderDateFilter={filterOrderDate}
												statusFilter={filterStatus}
												eventoPasado={eventDataSimple.eventoPasado}
												disabled={tableLoading}
											/>
										</Col>
									</Row>

									<Row className="table-header mt-2 pb-3">
										<Col md="3">
											<div className="col-check">
												<div className="check">
													<CheckboxNF
														value={allSelectedStatus}
														disabled={loadingChecks || totalSelectables === 0}
														onChange={(e) => handleSelectAll(e.target.checked)}
													/>
												</div>
												<TableVipFilter
													filterVip={filterVip}
													setFilterVip={setFilterVip}
												/>
												<TableRowOrder
													selectedOp={filterOrder}
													selectFn={setFilterOrder}
													titleRow="Invitado"
												/>
											</div>
										</Col>
										<Col md="2" className="text-center">
											Etiqueta
										</Col>
										<Col md="1" className="text-center col-invitacion">
											Invitación
										</Col>
										<Col md="1" className="text-center col-photocall">
											<TableRowFilter
												titleRow="Photocall"
												options={defInvitadosPhoto}
												selectedOption={filterPhotocall}
												selectFn={setFilterPhotocall}
											/>
										</Col>
										<Col md="1" className="text-center col-sitting">
											<TableRowFilter
												titleRow="Sitting"
												options={defInvitadosSitting}
												selectedOption={filterSitting}
												selectFn={setFilterSitting}
												wParamReturn
											/>
										</Col>
										<Col md="2" className="col-asistentes">
											<TableRowFilter
												titleRow="Asistentes"
												options={defInvitadosAsist}
												selectedOption={filterAsistentes}
												selectFn={setFilterAsistentes}
												wParamReturn
											/>
										</Col>
										<Col md="2" className="col-confirmacion">
											<TableRowOrder
												selectedOp={filterOrderDate}
												selectFn={setFilterOrderDate}
												titleRow="Fecha de confirmación"
											/>
										</Col>
									</Row>

									<Row>
										<Col md="12">
											<div className="contact-list">
												{tableLoading ? (
													<TableLoader />
												) : invitadosData && invitadosData.length > 0 ? (
													<div className="scrollable-contacts">
														<InfiniteScroll
															pageStart={0}
															loadMore={() => loadMoreGuests(currentPage + 1)}
															hasMore={currentPage < totalPages}
															useWindow={false}
															loader={
																<div key={0} style={{ clear: 'both' }}>
																	<TableLoader size="xs" />
																</div>
															}
														>
															{invitadosData &&
																invitadosData.map((contact, index) => {
																	const contactData = contact.contactoAnunciante
																		? contact.contactoAnunciante
																		: contact.contacto;

																	const fullName =
																		contactData && contactData.esPersona
																			? `${contactData.nombre} ${
																					contactData.apellidos
																						? contactData.apellidos
																						: ''
																			  }`
																			: contactData && contactData.nombre;
																	return contactData ? (
																		<Row
																			key={index}
																			className={`table-row ${
																				contact.asistenciaDeclinada
																					? 'row-declined'
																					: ''
																			}`}
																			onClick={() =>
																				!showBulkModal && showInvitado(contact)
																			}
																		>
																			<Col md="3">
																				<div className="col-check">
																					<div className="check">
																						{!contact.esAnonimo && (
																							<CheckboxNF
																								// value={
																								// 	_.find(
																								// 		tempInvitadosSelectedList,
																								// 		{ id: contact.id }
																								// 	)
																								// 		? true
																								// 		: false
																								// }
																								value={tempInvitadosSelectedList.includes(
																									contact.id
																								)}
																								// onChange={(e) => {
																								// 	selectContactList(
																								// 		index,
																								// 		contact,
																								// 		e.target.checked
																								// 	);
																								// }}
																								onChange={(e) => {
																									handleSelectRow(contact.id);
																								}}
																								onClick={(e) =>
																									e.stopPropagation()
																								}
																							/>
																						)}
																					</div>
																					<div className="vip">
																						{contact.esVIP && (
																							<i className="fticon-star-solid"></i>
																						)}
																					</div>
																					<div className="name">
																						{fullName}
																						{contact.procedencia && (
																							<div className="sub-proc">
																								{contact.procedencia.label}
																							</div>
																						)}
																					</div>
																				</div>
																			</Col>
																			<Col md="2" className="text-center">
																				{contactData.etiquetasContactos &&
																					contactData.etiquetasContactos
																						.length > 0 &&
																					contactData.etiquetasContactos.map(
																						(etq, index) => (
																							<Sticker
																								key={index}
																								text={etq.etiqueta.label}
																								color={
																									etq.etiqueta.color.claseCss
																								}
																							/>
																						)
																					)}
																			</Col>

																			<Col md="1" className="col-center">
																				{contact.dataInvitacion &&
																					contact.dataInvitacion
																						.realInvitationSent && (
																						<>
																							<div
																								className="icon-table icon-mail me-2"
																								data-tip
																								data-for={`invitation-${contact.id}`}
																							>
																								<i className="fticon-mail"></i>
																							</div>
																							<ReactTooltip
																								id={`invitation-${contact.id}`}
																							>
																								<div className="tt-into tt-notopen">
																									<p className="tit">
																										No abierta
																									</p>
																									{contact.dataInvitacion
																										.tooltipEmail &&
																										contact.dataInvitacion.tooltipEmail.map(
																											(inviMail, index) => (
																												<p key={index}>
																													{inviMail.email}
																												</p>
																											)
																										)}
																								</div>
																							</ReactTooltip>
																						</>
																					)}
																			</Col>

																			<Col md="1" className="col-center">
																				{contact.photocall && (
																					<div className="icon-table icon-photocall me-4">
																						<i className="fticon-camera"></i>
																					</div>
																				)}
																			</Col>
																			<Col md="1" className="col-center">
																				<div className="col-sitting">
																					<div
																						className={`icon-table icon-sitting ${
																							contactData.esPersona
																								? !contact.sitting &&
																								  contact.asistentesConSitting ===
																										0
																									? 'disable'
																									: contact.asistentesConSitting >
																									  0
																									? 'w-seats'
																									: ''
																								: ''
																						} ${
																							contact.sitting &&
																							contact.asistentesConSitting !==
																								0 &&
																							contact.asistentesConSitting ===
																								contact.asistentesConSittingSentados
																								? 'sentados'
																								: ''
																						}`}
																					>
																						<i className="fticon-sitting"></i>
																						<span>
																							{contact.asistentesConSitting
																								? contact.asistentesConSitting
																								: '--'}
																						</span>
																					</div>
																					<div
																						className={`icon-table icon-sitting ${
																							contact.asistentesSinSitting &&
																							contact.asistentesSinSitting !==
																								0 &&
																							contact.asistentesSinSitting ===
																								contact.asistentesSinSittingSentados
																								? 'sentados'
																								: ''
																						}`}
																					>
																						<i className="fticon-no-sitting"></i>
																						<span>
																							{contact.asistentesSinSitting
																								? contact.asistentesSinSitting
																								: '--'}
																						</span>
																					</div>
																				</div>
																			</Col>
																			<Col md="2" className="col-opts">
																				<div className="izq">
																					{contact.asistentes !== 0 ? (
																						contact.dataInvitacion &&
																						contact.dataInvitacion
																							.observaciones ? (
																							<>
																								<div
																									className="icon-table icon-asistentes w-comments"
																									data-tip
																									data-for={`comments-${contact.id}`}
																								>
																									<i className="fticon-user"></i>
																									<span>
																										{contact.asistentes}
																									</span>
																								</div>

																								<ReactTooltip
																									id={`comments-${contact.id}`}
																								>
																									<span>
																										"
																										{
																											contact.dataInvitacion
																												.observaciones
																										}
																										"
																									</span>
																								</ReactTooltip>
																							</>
																						) : (
																							<div className="icon-table icon-asistentes">
																								<i className="fticon-user"></i>
																								<span>
																									{contact.asistentes}
																								</span>
																							</div>
																						)
																					) : (
																						<div className="spacer-asistentes"></div>
																					)}

																					{contact.dataEntrada && (
																						<>
																							<div
																								className={`icon-table icon-entrada st-${noSpaceString(
																									contact.dataEntrada
																										.estadoEntrada,
																									true
																								)}`}
																								data-tip
																								data-for={`entradatt-${contact.id}`}
																							>
																								<i className="fticon-ticket"></i>
																							</div>
																							<ReactTooltip
																								id={`entradatt-${contact.id}`}
																							>
																								<div
																									className={`tt-into st-${noSpaceString(
																										contact.dataEntrada
																											.estadoEntrada,
																										true
																									)}`}
																								>
																									<p className="tit">
																										{
																											contact.dataEntrada
																												.estadoEntrada
																										}
																									</p>

																									{contact.dataEntrada
																										.tooltipMessage && (
																										<p>
																											{
																												contact.dataEntrada
																													.tooltipMessage
																											}
																										</p>
																									)}

																									{contact.dataEntrada
																										.fechaCambioEntrada ? (
																										<p>
																											{parseDateTimeFromString(
																												contact.dataEntrada
																													.fechaCambioEntrada,
																												'dd/MM/yyyy - HH:mm'
																											)}
																										</p>
																									) : (
																										contact.dataEntrada
																											.fechaEnvioEntrada && (
																											<p>
																												{parseDateTimeFromString(
																													contact.dataEntrada
																														.fechaEnvioEntrada,
																													'dd/MM/yyyy - HH:mm'
																												)}
																											</p>
																										)
																									)}
																								</div>
																							</ReactTooltip>
																						</>
																					)}
																				</div>
																			</Col>
																			<Col md="2" className="col-opts">
																				<div className="izq">
																					{contact.dataInvitacion &&
																						contact.dataInvitacion
																							.fechaConfirmacionInvitacion &&
																						parseDateTimeFromString(
																							contact.dataInvitacion
																								.fechaConfirmacionInvitacion,
																							'dd/MM/yyyy - HH:mm'
																						)}
																				</div>
																				<div className="der">
																					<div className="icon-table icon-state">
																						{contact.asistenciaConfirmada && (
																							<>
																								<i
																									className="fticon-confirm state-confirm"
																									data-tip
																									data-for={`status-conf-${contact.id}`}
																								></i>
																								<ReactTooltip
																									id={`status-conf-${contact.id}`}
																								>
																									<div className="tt-into tt-confirm">
																										<p className="tit">
																											Confirmada
																										</p>
																										<p>
																											{
																												contact.dataInvitacion
																													.estadoConfirmacionInvitacion
																													?.email
																											}
																										</p>
																										<p>
																											{contact.dataInvitacion
																												.estadoConfirmacionInvitacion
																												? parseDateTimeFromString(
																														contact
																															.dataInvitacion
																															.fechaConfirmacionInvitacion,
																														'dd/MM/yyyy - HH:mm'
																												  )
																												: '-'}
																										</p>
																									</div>
																								</ReactTooltip>
																							</>
																						)}
																						{contact.asistenciaDeclinada && (
																							<>
																								<i
																									className="fticon-state-cancel state-cancel"
																									data-tip
																									data-for={`status-dec-${contact.id}`}
																								></i>
																								<ReactTooltip
																									id={`status-dec-${contact.id}`}
																								>
																									<div className="tt-into tt-cancel">
																										<p className="tit">
																											Rechazada
																										</p>
																										<p>
																											{
																												contact.dataInvitacion
																													.estadoConfirmacionInvitacion
																													?.email
																											}
																										</p>
																										<p>
																											{contact.dataInvitacion
																												.estadoConfirmacionInvitacion
																												? parseDateTimeFromString(
																														contact
																															.dataInvitacion
																															.fechaConfirmacionInvitacion,
																														'dd/MM/yyyy - HH:mm'
																												  )
																												: '-'}
																										</p>
																									</div>
																								</ReactTooltip>
																							</>
																						)}
																						{contact.asistenciaFueraDePlazo && (
																							<>
																								<i
																									className="fticon-confirm state-out"
																									data-tip
																									data-for={`status-out-${contact.id}`}
																								></i>
																								<ReactTooltip
																									id={`status-out-${contact.id}`}
																								>
																									<div className="tt-into tt-out">
																										<p className="tit">
																											Confirmada fuera de plazo
																										</p>
																										<p>
																											{
																												contact.dataInvitacion
																													.estadoConfirmacionInvitacion
																													?.email
																											}
																										</p>
																										<p>
																											{contact.dataInvitacion
																												.estadoConfirmacionInvitacion
																												? parseDateTimeFromString(
																														contact
																															.dataInvitacion
																															.fechaConfirmacionInvitacion,
																														'dd/MM/yyyy - HH:mm'
																												  )
																												: '-'}
																										</p>
																									</div>
																								</ReactTooltip>
																							</>
																						)}
																						{contact.asistenciaPendienteConfirmar && (
																							<i className="fticon-pending pendientes"></i>
																						)}
																					</div>
																				</div>
																			</Col>
																		</Row>
																	) : (
																		<div key={index}></div>
																	);
																})}
														</InfiniteScroll>
													</div>
												) : (
													<div className="no-results py-5">
														No se han encontrado resultados.
													</div>
												)}
											</div>
										</Col>
									</Row>
								</WhiteBox>
							) : (
								<WhiteBox containerClass="full-box empty">
									<div className="no-results">
										<h3>No hay invitados</h3>

										{!tipoAnunciante ? (
											<>
												<p>Añade invitados de tu lista de contactos</p>
												<Button
													className="btn-prim-light mt-1"
													onClick={toggleModalInvitados}
												>
													Añadir invitados
												</Button>
											</>
										) : (
											<>
												<p>Selecciona tu archivo de invitados</p>
												<InputUploadBtn
													noForm
													label={'Subir archivo de invitados'}
													onChangeFn={handleUploadExcel}
												/>
											</>
										)}
									</div>
								</WhiteBox>
							)}
						</Col>
					</Row>
				</Container>
			</div>

			{showModalInvitados && (
				<LateralModal
					size="xl"
					isOpen={showModalInvitados}
					toggleModal={toggleModalInvitados}
					title="Contactos"
					noCloseOutside
				>
					<ModalBody className="event-contacts-modal">
						<Row>
							<Col md="12">
								<ContactsFilter
									tagList={tagListModal}
									companies={companiesModal}
									submitFilter={handleSubmitFilterModal}
									disabled={isLoadingModal}
								/>
							</Col>
						</Row>

						<Row className="table-header pb-3">
							<Col md="4">
								<div className="spacer-medal"></div>
								<div>Invitado</div>
							</Col>
							<Col className="text-center" md="4">
								Etiqueta
							</Col>
							<Col md="3">Empresa</Col>
							<Col md="1">
								<CheckboxNF
									value={allSelectedModal}
									count={tempInvitadosSelectedModal.length}
									onChange={(e) => selectAllContactsModal(e.target.checked)}
								/>
							</Col>
						</Row>
						<Row className="contact-list">
							<Col md="12">
								{isLoadingModal ? (
									<TableLoader />
								) : (
									<div
										className={
											addInvitadosModal.length > 5
												? 'scrollable-contacts'
												: 'scrollable-contacts compact'
										}
									>
										{addInvitadosModal && addInvitadosModal.length > 0 ? (
											addInvitadosModal.map((contact, index) => {
												const fullName = contact.esPersona
													? `${contact.nombre} ${
															contact.apellidos ? contact.apellidos : ''
													  }`
													: contact.nombre;

												return (
													<Row key={index} className="table-row">
														<Col md="4" className="col-name">
															<Medal
																text={`${contact.nombre} ${
																	contact.apellidos ? contact.apellidos : ''
																}`}
																color={contact.color.claseCss}
															/>
															<div className="name">{fullName}</div>
														</Col>
														<Col className="text-center" md="4">
															{contact.etiquetasContactos &&
																contact.etiquetasContactos.length > 0 &&
																contact.etiquetasContactos.map((etq, index) => (
																	<Sticker
																		key={index}
																		text={etq.etiqueta.label}
																		color={etq.etiqueta.color.claseCss}
																	/>
																))}
														</Col>

														<Col md="3">
															{contact.empresa && contact.empresa.label}
														</Col>

														<Col md="1" className="text-center">
															<CheckboxNF
																value={
																	_.find(tempInvitadosSelectedModal, {
																		id: contact.id,
																	})
																		? true
																		: false
																}
																onChange={(e) =>
																	selectContactModal(
																		index,
																		contact,
																		e.target.checked
																	)
																}
															/>
														</Col>
													</Row>
												);
											})
										) : (
											<Col md="12" className="text-center py-5">
												Sin resultados
											</Col>
										)}
									</div>
								)}
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-sec-dark" onClick={handleAddInvitados}>
							Añadir invitados
						</Button>
					</ModalFooter>
				</LateralModal>
			)}

			{showNewContactModal && (
				<ContactsForm
					modalBarOpen={showNewContactModal}
					toggleModalBar={toggleNewContactModal}
					modalEdit={true}
					modalData={{}}
					defModelForm={newContactModel}
					handleSubmitForm={handleCreateNewContact}
					tagList={tagListAll}
					companies={companiesAll}
					singleContact
				/>
			)}

			{showInvitadoDetails && (
				<InvitadoDetailsForm
					modalBarOpen={showInvitadoDetails}
					toggleModal={toggleInvitadoDetails}
					invitadoDetails={invitadoDetails}
					procedencias={originsAll}
					tipoAnunciante={tipoAnunciante}
					deleteInvitadoFn={handleDeleteInvitado}
					saveInvitadoFn={handleUpdateInvitado}
					tagList={tagListAll}
					companies={companiesAll}
					updateContactFn={() => getInvitados(currentFilter, true)}
					downloadEntradasFn={handleDownloadEntradas}
				/>
			)}

			{showBulkModal && (
				<InvitadoBulkUpdate
					isOpen={showBulkModal}
					invitadosSelected={tempInvitadosSelectedList}
					closeModalFn={toggleBulkModal}
					procedencias={originsAll}
					tipoAnunciante={tipoAnunciante}
					saveModalFn={handleBulkUpdate}
					deleteModalFn={handleBulkDelete}
				/>
			)}
		</>
	);
};

export default EventDBInvitados;
