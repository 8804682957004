import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink, useRouteMatch  } from 'react-router-dom';

const VerticalBar = () => {

	let match = useRouteMatch('/evento/:idevent/:idsection?');

	return (
		<div className="col-sm-auto nav-vertical">
			<div className="nav-container flex-sm-column flex-row">
				<ul className="nav-icons nav nav-pills nav-flush flex-sm-column flex-row">
					<NavItem className='logo'>
						<NavLink to="/eventos" tag={RRNavLink}>
						<div className="logo-slim"></div>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/evento/${match.params.idevent}/dashboard`} tag={RRNavLink}>
							<i className="fticon-dashboard"></i>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/evento/${match.params.idevent}/invitados`} tag={RRNavLink}>
							<i className="fticon-user"></i>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/evento/${match.params.idevent}/invitaciones`} tag={RRNavLink}>
							<i className="fticon-mail-open"></i>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/evento/${match.params.idevent}/notificaciones`} tag={RRNavLink}>
							<i className="fticon-notification"></i>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/evento/${match.params.idevent}/planos`} tag={RRNavLink}>
							<i className="fticon-location"></i>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/evento/${match.params.idevent}/entradas`} tag={RRNavLink}>
							<i className="fticon-ticket"></i>
						</NavLink>
					</NavItem>
				</ul>
			</div>
		</div>
	);
};

export default VerticalBar;
