import React from 'react';
import ContentLoader from 'react-content-loader';

//Size xl = 3 lineas xs = 1 linea

const TableLoader = ({ size = 'xl', props }) => (
	<ContentLoader
		viewBox={size === 'xl' ? '0 0 900 150' : '0 0 900 50'}
		backgroundColor="#eceef4"
		foregroundColor="#e1e1ed"
		title="Cargando"
		{...props}
	>
		<rect x="0" y="10" rx="0" ry="0" width="10" height="10" />
		<rect x="20" y="10" rx="0" ry="0" width="870" height="10" />
		<rect x="0" y="30" rx="0" ry="0" width="850" height="10" />

		{size === 'xl' && (
			<>
				<rect x="0" y="60" rx="0" ry="0" width="10" height="10" />
				<rect x="20" y="60" rx="0" ry="0" width="870" height="10" />
				<rect x="0" y="80" rx="0" ry="0" width="850" height="10" />

				<rect x="0" y="110" rx="0" ry="0" width="10" height="10" />
				<rect x="20" y="110" rx="0" ry="0" width="870" height="10" />
				<rect x="0" y="130" rx="0" ry="0" width="850" height="10" />
			</>
		)}
	</ContentLoader>
);

export default TableLoader;
