import React, { useState } from 'react';

const TableRowFilter = ({
	titleRow,
	options = [],
	selectedOption,
	selectFn,
	wNullReturn,
	wParamReturn
}) => {
	const [openPopup, setOpenPopup] = useState(false);

	const handleSelectOption = (opt) => {
		selectFn(opt);
		setOpenPopup(false);
	};

	const handleDeselectOption = (paramRet) => {
		wNullReturn ? selectFn({value:null, label:'all'}) : wParamReturn ? selectFn({value:null, param:paramRet}) : selectFn({value:null});
	};

	return (
		<div className="table-filter">
			{selectedOption && selectedOption.value !== null ? (
				<div className="tit w-opt-sel" onClick={() => handleDeselectOption(wParamReturn ? selectedOption.param : null)}>
					{selectedOption.label}
					<i className="fticon-close"></i>
				</div>
			) : (
				<div className="tit" onClick={() => setOpenPopup(!openPopup)}>
					{titleRow}
					<i className="fticon-arrow-down"></i>
				</div>
			)}

			<div className={`popup-opts ${openPopup ? 'open' : ''}`}>
				<ul>
					{options.map((opt, ix) => (
						<li
							className={`${
								selectedOption?.value === opt.value ? 'sel-filter' : ''
							}`}
							key={ix}
							onClick={() => handleSelectOption(opt)}
						>
							{opt.label}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default TableRowFilter;
