import React, { useRef } from 'react';

import {
	Col,
	Row,
	Button,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';

//forms
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputForm from 'components/common/forms/InputForm';
import InputSticker from 'components/common/forms/InputSticker';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MapsForm = ({
	modalBarOpen,
	toggleModalBar,
	modalData,
	defModelForm,
	handleSubmitFormMap,
	tagList
}) => {
	const formikRef = useRef();
	const MySwal = withReactContent(Swal);
	const isEditing = modalData && modalData.value ? true : false;	

	const validationSchema = Yup.object().shape({
		label: Yup.string().required(),
		mapaEtiquetas: Yup.array()
			.of(
				Yup.object().shape({
					label: Yup.string().required(),
				})
			)
			.min(1),
	});

		const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModalBar();
			});
		} else {
			toggleModalBar();
		}
	};

	return (
		<>
			<LateralModal
				title={isEditing ? modalData.label : 'Nuevo mapa'}
				isOpen={modalBarOpen}
				toggleModal={closeModalMenu}
			>				
				<Formik
					innerRef={formikRef}
					enableReinitialize
					initialValues={
						modalData && modalData.value ? modalData : defModelForm
					}
					validationSchema={validationSchema}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={(values, actions) => handleSubmitFormMap(values, actions)}
				>
					{({ values, setFieldValue, isSubmitting }) => {
						return (
							<>
								<Form autoComplete="off">
									<ModalBody>
										<div className="site-edit">
											<div className="site-form">

												<Row>
													<Col md="12">
														<Field
															label="Nombre*"
															placeholder="Nombre*"
															name="label"
															component={InputForm}
														/>
													</Col>
													<Col md="12">
														<Field
															label="Etiqueta*"
															placeholder="Etiqueta*"
															name="mapaEtiquetas"
															className="input-create-select"
															component={InputSticker}
															options={tagList}
														/>
													</Col>
												</Row>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<Button
											type="submit"
											onClick={() => setFieldValue('transfer', false)}
											className={
												modalData.id ? 'btn-sec-dark' : 'btn-sec-light'
											}
											disabled={isSubmitting}
										>
											Guardar
										</Button>
										{!modalData.id && (
											<Button
												type="submit"
												onClick={() => setFieldValue('transfer', true)}
												className="btn-sec-dark"
												disabled={isSubmitting}
											>
												Guardar y editar
											</Button>
										)}
									</ModalFooter>
								</Form>
							</>
						);
					}}
				</Formik>					
			</LateralModal>
		</>
	);
};

export default MapsForm;
