import React from 'react';
import _ from 'lodash';

import { Button, Row, Col } from 'reactstrap';

//componentes
import CheckboxNF from 'components/common/forms/CheckboxNF';

import ReactTooltip from 'react-tooltip';

//forms
import { Formik, Form, Field } from 'formik';
import InputStep from 'components/common/forms/InputStep';

const PlanoAsistente = ({
	asistente,
	isChecked,
	ixAsistente,
	canOpenAsistente,
	openAst,
	openFn,
	updateNoSitting,
	selectCheckAsistente,
	eventoPasado,
}) => {
	const isOpened = openAst
		? openAst.invitadoId === asistente.invitadoId
			? true
			: false
		: false;

	const openAsistente = () => {
		isOpened ? openFn(null) : openFn(asistente);
	};

	return (
		<div className="asistente">
			<div
				className={`asistente-row ${isOpened ? 'expanded' : ''} ${
					eventoPasado ? 'ast-pasado' : ''
				}`}
				onClick={() => canOpenAsistente && openAsistente()}
			>
				<div className="name">
					{eventoPasado ? (
						<div
							className={`icon-table icon-entrada ${
								asistente.fechaUsoQR != null ? 'dentro' : ''
							}`}
						>
							<i className="fticon-ticket"></i>
						</div>
					) : asistente.canSelect ? (
						<CheckboxNF
							value={isChecked}
							onChange={(e) =>
								selectCheckAsistente(asistente, ixAsistente, e.target.checked)
							}
							onClick={(e) => e.stopPropagation()}
						/>
					) : (
						<div className="no-check"></div>
					)}

					<span>{asistente.nombreContacto}</span>
				</div>
				<div className="info">
					<div className="seat">
						{asistente.totalAsistentesConSitting === 0 ? (
							<i className="fticon-no-sitting"></i>
						) : asistente.totalAsistentesSinSitting !== 0 ? (
							<i className="fticon-mix-sitting"></i>
						) : (
							<i className="fticon-sitting"></i>
						)}
					</div>
					{eventoPasado ? (
						<div className="totals">
							<span>{asistente.asistentesPorInvitadoDentro}</span>/
							{asistente.asistentesPorInvitadoConEntrada}
						</div>
					) : (
						<div className="totals">
							<span>{asistente.asistentesPorInvitadoSentados}</span>/
							{asistente.asistentesPorInvitado}
						</div>
					)}
				</div>
			</div>
			{isOpened && (
				<div
					className={`asistente-info ${isOpened ? 'opened' : ''} ${
						eventoPasado ? 'ast-info-pasado' : ''
					}`}
				>
					{asistente.entradaEnviada && !eventoPasado && (
						<div className="w-entrada">
							<i className="fticon-ticket"></i>
							<p>
								A este asistente ya se le ha enviado las entradas. Si se
								modifica su ubicación, se le deberán enviar las entradas
								nuevamente.
							</p>
						</div>
					)}

					{asistente.totalAsistentes === 0 && (
						<div className="w-seatinfo text-center py-5">
							El asistente no tiene butacas asignadas
						</div>
					)}

					{asistente.totalAsistentesConSitting > 0 && (
						<>
							<div className="sitting-type">
								<div className="name">
									<i className="fticon-sitting"></i>
									<span>Sitting</span>
								</div>
								{!eventoPasado && (
									<div className="info">
										<span>{asistente.totalAsistentesConSittingSentados}</span>/
										{asistente.totalAsistentesConSitting}
									</div>
								)}
							</div>

							{asistente.totalAsistentesConSittingSentados === 0 && (
								<div className="w-seatinfo text-center py-5">
									El asistente no tiene butacas asignadas
								</div>
							)}

							{asistente.asistencia &&
								asistente.asistencia.sitting &&
								asistente.asistencia.sitting.areas &&
								asistente.asistencia.sitting.areas.map((area, ixArea) => (
									<div key={ixArea} className="w-seatinfo">
										<div className="head">
											<div className="sala">{area.nombreSala}</div>
											<div className="zona">{area.nombreArea}</div>
										</div>
										{area.filas &&
											area.filas.length > 0 &&
											area.filas.map((fila, ixFila) => (
												<div key={ixFila} className="asientos">
													<div>
														{fila.numFila && (
															<>
																Fila <span>{fila.numFila}</span>
															</>
														)}
													</div>
													<div>
														{`Butaca${fila.butacas.length > 1 ? 's: ' : ': '}`}
														<span>
															{fila.butacas.map((butaca, ixButaca) => (
																<span
																	className={butaca.esPrincipal ? 'blue' : ''}
																	onMouseOver={() => {
																		const element = document.getElementById(
																			'btc-id-' + butaca.id
																		);
																		if (element) {
																			ReactTooltip.show(element);
																		}
																	}}
																	onMouseOut={() => {
																		const element = document.getElementById(
																			'btc-id-' + butaca.id
																		);
																		if (element) {
																			ReactTooltip.hide(element);
																		}
																	}}
																	key={ixButaca}
																>{`${butaca.texto}${
																	fila.butacas.length > 1
																		? ixButaca + 1 !== fila.butacas.length
																			? ', '
																			: ''
																		: ''
																}`}</span>
															))}
														</span>
													</div>
												</div>
											))}
									</div>
								))}
						</>
					)}

					{asistente.totalAsistentesSinSitting > 0 && (
						<>
							<div className="sitting-type">
								<div className="name">
									<i className="fticon-no-sitting"></i>
									<span>No sitting</span>
								</div>
								{!eventoPasado && (
									<div className="info">
										<span>{asistente.totalAsistentesSinSittingSentados}</span>/
										{asistente.totalAsistentesSinSitting}
									</div>
								)}
							</div>

							{eventoPasado
								? asistente.asistencia.noSitting.salas.map(
										(sala, ixArea) =>
											sala.totalOcupadasInvitado > 0 && (
												<div key={ixArea} className="w-seatinfo">
													<div className="head">
														<div className="sala">
															{sala.nombreSala}{' '}
															<span className="zona">{sala.nombreArea}</span>
														</div>
														<div>
															<span className="sala">
																{sala.totalOcupadasInvitado}
															</span>{' '}
															butaca
															{sala.totalOcupadasInvitado !== 1 ? 's' : ''}
														</div>
													</div>
												</div>
											)
								  )
								: asistente.asistencia.noSitting &&
								  asistente.asistencia.noSitting.salas && (
										<Formik
											initialValues={asistente.asistencia.noSitting.salas}
											validateOnChange={false}
											validateOnBlur={false}
											onSubmit={updateNoSitting}
											enableReinitialize
										>
											{({ values, isSubmitting, dirty }) => {
												var totalCount =
													_.sumBy(values, 'totalOcupadasInvitado') <
													asistente.totalAsistentesSinSitting
														? true
														: false;
												return (
													<Form autoComplete="off">
														<div className="section-row">
															{asistente.asistencia.noSitting.salas.map(
																(sala, ixSala) => (
																	<div
																		className="w-seatinfo steps"
																		key={ixSala}
																	>
																		<Row className="step-row">
																			<Col md="4" className="sala">
																				{sala.nombreSala}
																			</Col>
																			<Col md="4" className="zona">
																				{sala.nombreArea}
																			</Col>
																			<Col md="4" className="stepper">
																				<Field
																					name={`${ixSala}.totalOcupadasInvitado`}
																					component={InputStep}
																					maxVal={sala.plazasSuma}
																					canUpdate={totalCount}
																					mini
																				/>
																			</Col>
																		</Row>
																	</div>
																)
															)}
														</div>
														{dirty && (
															<div className="section-save">
																<Button
																	className="btn-sec-light"
																	disabled={isSubmitting}
																	type="submit"
																>
																	Guardar cambios
																</Button>
															</div>
														)}
													</Form>
												);
											}}
										</Formik>
								  )}
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default PlanoAsistente;
