import React from 'react';

//Controls
import Select from 'react-select';

const NoFormSelect = ({ options, onChangeFn, ...props }) => {

	return (
		<div className="noform-select-map">
			<Select
				options={options}
				classNamePrefix="maproom-select"
				noOptionsMessage={() => 'No hay salas disponibles'}
				isSearchable={false}
            isDisabled={props.disabled}
            onChange={onChangeFn}
				{...props}
			/>
		</div>
	);
};

export default NoFormSelect;
