import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

const SeatEditEvent = ({
	eventoPasado,
	seatDetails,
	isEditingSections,
	isSittingPeople,
	updateSeatTypeFn,
	updateSittingFn,
	areaIx,
	rowIx,
	seatIx,
}) => {
	const tooltipRef = useRef(null);
	
	const composeToolTip = (seatData) => {
		if (
			!seatData ||
			seatData.type === 'space' ||
			!seatData.canSeat ||
			!seatData.section
		) {
			return;
		}

		let htmlSection = seatData.section
			? `<span class='tx-${seatData.tooltipSeat?.seccionCssClass}'>${seatData.tooltipSeat?.seccion}</span>`
			: 'Sin Sección';
		const htmlTooltip = `<div class='st-tooltip'><div class='section'>${htmlSection}</div><div class='info'><div class='zona'>${
			seatData.tooltipSeat?.area || ''
		}</div><div class='zona-st'>${
			seatData.tooltipSeat?.fila
				? `<span>Fila: ${seatData.tooltipSeat?.fila}</span>`
				: ''
		}${
			seatData.tooltipSeat?.butaca
				? `<span>Butaca: ${seatData.tooltipSeat?.butaca}</span>`
				: ''
		}</div></div>${
			seatData.asistente
				? `<div class='asist'>${
						seatData.tooltipSeat?.seccionCssClass
							? `<div class='bullet-s bgc-${seatData.tooltipSeat?.seccionCssClass}'><i class='fticon-tick'></i></div>`
							: ''
				  }${seatData.asistente?.asistentePrincipal ? '' : 'A. '}${
						seatData.tooltipSeat.nombreContacto
				  }</div>`
				: ''
		}</div>`;
		return htmlTooltip;
	};

	let timer;
	const handleClick = (event) => {
		if (!eventoPasado) {
			clearTimeout(timer);
			if (event.detail === 1) {
				timer = setTimeout(() => {
					//single click
					//secciones
					isEditingSections &&
						!isSittingPeople &&
						seatDetails.type === 'seat' &&
						updateSeatTypeFn(seatDetails, areaIx, rowIx, seatIx);
					//sitting
					!isEditingSections &&
						isSittingPeople &&
						seatDetails.type === 'seat' &&
						updateSittingFn(seatDetails, areaIx, rowIx, seatIx, false);
				}, 300);
			} else if (event.detail === 2) {
				//doble
				//sitting
				!isEditingSections &&
					seatDetails.asistente &&
					seatDetails.type === 'seat' &&
					updateSittingFn(seatDetails, areaIx, rowIx, seatIx, true);
			}
		}
	};

	return (
		<div
			id={`btc-id-${seatDetails.id}`}
			ref={tooltipRef}
			className={`row-seat ${isEditingSections ? 'seat-select-ev' : ''} ${
				isSittingPeople ? 'seat-select-sitting' : ''
			} ${seatDetails.canSeat ? '' : 'cant-seat'} ${
				seatDetails.sentado ? 'in-seat' : ''
			} ${seatDetails.type} ${
				seatDetails.section && seatDetails.section.type
					? seatDetails.section.type
					: ''
			} ${
				seatDetails.section && seatDetails.section.color
					? `bgs-${seatDetails.section.color?.claseCss}`
					: 'no-bgs'
			}`}
			onClick={handleClick}
			data-tip={isEditingSections ? null : composeToolTip(seatDetails)}
			data-for="tt-seat"
			onMouseOver={() =>
				!isEditingSections && ReactTooltip.show(tooltipRef.current)
			}
			onMouseOut={() => !isEditingSections && ReactTooltip.hide()}
		>
			<span
				className={
					seatDetails.type === 'seat' && seatDetails.asistente ? 'notempty' : ''
				}
			></span>
		</div>
	);
};

export default SeatEditEvent;
