import React from 'react';

const TableVipFilter = ({ noFilter, filterVip, setFilterVip }) => {
	return (
		<div className="vip">
			{noFilter ? (
				<i className="fticon-star"></i>
			) : (
				<div className="vip-filter" onClick={() => setFilterVip(!filterVip)}>
					{filterVip ? (
						<i className="fticon-star-solid"></i>
					) : (
						<i className="fticon-star"></i>
					)}
				</div>
			)}
		</div>
	);
};

export default TableVipFilter;
