import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
	Row,
	Col,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';

//componentes
import AsistentLoader from 'components/loaders/AsistentLoader';
import TableRowFilter from 'components/common/elems/tables/TableRowFilter';
import PlanoAsistente from './PlanoAsistente';
import PlanoBulkNoSitting from './PlanoBulkNoSitting';

//service
import * as eventsApi from 'api/eventsApi';

//forms
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputForm from 'components/common/forms/InputForm';
import InputColor from 'components/common/forms/InputColor';
import NoFormSelect from 'components/common/forms/NoFormSelect';
import StackedCheckbox from 'components/common/forms/StackedCheckbox';
import InputSelect from 'components/common/forms/InputSelect';
import CheckboxNF from 'components/common/forms/CheckboxNF';

//helpers
import {
	classCssToColor,
	COLORS_PLAIN,
	SI_NO_OPTS,
	filtroInvitadosToolbar,
} from 'utils/helpers';

const PlanosToolBar = ({
	selectedSection,
	selectedSectionFn,
	mapData,
	capacityMap,
	currentZoom = 100,
	updateCurrentZoom,
	cursorHand,
	toggleCursor,
	asistMenuOpen,
	asistMenuFn,
	sectionsMenuOpen,
	sectionsMenuFn,
	roomList,
	currentRoom,
	updateCurrentRoom,
	deleteSectionFn,
	updateSectionFn,
	eventSiteName,
	eventoPasado,
	eventID,
	asistenteSelected,
	selectAsistente,
	asistentesList,
	setAsistentesList,
	asistentesTotal,
	setAsistentesTotal,
	asistentesAsignados,
	setAsistentesAsignados,
	asistentesPorAsignar,
	setAsistentesPorAsignar,
	asistentesDentro = 0,
	setAsistentesDentro,
	asistentesPorEntrar = 0,
	setAsistentesPorEntrar,
	updateNoSittingFn,
	tmpAsistentesSelected,
	setTmpAsistentesSelected,
	showBulkModal,
	setShowBulkModal,
	updateBulkFn,
	flagSectionsTouched,
	setFlagSectionsTouched,
	handleMapDownload,
}) => {
	const history = useHistory();
	const sectionsRef = useRef();

	const [editSectionMode, setEditSectionMode] = useState(false);

	const toggleSectionsMenu = (e) => {
		if (
			sectionsMenuOpen &&
			(e.target === sectionsRef.current ||
				!sectionsRef.current.contains(e.target))
		)
			return;
		sectionsMenuFn();
		setEditSectionMode(false);
	};

	//--------------------

	const backButton = () => {
		history.goBack();
	};

	const makeZoomIn = () => {
		if (currentZoom < 200) updateCurrentZoom(currentZoom + 25);
	};

	const makeZoomOut = () => {
		if (currentZoom > 50) updateCurrentZoom(currentZoom - 25);
	};

	//--------------------

	const selectSection = (sectionInfo) => {
		if (!eventoPasado) {
			const dataUpdate =
				selectedSection.value === sectionInfo.value
					? { type: null, value: null }
					: sectionInfo;
			selectedSectionFn(dataUpdate);
		}
	};

	//--------------------

	const defNewSection = {
		value: 0,
		label: '',
		color: '',
	};

	const validationSection = Yup.object().shape({
		label: Yup.string().required(),
		color: Yup.string().required(),
	});

	const toggleEditSections = () => {
		selectedSectionFn({ type: null, value: null });
		setEditSectionMode(!editSectionMode);
	};

	//--------------------
	// ASISTENTES

	const formikRef = useRef();

	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [isLoadingAsistentes, setIsLoadingAsistentes] = useState(false);

	const [zoneList, setZoneList] = useState([]);
	const [filterSitting, setFilterSitting] = useState(null);
	const [filterRoomList, setFilterRoomList] = useState(null);
	const [currenteAsistantsFilter, setCurrenteAsistantsFilter] = useState({});

	const allRoomsOption = { label: 'Todas las salas', value: 'all' };
	const allZonesOption = { label: 'Todas las zonas', value: 'all' };

	const [tagList, setTagList] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [origins, setOrigins] = useState([]);

	const toggleAsistMenu = () => {
		flagSectionsTouched && getAsistentes(currenteAsistantsFilter);
		asistMenuFn();
	};

	useEffect(() => {
		getAsistentes(currenteAsistantsFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTags = async () => {
		//const data = await contactsApi.getTags();
		const data = await eventsApi.getEventTags(eventID);
		setTagList(data.data);
	};

	const getCompanies = async () => {
		const data = await eventsApi.getEventCompanies(eventID);
		setCompanies(data.data);
	};

	const getOrigins = async () => {
		const data = await eventsApi.getEventOrigins(eventID);
		setOrigins(data.data);
	};

	const toggleAdvancedFilter = () => {
		if (showAdvancedFilter) {
			//closeFilter event TBD
		} else {
			if (formikRef.current && !formikRef.current.dirty) {
				getTags();
				getCompanies();
				getOrigins();
			}
		}
		setShowAdvancedFilter(!showAdvancedFilter);
	};

	useEffect(() => {
		if (!_.isNull(filterSitting) && formikRef.current) {
			formikRef.current.setFieldValue('sitting', filterSitting.value);
			formikRef.current.submitForm();
		}
	}, [filterSitting]);

	useEffect(() => {
		if (!_.isNull(filterRoomList) && formikRef.current) {
			formikRef.current.setFieldValue('salaId', filterRoomList.value);
			formikRef.current.submitForm();
		}
	}, [filterRoomList]);

	const handleFilterAsistentes = (values, formProps) => {
		const updatedValues = {
			...values,
			photocall: values.photocall
				? values.photocall === '1'
					? true
					: false
				: null,
			destacado: values.destacado
				? values.destacado === '1'
					? true
					: false
				: null,
		};
		getAsistentes(updatedValues);
	};

	const getAsistentes = async (filters) => {
		setIsLoadingAsistentes(true);

		//reset seleccionado
		selectAsistente(null);

		const data = await eventsApi.getAssistants({
			...filters,
			eventoId: parseInt(eventID),
		});
		if (data && data.succeeded) {
			setAsistentesList(data.data.asistentes);
			setAsistentesTotal(data.data.totalAsistentes);
			setAsistentesAsignados(data.data.totalAsistentesAsignados);
			setAsistentesPorAsignar(data.data.totalAsistentesPorAsignar);
			setAsistentesDentro(data.data.totalAsistentesDentro);
			setAsistentesPorEntrar(data.data.totalAsistentesPorEntrar);
			setCurrenteAsistantsFilter(filters);
		}
		setFlagSectionsTouched(false);
		setIsLoadingAsistentes(false);
	};

	const getZonesList = async (roomID) => {
		const data = await eventsApi.getEventAreasMap(roomID);
		if (data && data.succeeded) {
			setZoneList(_.unionBy([allZonesOption], data.data, 'value'));
		}
	};

	useEffect(() => {
		roomList && roomList.length > 0 && getZonesList(roomList[0].mapaId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roomList]);

	//--------------------
	// BULK FNS

	const [allAsistentesSelected, setAllAsistentesSelected] = useState(false);
	const [bulkRoomList, setBulkRoomList] = useState([]);
	const [isLoadingSelectRoom, setIsLoadingSelectRoom] = useState(false);

	useEffect(() => {
		showBulkModal && getBulkRooms();
		showBulkModal && selectAsistente(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showBulkModal]);

	const toggleBulkModal = () => {
		setShowBulkModal(!showBulkModal);
	};

	const getBulkRooms = async () => {
		setIsLoadingSelectRoom(true);
		const data = await eventsApi.getNoSittingFreeAllRooms(parseInt(eventID));
		if (data && data.succeeded) {
			setBulkRoomList(data.data);
		}
		setIsLoadingSelectRoom(false);
	};

	const handleBulkUpdate = async (asistData) => {
		updateBulkFn({
			...asistData,
			filter: currenteAsistantsFilter,
			eventoId: parseInt(eventID),
		});
	};

	const handleNoSittingUpdate = async (formValues) => {
		updateNoSittingFn(formValues, currenteAsistantsFilter);
	};

	const selectCheckAsistente = (asistente, ix, state) => {
		let arraySelecteds = tmpAsistentesSelected;
		state
			? arraySelecteds.push(asistente)
			: _.remove(
					arraySelecteds,
					(item) => item.asistenteId === asistente.asistenteId
			  );
		setTmpAsistentesSelected(arraySelecteds);

		let newState = [...asistentesList];
		newState[ix].isSelected = state;
		setAsistentesList(newState);

		showBulkModal
			? arraySelecteds.length < 2 && setShowBulkModal(false)
			: arraySelecteds.length > 1 && setShowBulkModal(true);
	};

	const selectCheckAllAsistentes = async (status) => {
		const updatedAsistentes = asistentesList.map((inv) => ({
			...inv,
			isSelected: inv.canSelect ? status : false,
		}));
		setAsistentesList(updatedAsistentes);

		const noSelect = asistentesList.filter((rg) => rg.canSelect === true);

		status
			? setTmpAsistentesSelected(
					_.unionBy(tmpAsistentesSelected, noSelect, 'asistenteId')
			  )
			: setTmpAsistentesSelected(
					tmpAsistentesSelected.filter(
						(ar) =>
							!asistentesList.find((rm) => rm.asistenteId === ar.asistenteId)
					)
			  );
	};

	useEffect(() => {
		setAllAsistentesSelected(
			tmpAsistentesSelected.length > 0
				? tmpAsistentesSelected.length ===
				  _.countBy(asistentesList, (o) => o.canSelect === true).true
					? true
					: false
				: false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [asistentesList]);

	useEffect(() => {
		tmpAsistentesSelected.length > 1 && setShowBulkModal(true);
		showBulkModal &&
			tmpAsistentesSelected.length < 2 &&
			setShowBulkModal(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tmpAsistentesSelected]);

	return (
		<>
			<div className="map-toolbar">
				<Row>
					<Col md="1">
						<div className="boxed">
							<Button
								className={`btn-inmap btn-map btn-icon ${
									asistenteSelected ? 'w-cto' : ''
								} ${asistMenuOpen ? 'active' : ''}`}
								onClick={toggleAsistMenu}
							>
								<i className="fticon-user"></i>
							</Button>
						</div>

						<div className={`asist-options ${asistMenuOpen ? 'show' : ''}`}>
							<div className="asist-content">
								<div className="head-popup">
									<h3 className="mb-0">
										{eventoPasado ? 'Ocupación' : 'Asistentes'}
										<span className="badge-count">
											<span>{asistentesTotal}</span>
										</span>
									</h3>
									<Button
										className={`btn-icon ${
											showAdvancedFilter ? 'btn-prim-dark' : 'btn-prim-light'
										}`}
										onClick={toggleAdvancedFilter}
									>
										<i className="fticon-filter"></i>
									</Button>
								</div>

								<div className="filter-popup">
									<Formik
										innerRef={formikRef}
										enableReinitialize
										initialValues={{
											asignados: true,
											porasignar: true,
											dentro: true,
											porentrar: true,
											etiquetaId: [],
											etiqueta: {},
											empresaId: 0,
											empresa: {},
											procedenciaId: 0,
											procedencia: {},
											// filterRoom:
											// 	roomList && roomList[0] ? roomList[0] : allRoomsOption,
											// filterZone:
											// 	zoneList && zoneList[0] ? zoneList[0] : allZonesOption,
										}}
										validateOnChange={false}
										validateOnBlur={false}
										onSubmit={(values, actions) =>
											handleFilterAsistentes(values, actions)
										}
									>
										{({ values, setFieldValue, handleSubmit }) => {
											return (
												<Form
													autoComplete="off"
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															handleSubmit();
														}
													}}
												>
													<div
														className={`row-form-filter ${
															showAdvancedFilter ? 'd-block' : 'd-none'
														}`}
													>
														<Field
															label="Nombre o apellidos"
															placeholder="Nombre o apellidos"
															name="nombreCompleto"
															component={InputForm}
															isClearable
															extraClearFunction={handleSubmit}
														/>
														<Field
															label="Procedencia"
															placeholder="Procedencia"
															name="procedencia"
															options={origins}
															component={InputSelect}
															onChange={(e) => {
																setFieldValue('procedenciaId', e ? e.value : 0);
																handleSubmit();
															}}
															isClearable
															canSearch
														/>
														<Field
															label="Empresa"
															placeholder="Empresa"
															name="empresa"
															options={companies}
															component={InputSelect}
															onChange={(e) => {
																setFieldValue('empresaId', e ? e.value : 0);
																handleSubmit();
															}}
															isClearable
															canSearch
														/>
														<Field
															label="Cargo"
															placeholder="Cargo"
															name="cargo"
															component={InputForm}
															isClearable
															extraClearFunction={handleSubmit}
														/>
														<Field
															label="Etiqueta"
															placeholder="Etiqueta"
															name="etiqueta"
															options={tagList}
															component={InputSelect}
															onChange={(e) => {
																setFieldValue('etiquetaId', e);
																handleSubmit();
															}}
															isClearable
															isMulti
															selectables
															canSearch
															grouped
														/>
														<Row>
															<Col>
																<Field
																	label="Photocall"
																	placeholder="Photocall"
																	name="photocall"
																	options={SI_NO_OPTS}
																	component={InputSelect}
																	isClearable
																	onChange={(e) => {
																		setFieldValue(
																			'photocall',
																			e ? e.value : null
																		);
																		handleSubmit();
																	}}
																/>
															</Col>
															<Col>
																<Field
																	label="Destacado"
																	placeholder="Destacado"
																	name="destacado"
																	options={SI_NO_OPTS}
																	component={InputSelect}
																	isClearable
																	onChange={(e) => {
																		setFieldValue(
																			'destacado',
																			e ? e.value : null
																		);
																		handleSubmit();
																	}}
																/>
															</Col>
														</Row>
													</div>

													{/* <div className="row-roms">
													<Row>
														<Col>
															<Field
																label="Sala"
																placeholder="Sala"
																name="filterRoom"
																options={_.unionBy(
																	[allRoomsOption],
																	roomList,
																	'value'
																)}
																//disabled={roomList.lengh > 1 ? false : true}
																component={InputSelect}
																isClearable
															/>
														</Col>
														<Col>
															<Field
																label="Zona"
																placeholder="Zona"
																name="filterZone"
																options={zoneList}
																component={InputSelect}
																//disabled={zoneList.lengh > 1 ? false : true}
																isClearable
															/>
														</Col>
													</Row>
												</div> */}

													{eventoPasado ? (
														<div className="row-status">
															<Row>
																<Col>
																	<Field
																		label={`Dentro (${asistentesDentro})`}
																		name="dentro"
																		reverse
																		fieldStack="dentro"
																		boolValue
																		component={StackedCheckbox}
																		onChange={(e) => {
																			handleSubmit();
																		}}
																	/>
																</Col>
																<Col>
																	<Field
																		label={`Por entrar (${asistentesPorEntrar})`}
																		name="porentrar"
																		reverse
																		fieldStack="porentrar"
																		boolValue
																		component={StackedCheckbox}
																		onChange={(e) => {
																			handleSubmit();
																		}}
																	/>
																</Col>
															</Row>
														</div>
													) : (
														<div className="row-status">
															<Row>
																<Col>
																	<Field
																		label={`Asignados (${asistentesAsignados})`}
																		name="asignados"
																		reverse
																		fieldStack="asignados"
																		boolValue
																		component={StackedCheckbox}
																		onChange={(e) => {
																			handleSubmit();
																		}}
																	/>
																</Col>
																<Col>
																	<Field
																		label={`Por Asignar (${asistentesPorAsignar})`}
																		name="porasignar"
																		reverse
																		fieldStack="porasignar"
																		boolValue
																		component={StackedCheckbox}
																		onChange={(e) => {
																			handleSubmit();
																		}}
																	/>
																</Col>
															</Row>
														</div>
													)}
												</Form>
											);
										}}
									</Formik>
								</div>

								<div className="body-popup">
									{isLoadingAsistentes ? (
										<AsistentLoader />
									) : (
										<>
											<Row className="table-header mb-3">
												<Col md="6" className="text-start">
													{!eventoPasado && (
														<CheckboxNF
															value={allAsistentesSelected}
															count={tmpAsistentesSelected.length}
															onChange={(e) =>
																selectCheckAllAsistentes(e.target.checked)
															}
														/>
													)}
												</Col>
												<Col md="6" className="text-end">
													{eventoPasado ? (
														<TableRowFilter
															titleRow="Salas"
															options={roomList}
															selectedOption={filterRoomList}
															selectFn={setFilterRoomList}
														/>
													) : (
														<TableRowFilter
															titleRow="Sitting"
															options={filtroInvitadosToolbar}
															selectedOption={filterSitting}
															selectFn={setFilterSitting}
														/>
													)}
												</Col>
											</Row>

											<div className="asistentes-list">
												{asistentesList && asistentesList.length > 0 ? (
													asistentesList.map((ast, astIx) => (
														<PlanoAsistente
															key={astIx}
															asistente={ast}
															ixAsistente={astIx}
															openAst={asistenteSelected}
															canOpenAsistente={!showBulkModal}
															openFn={selectAsistente}
															updateNoSitting={handleNoSittingUpdate}
															selectCheckAsistente={selectCheckAsistente}
															eventoPasado={eventoPasado}
															isChecked={
																_.find(tmpAsistentesSelected, {
																	asistenteId: ast.asistenteId,
																})
																	? true
																	: false
															}
														/>
													))
												) : (
													<div className="no-results py-5">
														<h3>No hay invitados confirmados</h3>
														<p>
															Cuando los invitados confirmen su asistencia,
															aparecerán aquí
														</p>
													</div>
												)}	
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</Col>
					<Col md="10">
						<div className="actions">
							<div className="back boxed">
								<Button
									className="btn-inmap btn-map btn-icon"
									onClick={backButton}
								>
									<i className="fticon-arrow-left"></i>
								</Button>
							</div>
							<div className="info boxed">
								<div className="room">
									<i className="fticon-location"></i>
									<span>{eventSiteName}</span>
								</div>
								<div className="seats">
									<div>Butacas</div>
									<div>{mapData.siteComplete}</div>
								</div>
							</div>
							<div className="controls boxed">
								<Button
									className={`btn-inmap btn-icon btn-pointer ${
										!cursorHand ? 'active' : ''
									}`}
									onClick={toggleCursor}
								>
									<i className="fticon-pointer"></i>
								</Button>
								<Button
									className={`btn-inmap btn-icon ${cursorHand ? 'active' : ''}`}
									onClick={toggleCursor}
								>
									<i className="fticon-hand"></i>
								</Button>
								<div className="zoom">
									<Button className="btn-inmap btn-icon" onClick={makeZoomOut}>
										<i className="fticon-minus"></i>
									</Button>
									<div className="percent">{currentZoom}%</div>
									<Button
										className="btn-inmap btn-plus btn-icon"
										onClick={makeZoomIn}
									>
										<i className="fticon-plus"></i>
									</Button>
								</div>
							</div>

							<div className="room-select boxed">
								<NoFormSelect
									options={roomList}
									defaultValue={currentRoom}
									onChangeFn={updateCurrentRoom}
								/>
								<div className="aforo">
									<div className="total">
										<div className="bullet"></div>
										<div>
											<span>
												{capacityMap?.mapaSeats -
													capacityMap?.mapaOcuppedSeats || 0}
											</span>
											/{capacityMap?.mapaSeats || 0}
										</div>
									</div>
									<div className="sala">
										<div className="bullet green"></div>
										<div>
											<span>{capacityMap?.mapaOcuppedSeats || 0}</span>/
											{capacityMap?.mapaSeats || 0}
										</div>
									</div>
									<div className="in-room pending">
										<i className="fticon-user"></i>
										<div>
											<span>{capacityMap?.mapaPorEntrar || 0}</span>/
											{capacityMap?.mapaSeatsInside || 0}
										</div>
									</div>
									<div className="in-room inside">
										<i className="fticon-user"></i>
										<div>
											<span>{capacityMap?.mapaDentro || 0}</span>/
											{capacityMap?.mapaSeatsInside || 0}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="1">
						<div className="actions-map">
							<div className="download-map">
								<div className="boxed">
									<Button
										className={`btn-inmap btn-map btn-icon`}
										onClick={handleMapDownload}
									>
										<i className="fticon-download"></i>
									</Button>
								</div>
							</div>

							<div className="settings" ref={sectionsRef}>
								<Dropdown isOpen={sectionsMenuOpen} toggle={toggleSectionsMenu}>
									<DropdownToggle data-toggle="dropdown" tag="div">
										<div className="boxed">
											<Button
												className={`btn-inmap btn-map btn-icon ${
													selectedSection.type || selectedSection.value
														? 'w-cto'
														: ''
												}  ${sectionsMenuOpen ? 'active' : ''}`}
											>
												<i className="fticon-setting"></i>
											</Button>
										</div>
									</DropdownToggle>
									<DropdownMenu>
										<div className="drop-content">
											<div className="header">
												<h3>Secciones</h3>
												{!eventoPasado &&
													(!editSectionMode ? (
														<Button
															onClick={toggleEditSections}
															className="btn-prim-light btn-icon"
														>
															<i className="fticon-edit"></i>
														</Button>
													) : (
														<Button
															onClick={toggleEditSections}
															className="btn-sec-link btn-icon"
														>
															<i className="fticon-close"></i>
														</Button>
													))}
											</div>

											{editSectionMode ? (
												<div className="section-edit">
													<div className="new-section">
														<Formik
															initialValues={defNewSection}
															validationSchema={validationSection}
															validateOnChange={false}
															validateOnBlur={false}
															onSubmit={updateSectionFn}
															enableReinitialize
														>
															{({ isSubmitting }) => (
																<Form autoComplete="off">
																	<div className="section-row">
																		<div className="color-sel">
																			<Field
																				component={InputColor}
																				compact
																				name="color"
																				classContainer="no-m-bt"
																				colors={COLORS_PLAIN}
																			/>
																		</div>
																		<div className="texto">
																			<Field
																				label="Nueva sección"
																				placeholder="Nueva sección"
																				name="label"
																				component={InputForm}
																			/>
																		</div>
																		<div className="action">
																			<Button
																				className="btn-green btn-icon"
																				type="submit"
																				disabled={isSubmitting}
																			>
																				<i className="fticon-tick"></i>
																			</Button>
																		</div>
																	</div>
																</Form>
															)}
														</Formik>
													</div>
													<div className="old-sections">
														<ul>
															{mapData.secciones &&
																mapData.secciones.map((sectionMap, index) =>
																	sectionMap.canEdit ? (
																		<Formik
																			key={index}
																			initialValues={{
																				value: sectionMap.value,
																				color: classCssToColor(
																					sectionMap.color.claseCss
																				),
																				label: sectionMap.label,
																			}}
																			enableReinitialize
																			validationSchema={validationSection}
																			validateOnChange={false}
																			validateOnBlur={false}
																			onSubmit={updateSectionFn}
																		>
																			{({ isSubmitting }) => (
																				<Form autoComplete="off">
																					<div className="section-row">
																						<div className="color-sel">
																							<Field
																								component={InputColor}
																								compact
																								name="color"
																								classContainer="no-m-bt"
																								colors={COLORS_PLAIN}
																							/>
																						</div>
																						<div className="texto">
																							<Field
																								label="Sección"
																								placeholder="Sección"
																								name="label"
																								component={InputForm}
																							/>
																						</div>
																						<div className="actions">
																							<Button
																								className="btn-prim-light btn-icon"
																								type="button"
																								disabled={isSubmitting}
																								onClick={() =>
																									deleteSectionFn(
																										sectionMap.value
																									)
																								}
																							>
																								<i className="fticon-trash"></i>
																							</Button>
																							<Button
																								className="btn-green btn-icon"
																								type="submit"
																								disabled={isSubmitting}
																							>
																								<i className="fticon-tick"></i>
																							</Button>
																						</div>
																					</div>
																				</Form>
																			)}
																		</Formik>
																	) : (
																		<li key={index} className="fixed-section">
																			<div
																				className={`circle ${
																					sectionMap.type === 'handicap'
																						? 'blue'
																						: 'nulo'
																				} ${
																					sectionMap.color &&
																					`bgc-${sectionMap.color.claseCss}`
																				}`}
																			>
																				{sectionMap.icon && (
																					<i className={sectionMap.icon}></i>
																				)}
																			</div>
																			<div className="content">
																				<span>{sectionMap.label}</span>
																				<span></span>
																			</div>
																		</li>
																	)
																)}
														</ul>
													</div>
												</div>
											) : (
												<ul
													className={
														selectedSection.value
															? 'w-selection'
															: 'n-selection'
													}
												>
													{mapData.secciones &&
														mapData.secciones.map((sectionMap, index) => (
															<li
																key={index}
																onClick={() => selectSection(sectionMap)}
																className={`${
																	selectedSection.value === sectionMap.value
																		? 'active'
																		: ''
																}`}
															>
																<div
																	className={`circle ${
																		sectionMap.type === 'handicap'
																			? 'blue'
																			: 'nulo'
																	} ${
																		sectionMap.color &&
																		`bgc-${sectionMap.color.claseCss}`
																	}`}
																>
																	{sectionMap.icon && (
																		<i className={sectionMap.icon}></i>
																	)}
																</div>
																<div className="content">
																	<span>{sectionMap.label}</span>
																	<span>
																		{capacityMap?.sections[sectionMap.value]
																			? capacityMap.sections[sectionMap.value]
																			: 0}
																	</span>
																</div>
															</li>
														))}
												</ul>
											)}
										</div>
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			{showBulkModal && (
				<PlanoBulkNoSitting
					isOpen={showBulkModal}
					isLoadingSelect={isLoadingSelectRoom}
					asistentesSelected={tmpAsistentesSelected}
					salas={bulkRoomList}
					closeModalFn={toggleBulkModal}
					saveModalFn={handleBulkUpdate}
				/>
			)}
		</>
	);
};

export default PlanosToolBar;
