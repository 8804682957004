import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

//services
import * as contactsApi from 'api/contactsApi';

//Components
import InfiniteScroll from 'react-infinite-scroller';
import PageHeader from 'components/common/elems/PageHeader';
import WhiteBox from 'components/common/elems/WhiteBox';
import TabsPage from 'components/common/elems/TabsPage';
import Medal from 'components/common/elems/Medal';
import Sticker from 'components/common/elems/Sticker';
import TableLoader from 'components/loaders/TableLoader';
import ContactsFilter from './ContactsFilter';
import ContactsForm from './ContactsForm';
import TableRowOrder from 'components/common/elems/tables/TableRowOrder';

//helpers
import { parseDateTimeFromString } from 'utils/helpers';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

const ContactsPage = () => {
	let match = useRouteMatch('/contactos/:type');
	const viewType = match ? match.params.type : 'personas';

	const tabsDef = [
		{ tabTitle: 'Personas', tabUrl: 'personas', tabCount: 0 },
		{ tabTitle: 'Productoras', tabUrl: 'productoras', tabCount: 0 },
	];

	const [tabList, setTabList] = useState(tabsDef);
	const [modalBarOpen, setModalBarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentFilter, setCurrentFilter] = useState({ pagina: 1 });

	const [modalData, setModalData] = useState({});
	const [modalEdit, setModalEdit] = useState(false);

	const [contactsData, setContactsData] = useState([]);
	const [totalContacts, setTotalContacts] = useState(0);
	const [tagList, setTagList] = useState([]);
	const [tagListAll, setTagListAll] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [companiesAll, setCompaniesAll] = useState([]);

	const [filterOrder, setFilterOrder] = useState(null);

	useEffect(() => {
		getTotals();
		getTagsAll();
		getCompaniesAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getContacts(viewType, {pagina: 1 });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const reloadContacts = () => {
		getTotals();
		getTagsAll();
		getCompaniesAll();
		getContacts(viewType, {...currentFilter, pagina: 1 });
	};

	const getTotals = async () => {
		const data = await contactsApi.getTotalContacts();
		if (data.data && data.data.length > 0) {
			const updatedTabs = tabsDef.map((tab, index) => ({
				...tab,
				tabCount:
					index === 0
						? data.data[index].totalPerson
						: data.data[index].totalProducer,
			}));
			setTabList(updatedTabs);
		}
	};

	 const getTagsAll = async () => {
		const data = await contactsApi.getTags();
		setTagListAll(data.data);
	};

	const getCompaniesAll = async () => {
		const data = await contactsApi.getCompanies();
		setCompaniesAll(data.data);
	}; 

	const getContacts = async (
		typeCont,
		filters = currentFilter,
		more = false
	) => {
		!more && setIsLoading(true);
		const data =
			typeCont === 'personas'
				? await contactsApi.getContacts(filters)
				: await contactsApi.getProducers(filters);

		more
			? setContactsData(_.concat(contactsData, data.data.contactsList))
			: setContactsData(data.data.contactsList);

		setTotalContacts(data.data.totalContacts);
		setCurrentFilter(filters);
		setTagList(data.data.selectEtiquetas);
		setCompanies(data.data.selectEmpresas);
		!more && setIsLoading(false);
	};

	const handleSubmitFilter = (filters) => {
		searchContact({...filters, pagina:1});
	};

	const searchContact = async (filters = currentFilter) => {
		setIsLoading(true);
		const data =
			viewType === 'personas'
				? await contactsApi.getContacts(filters)
				: await contactsApi.getProducers(filters);

		setContactsData(data.data.contactsList);
		setTotalContacts(data.data.totalContacts);
		setCurrentFilter(filters);
		setIsLoading(false);
	};

	const MySwal = withReactContent(Swal);
	const removeContact = (contactId, mensajeBorrar) => {
		MySwal.fire({
			title: '¿Quieres eliminar el contacto?',
			text: `${mensajeBorrar ? 'Este usuario está asociado a algún evento, se borrará de los contactos pero se mantendrá en aquellos eventos en los que está asignado.' : ''}`,
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await contactsApi.deleteContact(contactId);
				if (data && data.succeeded) {
					notify.success('El contacto se ha eliminado correctamente.');
					reloadContacts();
				} else {
					notify.error('Ha ocurrido un error eliminando el contacto.');
				}
			}
		});
	};

	const toggleModalBar = () => {
		setModalBarOpen(!modalBarOpen);
	};

	const toggleModalEdit = (val) => {
		setModalEdit(val);
	};

	const showContact = (data, edit) => {
		setModalData(data);
		setModalEdit(edit);
		setModalBarOpen(true);
	};

	const handleSubmitForm = async (values, formProps) => {
		const formEmpresa = values.empresa
			? values.empresa.__isNew__
				? _.omit(values.empresa, ['value'])
				: values.empresa
			: null;

		const formEtiquetas = values.etiquetasContactos
			? values.etiquetasContactos.map((item) =>
					item.__isNew__ ? _.omit(item, ['value']) : item
			  )
			: null;

		const updatedValues = {
			...values,
			empresa: formEmpresa,
			etiquetas: formEtiquetas,
			etiquetasContactos: [],
		};

		const data = await contactsApi.setContact(updatedValues);
		if (data && data.succeeded) {
			if (values.reload) {
				formProps.resetForm({ values: defModelForm });
				setModalData({});
			} else {
				setModalBarOpen(false);
			}

			reloadContacts();
			notify.success(
				`El contacto se ha ${
					values.id ? 'actualizado' : 'creado'
				} correctamente.`
			);
		} else {
			notify.error(
				`Ha ocurrido un error ${
					values.id ? 'actualizando' : 'creando'
				} el contacto. ${data.message}`
			);
		}
	};

	const defModelForm = {
		nombre: '',
		esPersona: viewType === 'personas' ? true : false,
		emails: [{ mail: '' }],
		etiquetas: [],
		etiquetasContactos: [],
		empresa: null,
		deletedMails: [],
	};

	return (
		<>
			<div className="contacts-page">
				<PageHeader title={'Contactos'}>
					<Button
						className="btn-prim-dark"
						onClick={() => showContact({}, true)}
					>
						Nuevo contacto
					</Button>
				</PageHeader>

				<TabsPage
					selectedTab={viewType}
					tabList={tabList}
					baseUrl="contactos"
				/>

				<Row>
					<Col lg="12">
						<WhiteBox containerClass="contact-list">
							<Row className="pb-3">
								<Col md="12">
									<ContactsFilter
										filterType={viewType}
										tagList={tagList}
										companies={companies}
										submitFilter={handleSubmitFilter}
										orderFilter={filterOrder}
										disabled={isLoading}
									/>
								</Col>
							</Row>

							<Row className="table-header pb-3">
								<Col md="4">
									<div className="spacer-medal"></div>
									<TableRowOrder selectedOp={filterOrder} selectFn={setFilterOrder} />
								</Col>
								<Col
									className="text-center"
									md={viewType === 'personas' ? 3 : 5}
								>
									Etiqueta
								</Col>
								{viewType === 'personas' && <Col md="2">Empresa</Col>}
								<Col md="3">Modificación</Col>
							</Row>

							{isLoading ? (
								<TableLoader />
							) : (
								<div
									className={
										contactsData.length > 5
											? 'scrollable-contacts'
											: 'scrollable-contacts compact'
									}
								>
									<InfiniteScroll
										loadMore={() => {
											getContacts(
												viewType,
												{
													...currentFilter,
													pagina: currentFilter.pagina + 1,
												},
												true
											);
										}}
										hasMore={
											contactsData.length >= totalContacts ? false : true
										}
										loader={
											<div className="display-flex" key={0}>
												<TableLoader size="xs" />
											</div>
										}
										useWindow={false}
									>
										{contactsData && contactsData.length > 0 ? (
											contactsData.map((contact, index) => {
												const fullName = contact.esPersona
													? `${contact.nombre} ${
															contact.apellidos ? contact.apellidos : ''
													  }`
													: contact.nombre;

												return (
													<Row
														key={index}
														className="table-row"
														onClick={() => showContact(contact, false)}
													>
														<Col md="4" className="col-name">
															<Medal
																text={`${contact.nombre} ${
																	contact.apellidos ? contact.apellidos : ''
																}`}
																color={contact.color.claseCss}
															/>
															<div className="name">{fullName}</div>
														</Col>
														<Col
															className="text-center"
															md={viewType === 'personas' ? 3 : 5}
														>
															{contact.etiquetasContactos &&
																contact.etiquetasContactos.length > 0 &&
																contact.etiquetasContactos.map((etq, index) => (
																	<Sticker
																		key={index}
																		text={etq.etiqueta.label}
																		color={etq.etiqueta.color.claseCss}
																	/>
																))}
														</Col>

														{viewType === 'personas' && (
															<Col md="2">
																{contact.empresa && contact.empresa.label}
															</Col>
														)}

														<Col md="3" className="col-actions">
															<div className="date">
																{parseDateTimeFromString(
																	contact.updatedAt,
																	'dd-MM-yyyy'
																)}
															</div>
															<div>
																<Button
																	className="btn-sec-link btn-icon"
																	onClick={(e) => {
																		e.stopPropagation();
																		showContact(contact, false);
																	}}
																>
																	<i className="fticon-edit"></i>
																</Button>
																<Button
																	className="btn-sec-link btn-icon"
																	onClick={(e) => {
																		e.stopPropagation();
																		removeContact(contact.id, contact.mensajeBorrar);
																	}}
																>
																	<i className="fticon-trash"></i>
																</Button>
															</div>
														</Col>
													</Row>
												);
											})
										) : (
											<Col md="12" className="text-center py-5">
												Sin resultados
											</Col>
										)}
									</InfiniteScroll>
								</div>
							)}
						</WhiteBox>
					</Col>
				</Row>

				<ContactsForm
					modalBarOpen={modalBarOpen}
					toggleModalBar={toggleModalBar}
					modalEdit={modalEdit}
					modalData={modalData}
					defModelForm={defModelForm}
					handleSubmitForm={handleSubmitForm}
					tagList={tagListAll}
					companies={companiesAll}
					toggleModalEdit={toggleModalEdit}
				/>
			</div>
		</>
	);
};

export default ContactsPage;
