import React from 'react'

const TableRowOrder = ({
	titleRow="Invitado",
	selectedOp,
	selectFn,
}) => {
  return (
   <div className="filter-order" onClick={() => selectFn((selectedOp === 'desc' || selectedOp === null) ? 'asc' : 'desc')}>
      {titleRow}
      {selectedOp === 'asc' ? <i className="fticon-arrow-up"></i> : <i className="fticon-arrow-down"></i>}
   </div>
  )
}

export default TableRowOrder