import React, { useState } from 'react';

import { Button } from 'reactstrap';

const TableDropEdit = ({
	options = [],
	invitadoId,
	selectFn,
	ixDest
}) => {

	const [dropDownOpen, setDropDownOpen] = useState(false);

	const handleSelectOption = (opt, destValIx) => {
		selectFn(ixDest, opt, destValIx, invitadoId);
		setDropDownOpen(false);
	};

	return (
		<>
			<div className="drop-button">
				<Button
					className="btn-prim-link btn-icon"
					onClick={()=> setDropDownOpen(!dropDownOpen)}
				>
					<i className="fticon-edit"></i>
				</Button>

				<div className={`drop-backdrop ${dropDownOpen ? 'd-block':'d-none'}`} onClick={()=>setDropDownOpen(!dropDownOpen)}></div>

				<div className={`drop-content ${dropDownOpen ? 'opened' : ''}`}>
					<ul>
						{options.map((opt, ix) => (
							<li
								className={`${
									opt.emailEntrada ? 'sel-filter' : ''
								}`}
								key={ix}
								onClick={() => handleSelectOption(opt, ix)}
							>
								{opt.email}
								{opt.emailEntrada && <i className='fticon-confirm'></i>}
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	);
};

export default TableDropEdit;
