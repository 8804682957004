import React from 'react';

import { Input } from 'reactstrap';
import { randomHash } from 'utils/helpers';

const CheckboxNF = ({ value, count=0, ...props }) => {
	return (
		<div className="input-type-checkbox single">
			<Input
            type="checkbox"
				checked={value || false}
				id={`input-check-${randomHash()}`}
				{...props}
			/>
			{count > 0 && (<span className='count-checkbox'>{count}</span>)}
		</div>
	);
};

export default CheckboxNF;
