import React, { useEffect, useState } from 'react';

import { Col, Row, Container, ModalBody } from 'reactstrap';

//componentes
import WhiteBox from 'components/common/elems/WhiteBox';
import NotificationMailPreview from 'components/common/mail/NotificationMailPreview';
import TabsPage from 'components/common/elems/TabsPage';
import ContactsFilter from 'pages/contacts/ContactsFilter';
import TableLoader from 'components/loaders/TableLoader';
import LoadingLayer from 'components/loaders/LoadingLayer';
import Sticker from 'components/common/elems/Sticker';
import TableVipFilter from 'components/common/elems/tables/TableVipFilter';
import TableRowOrder from 'components/common/elems/tables/TableRowOrder';
import InfiniteScroll from 'react-infinite-scroller';

//helpers
import {
	parseDateTimeFromString,
	invitationSentClass,
} from 'utils/helpers';

//services
import * as eventsApi from 'api/eventsApi';

const NotificationSentDetails = ({
	notificationData,
	tipoAnunciante,
	eventDataSimple,
}) => {
	const [invitadosList, setInvitadosList] = useState([]);
	const [invitadosTotal, setInvitadosTotal] = useState(0);
	const [invitadosTotalFilter, setInvitadosTotalFilter] = useState(0);
	const [tableLoading, setTableLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);

	const [tagList, setTagList] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [origins, setOrigins] = useState([]);

	const [currentTab, setCurrentTab] = useState('actividad');

	const [filterStatus, setFilterStatus] = useState();
	const [filterVip, setFilterVip] = useState(null);
	const [filterOrder, setFilterOrder] = useState(null);
	const [currentFilter, setCurrentFilter] = useState({});

	useEffect(() => {
		getSentNotifications({}, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tabsDef = [
		{ tabTitle: 'Actividad', tabUrl: 'actividad', tabCount: 0 },
		{ tabTitle: 'Datos generales', tabUrl: 'datos', tabCount: 0 },
	];

	const getSentNotifications = async (filters, inPage = false) => {
		inPage ? setPageLoading(true) : setTableLoading(true);

		const data = await eventsApi.getNotificationReceivers({
			...filters,
			notificacionId: parseInt(notificationData.id),
			pagina: 1,
		});

		if (data && data.succeeded) {
			setInvitadosList(data.data.receiverList);
			setInvitadosTotal(data.data.totalReceiverNotification);
			setInvitadosTotalFilter(data.data.totalReceiver);

			setCompanies(data.data.selectEmpresas);
			setOrigins(data.data.selectProcedencias);
			setTagList(data.data.selectEtiquetas);

			setCurrentFilter({ ...currentFilter, pagina: 1 });
		}

		inPage ? setPageLoading(false) : setTableLoading(false);
	};

	//---------------------------------

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreInvitations = async (newPage) => {
		if (
			currentFilter &&
			currentFilter.pagina &&
			newPage > currentFilter.pagina &&
			!isLoadingMore
		) {
			setIsLoadingMore(true);
			const data = await eventsApi.getNotificationReceivers({
				...currentFilter,
				pagina: newPage,
				notificacionId: parseInt(notificationData.id),
			});
			if (data && data.succeeded) {
				setInvitadosList((prevList) => [
					...prevList,
					...data.data.receiverList,
				]);
				setCurrentFilter({ ...currentFilter, pagina: newPage });
				setIsLoadingMore(false);
			}
		}
	};

	const totalPages = Math.ceil((invitadosTotalFilter || 0) / 50);
	const currentPage = currentFilter?.pagina || 1;

	//---------------------------------

	const handleSubmitFilter = (values) => {
		getSentNotifications(values);
	};

	const handleStatusFilter = (newState) => {
		//filterStatus === newState ? setFilterStatus(null) : setFilterStatus(newState);
	};

	return (
		<ModalBody className={currentTab === 'actividad' ? '' : 'p-0'}>
			{pageLoading && <LoadingLayer visible={pageLoading} />}

			<div
				className={
					currentTab === 'actividad' ? 'bg-gray' : 'bg-white padded-head'
				}
			>
				<Container fluid>
					<Row>
						<TabsPage
							selectedTab={currentTab}
							tabList={tabsDef}
							updateSelectedFn={setCurrentTab}
							noUrlChange
						/>
					</Row>
				</Container>
			</div>

			{currentTab === 'actividad' ? (
				<Container fluid>
					<Row
						className={`header-filter mt-4 mb-4 ${
							filterStatus ? 'filtered' : ''
						}`}
					>
						<Col md="2">
							<WhiteBox
								onClick={() => handleStatusFilter('sent')}
								containerClass={`${
									filterStatus === 'sent' ? 'filter-active' : ''
								}`}
							>
								<div className="filter-box">
									<div className="status">Enviadas</div>
									<div className="info">
										<div className="badge sent">
											{notificationData?.totalSent || 0}
										</div>
									</div>
								</div>
							</WhiteBox>
						</Col>
						<Col md="2">
							<WhiteBox
								onClick={() => handleStatusFilter('bounced')}
								containerClass={`${
									filterStatus === 'bounced' ? 'filter-active' : ''
								}`}
							>
								<div className="filter-box">
									<div className="status">Rebotadas</div>
									<div className="info">
										<div className="badge bounced">
											{notificationData?.totalReturned || 0}
										</div>
									</div>
								</div>
							</WhiteBox>
						</Col>
						<Col md="2">
							<WhiteBox
								onClick={() => handleStatusFilter('opened')}
								containerClass={`${
									filterStatus === 'opened' ? 'filter-active' : ''
								}`}
							>
								<div className="filter-box">
									<div className="status">Abiertas</div>
									<div className="info">
										<div className="badge opened">
											{notificationData?.totalOpened || 0}
										</div>
									</div>
								</div>
							</WhiteBox>
						</Col>
						<Col md="2">
							<WhiteBox
								onClick={() => handleStatusFilter('unopen')}
								containerClass={`${
									filterStatus === 'unopen' ? 'filter-active' : ''
								}`}
							>
								<div className="filter-box">
									<div className="status">Sin abrir</div>
									<div className="info">
										<div className="badge unopen">
											{notificationData?.totalNotOpened || 0}
										</div>
									</div>
								</div>
							</WhiteBox>
						</Col>
					</Row>

					<Row>
						<Col lg="12">
							{invitadosTotal > 0 ? (
								<WhiteBox containerClass="full-box invitados-list">
									<Row>
										<Col md="12">
											<ContactsFilter
												tagList={tagList}
												companies={companies}
												fromOrigin={origins}
												submitFilter={handleSubmitFilter}
												vipFilter={filterVip}
												orderFilter={filterOrder}
												disabled={tableLoading}
											/>
										</Col>
									</Row>

									<Row className="table-header mt-2 pb-3">
										<Col md="5">
											<div className="col-star">
												<TableVipFilter
													filterVip={filterVip}
													setFilterVip={setFilterVip}
												/>
												<TableRowOrder
													selectedOp={filterOrder}
													selectFn={setFilterOrder}
												/>
											</div>
										</Col>
										<Col md="5">Email</Col>
										<Col md="2" className="text-right">
											Estado
										</Col>
									</Row>

									<Row>
										<Col md="12">
											<div className="contact-list">
												{tableLoading ? (
													<TableLoader />
												) : invitadosList && invitadosList.length > 0 ? (
													<div className="scrollable-contacts">
														<InfiniteScroll
															pageStart={0}
															loadMore={() =>
																loadMoreInvitations(currentPage + 1)
															}
															hasMore={currentPage < totalPages}
															useWindow={false}
															loader={
																<div key={0} style={{ clear: 'both' }}>
																	<TableLoader size="xs" />
																</div>
															}
														>
															{invitadosList.map((contact, index) => {
																const contactData = tipoAnunciante
																	? contact.contacto.contactoAnunciante
																	: contact.contacto.contacto;
																const fullName = contactData.esPersona
																	? `${contactData.nombre} ${
																			contactData.apellidos
																				? contactData.apellidos
																				: ''
																	  }`
																	: contactData.nombre;

																return (
																	<Row key={index} className="table-row">
																		<Col md="5">
																			<div className="col-star">
																				<div className="vip">
																					{contact.contacto?.esVIP && (
																						<i className="fticon-star-solid"></i>
																					)}
																				</div>
																				<div className="name">
																					{fullName}
																					{contact.contacto?.procedencia && (
																						<div className="sub-proc">
																							{
																								contact.contacto.procedencia
																									.label
																							}
																						</div>
																					)}
																				</div>
																			</div>
																		</Col>
																		<Col md="5" className="col-center">
																			<div className="mail-list">
																				{contactData.emails &&
																					contactData.emails.map(
																						(mail, ix) =>
																							`${ix !== 0 ? ', ' : ''}${mail.mail
																							}`
																					)}
																			</div>
																		</Col>
																		<Col md="2" className="col-center">
																			<Sticker
																				text={
																					contact.estadoEnvio?.estado ||
																					'Sin abrir'
																				}
																				color={invitationSentClass(
																					contact.estadoEnvio?.id
																				)}
																			/>
																		</Col>
																	</Row>
																);
															})}
														</InfiniteScroll>
													</div>
												) : (
													<div className="no-results py-5">
														No se han encontrado resultados.
													</div>
												)}
											</div>
										</Col>
									</Row>
								</WhiteBox>
							) : (
								<WhiteBox containerClass="full-box empty">
									<div className="no-results">
										<h3>No hay resultados disponibles</h3>
									</div>
								</WhiteBox>
							)}
						</Col>
					</Row>
				</Container>
			) : (
				<>
					<div className="bg-white padded-body">
						<Container fluid>
							<Row className="pt-4 info-mail">
								<Col lg="12">
									<Row className="mb-2">
										<Col md="2">Fecha de envío:</Col>
										<Col md="10">
											{parseDateTimeFromString(notificationData?.fechaEnvio)}
										</Col>
									</Row>

									<Row className="mb-2">
										<Col md="2">Asunto:</Col>
										<Col md="10">{notificationData?.asunto}</Col>
									</Row>

									<Row className="mb-2">
										<Col md="2">De:</Col>
										<Col md="10">{`${notificationData?.sendFromNombre} · ${notificationData?.sendFromEmail}`}</Col>
									</Row>

									<Row className="mb-3">
										<Col md="2">Enviado a:</Col>
										<Col md="10">{`${notificationData?.totalSent} destinatarios`}</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</div>
					<Container fluid>
						<Row className="preview-container">
							<Col lg="12 mt-5">
								<NotificationMailPreview
									notificationData={notificationData}
									notificationEvent={eventDataSimple}
								/>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</ModalBody>
	);
};

export default NotificationSentDetails;
