import { callApiGet, callApiPost } from './apiUtils';
const url_base = '/lugares';

export const getSites = async (data) => {
	return await callApiPost(`${url_base}/getSites`,data);
};

export const getColors = async () => {
	return await callApiGet(`${url_base}/getColors`);
};

export const getMaps = async () => {
	return await callApiGet(`${url_base}/getMaps`);
};

export const setSite = async (siteData) => {
	return await callApiPost(`${url_base}/setSite`, siteData);
};

export const deleteSite = async (siteID) => {
	return await callApiPost(`${url_base}/deleteSite/${siteID}`,siteID);
};

export const getSitesAndRooms = async () => {
	return await callApiGet(`${url_base}/getSitesAndRooms`);
};

export const getMapTags = async () => {
	return await callApiGet(`${url_base}/getMapTags`);
};

export const getMapsByTag = async (tagId) => {
	return await callApiGet(`${url_base}/getMapsByTag/${tagId}`);
};

export const getAreasByMap = async (mapId) => {
	return await callApiGet(`${url_base}/getAllAreasMap/${mapId}`);
};



