import React from 'react';
import _ from 'lodash';

//componentes
import { Row, ModalBody, Col, ModalFooter, Button } from 'reactstrap';
import LateralModal from 'components/common/modals/LateralModal';
import CheckboxNF from 'components/common/forms/CheckboxNF';

const EntradasUnifyModal = ({
	isOpen,
	toggleModal,
	unifyContacts,
	setUnifyContacts,
	unifyUpdate,
}) => {
	const handleSaveChanges = () => {
		unifyUpdate(unifyContacts);
		toggleModal();
	};
	const handleSelectDestinatario = (destIndex, index, status) => {
		const updatedDest = unifyContacts.map((elem, ix) =>
			ix === destIndex
				? {
						...elem,
						invitado: elem.invitado.map((dm, ixdm) =>
							(
								index === ixdm ? 
								{ ...dm, unificarEnvio: status }
								: dm
							)
						),
				  }
				: elem
		);
		setUnifyContacts(updatedDest);
	};

	const handleSelectAllDestinatarios = (index, status) => {			
		const updatedContacts = unifyContacts.map((elem, ix) =>
			ix === index
				? {
						...elem,
						invitado: elem.invitado.map((inv) => ({
							...inv,
							unificarEnvio: status,
						})),
				  }
				: elem
		);
		setUnifyContacts(updatedContacts);
	};

	return (
		<LateralModal
			size="xm"
			isOpen={isOpen}
			toggleModal={toggleModal}
			title="Unificar envío"
		>
			<ModalBody className="event-unify-modal">
				<Row>
					<Col md="12">
						<p>
							Los siguientes desinatarios tienen la misma dirección de email.
							Indica a continuación cómo se debe tratar el envío.
						</p>
					</Col>
				</Row>
				<Row className="list-contacts">
					{unifyContacts && unifyContacts.length > 0 ? (
						unifyContacts.map((contact, index) => (
							<div key={index} className="contact-tounify">
								<div className="rep">{contact.email}</div>
                        <div className='select'>
                           <span>Seleccionar todos</span>
                           <CheckboxNF 
						    value={
							contact.invitado !== 0 &&
								contact.invitado.length ===
									_.countBy(contact.invitado, (o) => o.unificarEnvio === true).true
								? true
								: false}
							onChange={(e) =>
								handleSelectAllDestinatarios(index,e.target.checked)
							}
						   />
                        </div>
								<div className="mail-list">									
									{contact.invitado &&
										contact.invitado.map((ct, ixCt) => (
												<div className="contact" key={ixCt}>
													<div className="name">
														<i className="fticon-user"></i>
														<span>{ct.nombre}</span>
													</div>
													<div className="check">
														<CheckboxNF 
														value={unifyContacts[index].invitado[ixCt].unificarEnvio}										
														onChange={(e) =>
															handleSelectDestinatario(
																index,
																ixCt,
																e.target.checked
															)
														}
														/>
													</div>
												</div>
										))}
								</div>
							</div>
						))
					) : (
						<Col md="12" className="my-5 py-5 text-center">
							No se han encontrado contactos coincidentes.
						</Col>
					)}
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button className="btn-sec-dark" onClick={handleSaveChanges}>
					Guardar
				</Button>
			</ModalFooter>
		</LateralModal>
	);
};

export default EntradasUnifyModal;
