import React from 'react'

const SiteButton = ({roomName, roomCapacity}) => {
  return (
    <div className='site-button'>
      <div className='icon'><i className='fticon-location'></i></div>
      <div className='name'>{roomName || 'Sala'}</div>
      <div className='aforo'>{roomCapacity || 0}</div>
    </div>
  )
}

export default SiteButton