import React from 'react';
import _ from 'lodash';

import {
	FormGroup,
	Input,
	Label,
} from 'reactstrap';

import { randomHash } from 'utils/helpers';

const InputForm = ({
	label,
	baseLabel,
	noSpace,
	reverse,
	//Formik
	field,
	form,
	innerRef,
	//Others
	classContainer = '',
	isClearable,
	extraClearFunction,
	...props
}) => {
	const error = form && form.errors && _.get(form.errors, field.name);
	const inputType = props.type || 'text';
	const isDisabled = props.disabled ? true : false;

	const randomIdField = `input-${randomHash()}`;
	
	return (
		<div
			className={`input-type-${inputType} ${classContainer}${
				isClearable && field.value ? ' input-group' : ''
			}`}
		>
			<FormGroup
				className={`${!baseLabel ? 'form-floating flex-grow-1' : 'form-group'}${
					isDisabled ? ' disabled' : ''
				}${error && typeof error === 'string' ? ' has-error' : ''}`}
			>
				{inputType === 'switch' ||
				inputType === 'radio' ||
				inputType === 'checkbox' ? (
					
					<Label htmlFor={randomIdField}>
						{reverse && label}
						<Input
							className={`${
								error && typeof error === 'string' ? 'border-error' : ''
							}`}
							onChange={(e) => {
								form.setFieldValue(field.name, e.target.checked);
								form.setFieldTouched(field.name, true);
							}}
							onBlur={() => form.setFieldTouched(field.name, true)}
							value={field.value || ''}
							checked={field.value || false}
							id={randomIdField}
							{...props}
						/>
						{inputType === 'switch' && <span className={`slider ${!reverse ? 'normal':'invers'}`}></span>}
						{!reverse && label}
					</Label>
				) : (
					<>
						<Input
							placeholder={label}
							className={`${
								error && typeof error === 'string' ? 'border-error' : ''
							}`}
							onChange={(e) => {
								form.setFieldValue(field.name, e.currentTarget.value);
								form.setFieldTouched(field.name, true);
							}}
							onBlur={() => form.setFieldTouched(field.name, true)}
							value={field && field.value ? field.value : ''}
							onKeyDown={(e)=> noSpace && e.key === " " && e.preventDefault()}
							id={randomIdField}
							{...props}
						/>
						<Label htmlFor={randomIdField}>{label}</Label>
						{inputType === 'date'&& <span className="input-date-icon"></span>}
						{inputType === 'time'&& <span className="input-time-icon"></span>}
					</>
				)}
			</FormGroup>
			{isClearable && field.value && (
				<span
					className={`input-group-text clear-input mb-3${
					isDisabled ? ' disabled' : ''
				}`}
					onClick={() => {
						form.setFieldValue(field.name, '');
						extraClearFunction && extraClearFunction();
					}}
				>
					<i className="fticon-close"></i>
				</span>
			)}
		</div>
	);
};

export default InputForm;
