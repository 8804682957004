import React from 'react';

const SeatSimple = ({ seatDetails }) => {
	return (
		<>
			<div
				className={`row-seat ${seatDetails.type} ${
					seatDetails.section ? seatDetails.section.type : ''
				}`}
			>
				<span></span>
			</div>
		</>
	);
};

export default SeatSimple;
