import React from 'react';

//components
import WhiteBox from 'components/common/elems/WhiteBox';

const InvitadosHeader = ({
	categorias,
	noClick,
	filterStatus = "invitados",
	handleStatusFilter,
}) => {
	return (
		<div
			className={`filter-scroll ${
				filterStatus ? 'filtered' : ''
			}`}
		>
			{categorias &&
				categorias.length > 0 &&
				categorias.map((categoria, ixCat) => (
					<div className="filter-box" key={ixCat}>
						<WhiteBox
							onClick={() =>
								handleStatusFilter && handleStatusFilter(categoria.clase)
							}
							containerClass={`${
								filterStatus === categoria.clase ? 'filter-active' : ''
							}`}
						>
							<div
								className={`asist-box ${categoria.clase} ${
									noClick ? 'no-pointer' : ''
								}`}
							>
								<div className="icon">
									<i className={categoria.icono} />
								</div>
								<div className="info">
									<div className="tit">{categoria.titulo}</div>
									<div className="nums">
										<span>{categoria.asistentes}</span>
										<span>{categoria.invitados}</span>
									</div>
								</div>
							</div>
						</WhiteBox>
					</div>
				))}
		</div>
	);
};

export default InvitadosHeader;
