import React from 'react';

const Sticker = ({ text, color = 'blue', removeAction=null }) => {
	return (
		<div className={`sticker sticker-${color}`}>
			{text}
			{removeAction && <i className="fticon-close"></i>}
		</div>
	);
};

export default Sticker;
