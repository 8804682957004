import React from 'react';
import _ from 'lodash';

import { FormGroup, Input, Label } from 'reactstrap';
import { randomHash } from 'utils/helpers';

const InputStep = ({
	label,
	//Formik
	field,
	form,
	maxVal=9999,
	canUpdate = true,
	//Others
	classContainer = '',
	mini,
	canWrite,
	...props
}) => {
	const error = form && form.errors && _.get(form.errors, field.name);
	const isDisabled = props.disabled ? true : false;
	const randomIdField = `input-${randomHash()}`;

	const updateNumber = (newVal, action) => {
		if (newVal >= 0) {
			if(action === 'plus') {
				if(newVal <= maxVal && canUpdate) {
					form.setFieldValue(field.name, newVal);
					form.setFieldTouched(field.name, true);
				}
			} else {
				form.setFieldValue(field.name, newVal);
				form.setFieldTouched(field.name, true);
			}
		}
	};

	return (
		<div
			className={`input-type-step input-group ${
				mini ? 'mini-step' : ''
			} ${classContainer}`}
		>
			<FormGroup
				className={`${mini ? '' : 'form-floating flex-grow-1'} ${
					isDisabled ? 'disabled' : ''
				}${error && typeof error === 'string' ? 'has-error' : ''}`}
			>
				<Input
					placeholder={label}
					className={`${
						error && typeof error === 'string' ? 'border-error' : ''
					}`}
					type="number"
					min={0}
					readOnly={canWrite ? false : true}
					onChange={(e) => form.setFieldValue(field.name, parseInt(e.target.value) || "")}
					value={field.value}
					id={randomIdField}
					{...props}
				/>
				{!mini && <Label>{label}</Label>}
			</FormGroup>
			<span
				className="input-group-text button-action mb-3"
				onClick={() => updateNumber((parseInt(field.value) || 0) - 1, 'minus')}
			>
				<i className="fticon-minus"></i>
			</span>
			<span
				className="input-group-text button-action mb-3"
				onClick={() => updateNumber((parseInt(field.value) || 0) + 1, 'plus')}
			>
				<i className="fticon-plus"></i>
			</span>
		</div>
	);
};

export default InputStep;
