import React, { useState, useEffect } from 'react';

//components
import { Button, Col, Row } from 'reactstrap';
import WhiteBox from 'components/common/elems/WhiteBox';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useHistory } from 'react-router-dom';
import EventsForm from '../EventsForm';
import LoadingLayer from 'components/loaders/LoadingLayer';
import InvitadosHeader from './tables/InvitadosHeader';
import Sticker from 'components/common/elems/Sticker';
import 'react-circular-progressbar/dist/styles.css';
import ReactTooltip from 'react-tooltip';

//helpers
import {
	mergeDateAndTime,
	spanishLongDate,
	truncateText,
} from 'utils/helpers';

//services
import * as eventsApi from 'api/eventsApi';

//icons
import InviPend from 'css/images/icons/dashboard-invitation-pending-grey.svg';
import InviSent from 'css/images/icons/dashboard-invitation-send-grey.svg';
import TicketPend from 'css/images/icons/dashboard-ticket-pending-grey.svg';
import TicketSent from 'css/images/icons/dashboard-ticket-send-grey.svg';
import SeatPend from 'css/images/icons/dashboard-seat-pending.svg';
import SeatSeat from 'css/images/icons/dashboard-seat-do.svg';
import CountDown from 'components/common/elems/CountDown';

//notifications
import * as notify from 'utils/notify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const EventDashboard = ({ eventID }) => {
	const [modalBarOpen, setModalBarOpen] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);

	const [eventData, setEventData] = useState(null);

	const history = useHistory();

	useEffect(() => {
		getEventData(eventID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//dashboard data
	const getEventData = async (evID) => {
		setPageLoading(true);
		const data = await eventsApi.getEvent(evID);
		setEventData(data.data);
		setPageLoading(false);
	};

	const toggleModalBar = () => {
		setModalBarOpen(!modalBarOpen);
	};

	const MySwal = withReactContent(Swal);
	const removeEvent = (eventID) => {
		MySwal.fire({
			title: '¿Quieres eliminar el evento?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				setPageLoading(true);
				const data = await eventsApi.deleteEvento(eventID);
				if (data && data.succeeded) {
					setPageLoading(false);
					notify.success('El evento se ha eliminado correctamente.');
					history.push(`/eventos`);
				} else {
					setPageLoading(false);
					notify.error('Ha ocurrido un error eliminando el evento.');
				}
			}
		});
	};

	const handleSubmitForm = async (values, formProps) => {
		setPageLoading(true);
		const eventForm = new FormData();
		eventForm.append('id', values.id);
		eventForm.append('nombre', values.nombre);
		eventForm.append('descripcion', values.descripcion || '');
		eventForm.append('fechaEvento', values.fechaEventoDate);
		eventForm.append('horaEvento', values.horaEvento);
		eventForm.append('horaPhotoCall', values.horaPhotoCall);
		eventForm.append('horaApertura', values.horaApertura);
		eventForm.append('horaLimiteAcceso', values.horaLimiteAcceso);
		eventForm.append('fechaLimiteConfirm', values.fechaLimiteConfirmDate);
		eventForm.append('horaLimiteConfirm', values.horaLimiteConfirm);
		eventForm.append('documento', values.urlImagen);
		eventForm.append('invitados', values.invitados);
		eventForm.append('salasId', values.salasIdArray.join(','));
		eventForm.append('lugarOrigenId', values.lugarOrigenId);
		eventForm.append('activo', values.activo);
		eventForm.append('esAnunciante', values.esAnunciante);
		eventForm.append('defaultInvitations', values.defaultInvitations);

		const data = values.esAnunciante
			? await eventsApi.setAdvertEvent(eventForm)
			: await eventsApi.setPremiere(eventForm);

		if (data && data.succeeded) {
			notify.success(`El evento ha sido actualizado.`);
			getEventData(eventID);
			toggleModalBar();
		} else {
			notify.error(
				`Ha ocurrido un error ${
					values.id ? 'actualizando' : 'creando'
				} el evento. ${data.message}`
			);
		}

		setPageLoading(false);
	};

	const handleDownloadAsistentsInfo = async () => {
		setPageLoading(true);
		try {
			const url = `/eventos/downloadEntradas/${eventID}`;
			const fileName = `Evento ${eventData.nombre}.xlsx`;
			await eventsApi.downloadExcelAuth(url, fileName);
		} catch (ex) {
			notify.error(
				`Ha ocurrido un error al descargar el listado. ${ex.message}`
			);
		}
		setPageLoading(false);
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}
			{eventData && (
				<div className="dashboard-page dashboard-home">
					<ReactTooltip id="tt-dash" effect="solid" />
					<Row>
						<Col lg="12" className="mt-2 mb-5">
							<WhiteBox containerClass="event-header">
								<Row>
									<Col md="12" className="header-cont">
										<div
											className="event-image"
											style={{ backgroundImage: `url(${eventData.urlImagen})` }}
										>
											<div className="counter-container">
												{eventData.eventoPasado && eventData.estadisticas ? (
													<div className="event-finished">
														<div className="asistencia">
															<div className="tit">Asistencia</div>
															<div className="data">
																<span>
																	{
																		eventData.estadisticas.asistencia
																			.asientosDentro
																	}
																</span>
																{' / '}
																{
																	eventData.estadisticas.asistencia
																		.asientosTotal
																}
															</div>
														</div>
														<div
															className={`porcentaje ${
																eventData.estadisticas.asistencia
																	.porcentajeAsistencia === 100
																	? 'tx-sucs-dark'
																	: 'tx-pend'
															}`}
														>
															{`${eventData.estadisticas.asistencia.porcentajeAsistencia} %`}
														</div>
													</div>
												) : (
													<CountDown
														finishDate={mergeDateAndTime(
															eventData.fechaEvento,
															eventData.horaEvento
														)}
													/>
												)}
											</div>
										</div>
										<div className="event-info">
											<div className="actions">
												{eventData.canDelete && (
													<Button
														className="btn-prim-light btn-icon me-2"
														onClick={() => {
															removeEvent(eventID);
														}}
													>
														<i className="fticon-trash"></i>
													</Button>
												)}

												<Button
													className="btn-prim-light btn-icon me-2"
													onClick={handleDownloadAsistentsInfo}
													data-tip="Descargar información de invitados confirmados"
													data-for="tt-dash"
												>
													<i className="fticon-download"></i>
												</Button>

												{eventData.canEdit && (
													<Button
														onClick={toggleModalBar}
														className="btn-prim-light"
													>
														Editar
													</Button>
												)}

												{eventData.eventoPasado && (
													<Sticker text="Evento finalizado" color="bggreen" />
												)}
											</div>

											<div className="general">
												<h1>{eventData.nombre}</h1>
												<p className="fecha">
													<i className="fticon-calendar"></i>
													{spanishLongDate(eventData?.fechaEvento)}
													<span>·</span>
													{`${eventData.horaEvento}h`}
													<span></span>
													{eventData.horaPhotoCall && (
														<>
															<i className="fticon-camera"></i>
															{`${eventData.horaPhotoCall}h`}
															<span></span>
														</>
													)}
													<i className="fticon-ticket"></i>
													{`${eventData.horaApertura} - ${eventData.horaLimiteAcceso}`}
												</p>
												<p className="lugar">
													{eventData.eventoLugar.label}
													{eventData.estadisticas &&
														eventData.estadisticas.lugar &&
														eventData.estadisticas.lugar.salas &&
														eventData.estadisticas.lugar.salas.map(
															(room, ixRoom) => (
																<React.Fragment key={ixRoom}>
																	<span>·</span>
																	{room.nombre}
																</React.Fragment>
															)
														)}
												</p>
												<div className="invitados">
													<span className="blue">
														<i className="fticon-user"></i>
														{eventData.estadisticas.totalInvitados}
													</span>
													<span>invitados</span>
												</div>

												{eventData.descripcion ? (
													<div className="descripcion">
														<p>{truncateText(eventData.descripcion)}</p>
													</div>
												) : (
													<div className="no-desc"></div>
												)}
											</div>
											<div className="progress-event">
												<div className="ball">
													<CircularProgressbarWithChildren
														className="invitados"
														value={eventData.estadisticas.porcentajeInvitados}
														strokeWidth={5}
													>
														<i className="fticon-user"></i>
													</CircularProgressbarWithChildren>
												</div>
												<div className="ball">
													<CircularProgressbarWithChildren
														className="edition"
														value={eventData.estadisticas.porcentajeEditados}
														strokeWidth={5}
													>
														<i className="fticon-edit"></i>
													</CircularProgressbarWithChildren>
												</div>
												<div className="ball">
													<CircularProgressbarWithChildren
														className="invitaciones"
														value={eventData.estadisticas.porcentajeInvitados}
														strokeWidth={5}
													>
														<i className="fticon-mail"></i>
													</CircularProgressbarWithChildren>
												</div>
												<div className="ball">
													<CircularProgressbarWithChildren
														className="seats"
														value={eventData.estadisticas.porcentajeAsientos}
														strokeWidth={5}
													>
														<i className="fticon-sitting"></i>
													</CircularProgressbarWithChildren>
												</div>
												<div className="ball">
													<CircularProgressbarWithChildren
														className="tickets"
														value={eventData.estadisticas.porcentajeEntradas}
														strokeWidth={5}
													>
														<i className="fticon-ticket"></i>
													</CircularProgressbarWithChildren>
												</div>
											</div>
											<div className="limite">
												<p>
													Límite confirmación:{' '}
													{spanishLongDate(eventData?.fechaLimiteConfirm)}
													<span>·</span>
													{`${eventData?.horaLimiteConfirm}h`}
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</WhiteBox>
						</Col>
					</Row>

					<Row>
						<Col md="12" className="mb-4 header-asist">
							<div className="w-icon">
								<div>
									<h3>Asistentes</h3>
								</div>
								{eventData.estadisticas.asistentes.numObservaciones !== 0 && (
									<div className="icono">
										<div
											className="boxed-icon w-badge"
											data-count={
												eventData.estadisticas.asistentes.numObservaciones
											}
										>
											<i className="fticon-message"></i>
										</div>
									</div>
								)}
							</div>
							<InvitadosHeader
								categorias={eventData.estadisticas.asistentes.info}
								filterStatus={null}
								noClick
							/>
						</Col>
						<Col md="6" className="grid-50 first-col">
							<Row className="mb-4">
								<Col>
									<h3>Invitaciones</h3>
									<Row className="mb-4">
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<img alt="Invitaciones pendientes" src={InviPend} />
												</div>
												<div className="invitaciones">
													<span className="pend">
														{
															eventData.estadisticas.invitaciones
																.pendientesEnvio
														}
													</span>
												</div>
												<div>Pendientes de envío</div>
											</WhiteBox>
										</Col>
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<img alt="Invitaciones enviadas" src={InviSent} />
												</div>
												<div className="invitaciones">
													<span className="sent">
														{eventData.estadisticas.invitaciones.enviadas}
													</span>
												</div>
												<div>Enviadas</div>
											</WhiteBox>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col className="mb-4">
									<div className="w-aforo">
										<div>
											<h3>Asignación de butacas</h3>
										</div>
										<div className="info">
											<span className="w-bullet">Libres</span>
											<span>{eventData.estadisticas.lugar.asientosLibres}</span>
											<span>/{eventData.estadisticas.lugar.asientosTotal}</span>
										</div>
									</div>
									<Row className="mb-4">
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<img alt="Asientos por asignar" src={SeatPend} />
												</div>
												<div className="numbers">
													<span className="pend">
														{eventData.estadisticas.lugar.asientosPorAsignar}
													</span>
													<span>
														{
															eventData.estadisticas.lugar
																.asistentesConfirmadosTotal
														}
													</span>
												</div>
												<div>Por asignar</div>
											</WhiteBox>
										</Col>
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<img alt="Asientos asignados" src={SeatSeat} />
												</div>
												<div className="numbers">
													<span className="sent">
														{eventData.estadisticas.lugar.asientosAsignados}
													</span>
													<span>
														{
															eventData.estadisticas.lugar
																.asistentesConfirmadosTotal
														}
													</span>
												</div>
												<div>Asignadas</div>
											</WhiteBox>
										</Col>
									</Row>
									<Row>
										{eventData.estadisticas.lugar.salas &&
											eventData.estadisticas.lugar.salas.map((sala, index) => (
												<Col md="12" className="mb-4" key={index}>
													<WhiteBox>
														<div className="sala-event">
															<div className="info">
																<i className="fticon-location"></i>
																<span>{sala.nombre}</span>
															</div>
															<div className="aforo">
																<div className="libre me-4">
																	<span className="tit w-bullet">Libres</span>
																	<div className="data">
																		<span className="disp">
																			{sala.asientosLibres}
																		</span>
																		<span className="tot">
																			/{sala.asientosTotales}
																		</span>
																	</div>
																</div>
																<div className="ocupado me-1">
																	<span className="tit w-bullet">
																		Asignadas
																	</span>
																	<div className="data">
																		<span className="disp">
																			{sala.asientosAsistentesOcupados}
																		</span>
																		<span className="tot">
																			/{sala.asientosTotales}
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</WhiteBox>
												</Col>
											))}
									</Row>
								</Col>
							</Row>
						</Col>
						<Col md="6" className="grid-50">
							<Row>
								<Col className="mb-4">
									<h3>Entradas</h3>
									<Row className="mb-4">
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<img
														alt="Entradas pendientes de envío"
														src={TicketPend}
													/>
												</div>
												<div className="numbers">
													<span className="pend">
														{eventData.estadisticas.entradas.pendientesEnvio}
													</span>
													<span>{eventData.estadisticas.entradas.total}</span>
												</div>
												<div>Pendientes de envío</div>
											</WhiteBox>
										</Col>
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<img alt="Entradas enviadas" src={TicketSent} />
												</div>
												<div className="numbers">
													<span className="sent">
														{eventData.estadisticas.entradas.enviadas}
													</span>
													<span>{eventData.estadisticas.entradas.total}</span>
												</div>
												<div>Enviadas</div>
											</WhiteBox>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col className="mb-4">
									<div className="w-aforo">
										<div>
											<h3>Asistencia</h3>
										</div>
										<div className="info">
											<span className="w-bullet">Libres</span>
											<span>
												{eventData.estadisticas.asistencia.asientosLibres}
											</span>
											<span></span>
										</div>
									</div>
									<Row className="mb-4">
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<i className="fticon-user tx-redlight"></i>
												</div>
												<div className="numbers">
													<span className="pend">
														{
															eventData.estadisticas.asistencia
																.asientosPorEntrar
														}
													</span>
													<span>
														{eventData.estadisticas.asistencia.asientosTotal}
													</span>
												</div>
												<div>Por entrar</div>
											</WhiteBox>
										</Col>
										<Col>
											<WhiteBox containerClass="big-icon">
												<div>
													<i className="fticon-user tx-greenlight"></i>
												</div>
												<div className="numbers">
													<span className="sent">
														{eventData.estadisticas.asistencia.asientosDentro}
													</span>
													<span>
														{eventData.estadisticas.asistencia.asientosTotal}
													</span>
												</div>
												<div>Dentro</div>
											</WhiteBox>
										</Col>
									</Row>
									<Row>
										{eventData.estadisticas.asistencia.salas &&
											eventData.estadisticas.asistencia.salas.map(
												(sala, index) => (
													<Col md="12" className="mb-4" key={index}>
														<WhiteBox>
															<div className="sala-event">
																<div className="info">
																	<i className="fticon-location"></i>
																	<span>{sala.nombre}</span>
																</div>
																<div className="aforo">
																	<div className="libre me-4">
																		<span className="tit w-bullet">Libres</span>
																		<div className="data">
																			<span className="disp">
																				{sala.asientosLibres}
																			</span>
																		</div>
																	</div>

																	<div className="toenter me-4">
																		<div className="tit">
																			<i className="fticon-user tx-redlight"></i>
																			<span>Por entrar</span>
																		</div>
																		<div className="data">
																			<span className="disp">
																				{sala.asientosPorEntrar}
																			</span>
																			<span className="tot">
																				/{sala.asientosTotal}
																			</span>
																		</div>
																	</div>
																	<div className="inside me-1">
																		<div className="tit">
																			<i className="fticon-user tx-greenlight"></i>
																			<span>Dentro</span>
																		</div>
																		<div className="data">
																			<span className="disp">
																				{sala.asientosDentro}
																			</span>
																			<span className="tot">
																				/{sala.asientosTotal}
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</WhiteBox>
													</Col>
												)
											)}
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					{modalBarOpen && (
						<EventsForm
							modalBarOpen={modalBarOpen}
							toggleModalBar={toggleModalBar}
							modalData={eventData}
							setModalData={setEventData}
							handleSubmitForm={handleSubmitForm}
							isEditing
						/>
					)}
				</div>
			)}
		</>
	);
};

export default EventDashboard;
