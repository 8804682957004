import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

//services
import * as auditApi from 'api/auditApi';

//Components
import InfiniteScroll from 'react-infinite-scroller';
import PageHeader from 'components/common/elems/PageHeader';
import WhiteBox from 'components/common/elems/WhiteBox';
import Sticker from 'components/common/elems/Sticker';
import TableLoader from 'components/loaders/TableLoader';
import AdminAuditsFilter from './AdminAuditsFilter';
import AdminAuditsForm from './AdminAuditsForm';

//helpers
import { parseDateTimeFromString } from 'utils/helpers';

const AdminAuditsPage = () => {
	const [modalBarOpen, setModalBarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentFilter, setCurrentFilter] = useState({ pagina: 1 });

	const [modalData, setModalData] = useState({});
	const [modalEdit, setModalEdit] = useState(false);

	const [auditsData, setAuditsData] = useState([]);
	const [totalAudits, setTotalAudits] = useState(0);
	const [levelList, setLevelList] = useState();
	const [userList, setUserList] = useState();

	useEffect(() => {
		getLevels();
		getUsers();
		getAudits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLevels = async () => {
		const data = await auditApi.getLevels();
		setLevelList(data.data);
	};

	const getUsers = async () => {
		const data = await auditApi.getUsers();
		setUserList(data.data);
	};

	const getAudits = async (filters = currentFilter) => {
		setIsLoading(true);
		const data = await auditApi.getAudits({ ...filters, pagina: 1 });
		setAuditsData(data.data.logs);
		setTotalAudits(data.data.total);
		setCurrentFilter({ ...filters, pagina: 1 });
		setIsLoading(false);
	};

	//---------------------------------

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const loadMoreLogs = async (newPage) => {
		if (
			currentFilter &&
			currentFilter.pagina &&
			newPage > currentFilter.pagina &&
			!isLoadingMore
		) {
			setIsLoadingMore(true);
			const data = await auditApi.getAudits({
				...currentFilter,
				pagina: newPage,
			});
			if (data && data.succeeded) {
				setAuditsData((prevList) => [...prevList, ...data.data.logs]);
				setCurrentFilter({ ...currentFilter, pagina: newPage });
				setIsLoadingMore(false);
			}
		}
	};

	const totalPages = Math.ceil((totalAudits || 0) / 50);
	const currentPage = currentFilter?.pagina || 1;

	//---------------------------------

	const handleSubmitFilter = (formValues) => {
		getAudits(formValues);
	};

	const toggleModalBar = () => {
		setModalBarOpen(!modalBarOpen);
	};

	const showAudit = (data, edit) => {
		setModalData(data);
		setModalEdit(edit);
		setModalBarOpen(true);
	};

	const defModelForm = {
		message: '',
		messageTemplate: '',
		level: null,
		timestamp: null,
		exception: null,
		logevent: '',
		userid: null,
	};

	return (
		<>
			<div className="audits-page">
				<PageHeader className="mb-4 mt-1" title={'Auditoría'}></PageHeader>
				<Row>
					<Col lg="12">
						<WhiteBox containerClass="audit-list">
							<Row className="pb-3">
								<Col md="12">
									<AdminAuditsFilter
										levelList={levelList}
										userList={userList}
										submitFilter={handleSubmitFilter}
									/>
								</Col>
							</Row>

							<Row className="table-header pb-3">
								<Col md="6">
									<div className="spacer-medal"></div>
									<div>Acción</div>
								</Col>
								<Col md="2" className="text-center">
									Tipo
								</Col>
								<Col md="2" className="text-left">
									Fecha
								</Col>
								<Col md="2" className="text-left">
									Usuario
								</Col>
							</Row>

							{isLoading ? (
								<TableLoader />
							) : (
								<Col lg="12">
									{auditsData && auditsData.length > 0 ? (
										<InfiniteScroll
											pageStart={0}
											loadMore={() => loadMoreLogs(currentPage + 1)}
											hasMore={currentPage < totalPages}
											loader={
												<div key={0} style={{ clear: 'both' }}>
													<TableLoader size="xs" />
												</div>
											}
										>
											{auditsData.map((audit, index) => {
												return (
													<Row
														key={index}
														className="table-row"
														onClick={() => showAudit(audit, false)}
													>
														<Col md="6" className="col-name">
															<div className="name">{audit.message}</div>
														</Col>
														<Col md="2" className="text-center">
															<Sticker
																key={0}
																text={audit.level}
																color={
																	audit.level === 'Error' ? 'red' : 'yellow'
																}
															/>
														</Col>

														<Col md="2">
															<div className="date">
																{parseDateTimeFromString(
																	audit.timeStamp,
																	'dd-MM-yyyy HH:mm'
																)}
															</div>
														</Col>
														<Col md="2">{audit.user}</Col>
													</Row>
												);
											})}
										</InfiniteScroll>
									) : (
										<Row>
											<Col className="text-center py-5">Sin resultados</Col>
										</Row>
									)}
								</Col>
							)}
						</WhiteBox>
					</Col>
				</Row>

				{modalBarOpen && (
					<AdminAuditsForm
						modalBarOpen={modalBarOpen}
						toggleModalBar={toggleModalBar}
						modalEdit={modalEdit}
						modalData={modalData}
						defModelForm={defModelForm}
					/>
				)}
			</div>
		</>
	);
};

export default AdminAuditsPage;
