import React, { useRef } from 'react';

import {
	Col,
	Row,
	Button,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';
import WhiteBox from 'components/common/elems/WhiteBox';
import Medal from 'components/common/elems/Medal';
import Sticker from 'components/common/elems/Sticker';

//forms
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const AdminUsersForm = ({
	modalBarOpen,
	toggleModalBar,
	modalEdit,
	modalData,
	defModelForm,
	handleSubmitForm,
	toggleModalEdit,
	rolList,
}) => {
	const formikRef = useRef();
	const MySwal = withReactContent(Swal);

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required(),
		apellidos: Yup.string().required(),
		login: Yup.string().required(),	
	});

	const closeModalMenu = () => {
		if (formikRef.current && formikRef.current.dirty) {
			MySwal.fire({
				title: 'Cambios sin guardar',
				text: 'Si cierras la ventana se perderán los cambios.',
				cancelButtonText: 'Cancelar',
				showCancelButton: true,
				confirmButtonText: 'Cerrar',
				focusConfirm: false,
				focusCancel: false,
				reverseButtons: true,
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn-prim-dark btn',
					cancelButton: 'btn-prim-light btn',
				},
			}).then((result) => {
				if (result && result.isConfirmed) toggleModalBar();
			});
		} else {
			toggleModalBar();
		}
	};

	return (
		<>
			<LateralModal
				title={
					modalData && modalData.id
						? `${modalData.nombre} ${modalData.apellidos ? modalData.apellidos : ''
						}`
						: 'Nuevo usuario'
				}
				isOpen={modalBarOpen}
				toggleModal={closeModalMenu}
			>
				{modalEdit && modalData ? (
					<>
						<Formik
							innerRef={formikRef}
							enableReinitialize
							initialValues={modalData && modalData.id ? modalData : defModelForm}
							validationSchema={validationSchema}
							validateOnChange={false}
							validateOnBlur={false}
							onSubmit={(values, actions) => handleSubmitForm(values, actions)}
						>
							{({ values, setFieldValue, isSubmitting, resetForm }) => {
								return (
									<>
										<Form autoComplete="off">
											<ModalBody>
												<div className="user-edit">
													<div className="user-form">
														<Row>
															<Col md="12">
																<Field
																	label="Nombre*"
																	placeholder="Nombre*"
																	name="nombre"
																	component={InputForm}
																/>
															</Col>
															<Col md="12">
																<Field
																	label="Apellidos*"
																	placeholder="Apellidos*"
																	name="apellidos"
																	component={InputForm}
																/>
															</Col>
															<Col md="12">
																<Field
																	label="Email*"
																	placeholder="Email*"
																	name="login"
																	component={InputForm}
																/>
															</Col>
															<Col md="12">																
																<Field
																	label="Rol*"
																	placeholder="Rol*"
																	name="rol"
																	options={rolList}
																	component={InputSelect}
																	findByValue
																/>
															</Col>
														</Row>
													</div>
												</div>
											</ModalBody>
											<ModalFooter>
												<Button
													type="submit"
													onClick={() => setFieldValue('reload', false)}
													className={
														modalData.id ? 'btn-sec-dark' : 'btn-sec-light'
													}
													disabled={isSubmitting}
												>
													Guardar
												</Button>
												{!modalData.id && (
													<Button
														type="submit"
														onClick={() => setFieldValue('reload', true)}
														className="btn-sec-dark"
														disabled={isSubmitting}
													>
														Guardar y nuevo
													</Button>
												)}
											</ModalFooter>
										</Form>
									</>
								);
							}}
						</Formik>
					</>
				) : (
					<>
						<ModalBody>
							<div className="user-read">
								<WhiteBox>
									<Medal
										text={`${modalData.nombre} ${modalData.apellidos ? modalData.apellidos : ''
											}`}
										color={
											modalData && modalData.color
												? modalData.color.claseCss
												: 'blue'
										}
									/>
									<h3>{`${modalData.nombre} ${modalData.apellidos ? modalData.apellidos : ''
										}`}</h3>
									<div className="user-details">
										{modalData.telefono && <p>{modalData.telefono}</p>}
										{modalData.emails &&
											modalData.emails.length > 0 &&
											modalData.emails.map((eml, index) => (
												<p key={index}>{eml.mail}</p>
											))}
									</div>
									<div className="user-companydetails">
										{modalData.login && <p>{modalData.login}</p>}
									</div>
									{modalData.rol && <p>{modalData.rol.nombre}</p>}
									{modalData.rol &&
										modalData.rol.rolModulos && modalData.rol.rolModulos.length > 0
										&& modalData.rol.rolModulos.map((rolModulo, index) => (
											<Sticker
												key={index}
												text={rolModulo.modulo.nombre}
												color={rolModulo.escritura ? "green" : "blue"}
												title={rolModulo.escritura ? "escritura/lectura" : "lectura"}
											/>
										)
									)}	
								</WhiteBox>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								className="btn-sec-dark"
								onClick={() => toggleModalEdit(true)}
							>
								Editar
							</Button>
						</ModalFooter>
					</>
				)}
			</LateralModal>
		</>
	);
};

export default AdminUsersForm;
