import { callApiGet, callApiPost } from './apiUtils';
const url_base = '/auditoria';

export const getAudits = async (filters) => {
	return await callApiPost(`${url_base}/getAudits`, filters);
};

export const getLevels = async () => {
	return await callApiGet(`${url_base}/getLevels`);
};

export const getUsers = async () => {
	return await callApiGet(`${url_base}/getUsers`);
};

