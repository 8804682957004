import { callApiPost, callApiGet } from "./apiUtils";
const url_base = "/usuarios";

export const getUsers = async (filters) => {
	return await callApiPost(`${url_base}/getUsers`, filters);
};

export const getRoles = async () => {
	return await callApiGet(`${url_base}/getRoles`);
};

export const getModules = async () => {
	return await callApiGet(`${url_base}/getModules`);
};

export const deleteUser = async (userID) => {
	return await callApiPost(`${url_base}/deleteUser/${userID}`, userID);
};

export const setUser = async (userData) => {
	return await callApiPost(`${url_base}/setUser`, userData);
};

