import React, { useRef } from 'react';

import {
	ModalBody,
	ModalFooter,
} from 'reactstrap';

//componentes
import LateralModal from 'components/common/modals/LateralModal';
import WhiteBox from 'components/common/elems/WhiteBox';
import Sticker from 'components/common/elems/Sticker';

//forms
import { Formik, Form } from 'formik';

//helpers
import { parseDateTimeFromString } from 'utils/helpers';

const AdminAuditsForm = ({
	modalBarOpen,
	toggleModalBar,
	modalEdit,
	modalData,
	defModelForm,
}) => {
	const formikRef = useRef();

	const closeModalMenu = () => {
		toggleModalBar();
	};

	return (
		<>
			<LateralModal
				size='xl'
				title= 'Detalle del log'
				isOpen={modalBarOpen}
				toggleModal={closeModalMenu}
			>
				{modalEdit && modalData ? (
					<>
						<Formik
							innerRef={formikRef}
							enableReinitialize
							initialValues={
								modalData  ? modalData : defModelForm
							}
							
							validateOnChange={false}
							validateOnBlur={false}
						>
							{({ values, setFieldValue, isSubmitting, resetForm }) => {
								return (
									<>
										<Form>											
										</Form>
									</>
								);
							}}
						</Formik>
					</>
				) : (
					<>
						<ModalBody>
							<div className="audit-read">
									<WhiteBox>
										<div className="head">
											<h3>{modalData.message}</h3>
											<Sticker
												key={0}
												text={modalData.level}
												color={modalData.level == "Error" ? "red" : "yellow"}
												/>
										</div>
										<div className="audit-details">
											<div className="date">
												<span><strong>Fecha: </strong>{parseDateTimeFromString(
													modalData.timeStamp,
													'dd-MM-yyyy HH:mm'
												)}</span>
											</div>
										</div>
										{modalData.userid && (
											<div className="audit-details">
												<p><strong>Usuario: </strong>
													{modalData.userid}</p>
											</div>
										)}
										{modalData.exception && (
											<div className="audit-details">
												<p><strong>Excepción</strong></p>
												{modalData.exception && <p>{modalData.exception}</p>}
											</div>
										)}
										<div className="audit-details">
											<p><strong>Log evento</strong></p>
											{modalData.logEvent && <p>{modalData.logEvent}</p>}
										</div>
								</WhiteBox>
							</div>
						</ModalBody>
						<ModalFooter>							
						</ModalFooter>
					</>
				)}
			</LateralModal>
		</>
	);
};

export default AdminAuditsForm;
