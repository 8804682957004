import React from 'react';
import _ from 'lodash';
import TimeField from 'react-simple-timefield';

import { FormGroup, Label } from 'reactstrap';

const InputTime = ({
	label,
	baseLabel,
	noSpace,
	reverse,
	disabled,
	//Formik
	field,
	form,
	innerRef,
	//Others
	classContainer = '',
	isClearable,
	extraClearFunction,
	...props
}) => {
	const error = form && form.errors && _.get(form.errors, field.name);
	const isDisabled = disabled ? true : false;

	return (
		<div
			className={`input-type-time ${classContainer}${
				isClearable && field.value ? ' input-group' : ''
			}${isDisabled ? ' disabled' : ''}`}
		>
			<FormGroup
				className={`${
					!baseLabel ? 'form-floating flex-grow-1' : 'form-group'
				} ${error && typeof error === 'string' ? ' has-error' : ''}`}
			>
				<TimeField
					value={field && field.value ? field.value : '--:--'}
					onChange={(event, value) => {
						form.setFieldValue(field.name, value);
						form.setFieldTouched(field.name, true);
					}}
					input={
						<input
							className="form-control input-timefield"
							type="text"
							placeholder={label}
						/>
					}
					colon=":"
					disabled={disabled}
					id={`input-time-${field.name}`}
				/>
				<Label htmlFor={`input-time-${field.name}`}>{label}</Label>
				<span className="input-time-icon"></span>
			</FormGroup>
			{isClearable && field.value && (
				<span
					className="input-group-text clear-input mb-3"
					onClick={() => {
						form.setFieldValue(field.name, '');
						extraClearFunction && extraClearFunction();
					}}
				>
					<i className="fticon-close"></i>
				</span>
			)}
		</div>
	);
};

export default InputTime;
