import React from 'react';
import { Row, Col } from 'reactstrap';

//Components
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';
import InputDate from 'components/common/forms/InputDate';

//helpers
import { EVENT_TYPES } from 'utils/helpers';

//forms
import { Formik, Form, Field } from 'formik';

const EventsFilter = ({ filterType = 'proximos', sitesList=[], submitFilter }) => {
	const initialValues = {
		nombre: '',
      fechaEvento: null,
      lugarId: null,
      eventType: '',
		esAnunciante: null
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={submitFilter}
		>
			{({ handleSubmit, setFieldValue, values }) => (
				<>
					<Form
						autoComplete="off"
						onKeyDown={(e) =>  (e.key === 'Enter') && handleSubmit()}
					>
						<div className="event-filters">
                  <Row>
									<Col md="4">
										<Field
											label="Nombre del evento"
											placeholder="Nombre del evento"
											name="nombre"
											component={InputForm}
											isClearable
											extraClearFunction={handleSubmit}
										/>
									</Col>
									<Col md="2">
										<Field
											label="Fecha del evento"
											placeholder="Fecha del evento"
											name="fechaEvento"
                                 type="date"
											component={InputDate}
											isClearable
											extraFnChange={handleSubmit}
										/>
									</Col>
                           <Col md="2">
										<Field
											label="Lugar del evento"
											placeholder="Lugar del evento"
											name="lugarId"
											options={sitesList}
											component={InputSelect}
											isClearable
											onChange={(e) => {
												setFieldValue('lugarId', e ? parseInt(e.value) : null);
												handleSubmit();
											}}
											canSearch
										/>
									</Col>
                           <Col md="2">
										<Field
											label="Tipo de evento"
											placeholder="Tipo de evento"
											name="eventType"
											options={EVENT_TYPES}
											component={InputSelect}
                                 isClearable
											onChange={(e) => {
												setFieldValue('esAnunciante', e ? parseInt(e.value) : null);
												setFieldValue('eventType', e ? e.value : '');
												handleSubmit();
											}}
											
										/>
									</Col>
								</Row>
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default EventsFilter;
