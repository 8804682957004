import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Row,
	Col,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';

//forms

//helpers


const MapToolBar = ({
	newAreaFn,
	checkMapDuplicatesFn,
	selectedSection,
	selectedSectionFn,
	areasList,
	sectionsList,
	mapData,
	capacityMap,
	currentZoom = 100,
	updateCurrentZoom,
	cursorHand,
	toggleCursor,
	seatsMenuOpen,
	seatsMenuFn,
	sectionsMenuOpen,
	sectionsMenuFn,
}) => {
	const history = useHistory();
	const sectionsRef = useRef();

	const toggleSectionsMenu = (e) => {
		if (
			sectionsMenuOpen &&
			(e.target === sectionsRef.current ||
				!sectionsRef.current.contains(e.target))
		)
			return;
		sectionsMenuFn();
	};

	const toggleSeatsMenu = () => {
		seatsMenuFn();
	};

	//--------------------

	const backButton = () => {
		history.goBack();
	};

	const makeZoomIn = () => {
		if (currentZoom < 200) updateCurrentZoom(currentZoom + 25);
	};

	const makeZoomOut = () => {
		if (currentZoom > 50) updateCurrentZoom(currentZoom - 25);
	};

	//--------------------

	const addNewArea = (areaType) => {
		newAreaFn(areaType);
		toggleSeatsMenu();
	};

	const checkMapDuplicates = () => {
		checkMapDuplicatesFn();
		toggleSeatsMenu();
	}

	const selectSection = (sectionInfo) => {
		const dataUpdate =
			selectedSection.type === sectionInfo.type ? { type: null } : sectionInfo;
		selectedSectionFn(dataUpdate);
	};

	//--------------------


	return (
		<div className="map-toolbar">
			<Row>
				<Col md="1">
					<div className="boxed">
						<Button
							className={`btn-inmap btn-map btn-icon ${
								capacityMap.totalSeats === 0 ? 'w-cto' : ''
							}`}
							onClick={toggleSeatsMenu}
						>
							<i className="fticon-edit"></i>
						</Button>
					</div>
					<div className={`seat-options ${seatsMenuOpen ? 'show' : ''}`}>
						<div className="drop-content">
							<h3>Editar mapa</h3>
							<ul>
								{areasList &&
									areasList.map((areaTp, index) => (
										<li key={index} onClick={() => addNewArea(areaTp)}>
											<div className="circle">
												<i className={areaTp.icon}></i>
											</div>
											{areaTp.label}
										</li>
									))}
								<li onClick={() => checkMapDuplicates()}>
									<div className="circle">
										<i className="fticon-move"></i>
									</div>
									Comprobar asientos duplicados
								</li>
							</ul>
						</div>
					</div>
				</Col>
				<Col md="10">
					<div className="actions">
						<div className="back boxed">
							<Button
								className="btn-inmap btn-map btn-icon"
								onClick={backButton}
							>
								<i className="fticon-arrow-left"></i>
							</Button>
						</div>
						<div className="info boxed">
							<div className="room">
								<i className="fticon-location"></i>
								<span>{mapData && mapData.label}</span>
							</div>
							<div className="seats">
								<div>Butacas</div>
								<div>{capacityMap.totalSeats}</div>
							</div>
						</div>
						<div className="controls boxed">
							<Button
								className={`btn-inmap btn-icon btn-pointer ${
									!cursorHand ? 'active' : ''
								}`}
								onClick={toggleCursor}
							>
								<i className="fticon-pointer"></i>
							</Button>
							<Button
								className={`btn-inmap btn-icon ${cursorHand ? 'active' : ''}`}
								onClick={toggleCursor}
							>
								<i className="fticon-hand"></i>
							</Button>
							<div className="zoom">
								<Button className="btn-inmap btn-icon" onClick={makeZoomOut}>
									<i className="fticon-minus"></i>
								</Button>
								<div className="percent">{currentZoom}%</div>
								<Button
									className="btn-inmap btn-plus btn-icon"
									onClick={makeZoomIn}
								>
									<i className="fticon-plus"></i>
								</Button>
							</div>
						</div>
					</div>
				</Col>
				<Col md="1">
					<div className="settings" ref={sectionsRef}>
						<Dropdown isOpen={sectionsMenuOpen} toggle={toggleSectionsMenu}>
							<DropdownToggle data-toggle="dropdown" tag="div">
								<div className="boxed">
									<Button
										className={`btn-inmap btn-map btn-icon ${
											selectedSection.type || selectedSection.value
												? 'w-cto'
												: ''
										}  ${sectionsMenuOpen ? 'active' : ''}`}
									>
										<i className="fticon-setting"></i>
									</Button>
								</div>
							</DropdownToggle>
							<DropdownMenu>
								<div className="drop-content">
									<h3>Secciones</h3>
									<ul
										className={
											selectedSection.type ? 'w-selection' : 'n-selection'
										}
									>
										<li
											onClick={() => selectSection({ type: 'seat' })}
											className={`${
												selectedSection.type === 'seat' ? 'active' : ''
											}`}
										>
											<div className="circle"></div>
											<div className="content">
												<span>Butacas</span>
												<span>{capacityMap.seats}</span>
											</div>
										</li>
										<li
											onClick={() => selectSection({ type: 'space' })}
											className={`${
												selectedSection.type === 'space' ? 'active' : ''
											}`}
										>
											<div className="circle space"></div>
											<div className="content">
												<span>Espacio vacío</span>
												<span>{capacityMap.empty}</span>
											</div>
										</li>
										{sectionsList &&
											sectionsList.map((sectionTp, index) => (
												<li
													key={index}
													onClick={() => selectSection(sectionTp)}
													className={`${
														selectedSection.type === sectionTp.type
															? 'active'
															: ''
													}`}
												>
													<div
														className={`circle ${
															sectionTp.type === 'handicap' ? 'blue' : 'nulo'
														}`}
													>
														{sectionTp.icon && (
															<i className={sectionTp.icon}></i>
														)}
													</div>
													<div className="content">
														<span>{sectionTp.label}</span>
														<span>
															{capacityMap[sectionTp.type]
																? capacityMap[sectionTp.type]
																: 0}
														</span>
													</div>
												</li>
											))}
									</ul>
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default MapToolBar;
