import React, {useEffect} from 'react';
import _ from 'lodash';

//Controls
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

//helpers
import { classCssToColor } from 'utils/helpers';

const {
	ValueContainer,
	Placeholder,
	MultiValueRemove,
} = components;

const CustomValueContainer = ({ children, ...props }) => {
	return (
		<ValueContainer {...props}>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			{React.Children.map(children, (child) =>
				child && child.type !== Placeholder ? child : null
			)}
		</ValueContainer>
	);
};

const CustomMultiValueContainer = ({ children, getValue, ...props }) => {
	var valueLength = getValue().length;
	var optionsLength = props.selectProps.options.length;
	valueLength = optionsLength === valueLength ? 'All' : valueLength;

	return (
		<ValueContainer {...props}>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			{!props.selectProps.inputValue &&
				`${valueLength} seleccionado${valueLength !== 1 ? 's' : ''}`}
			{React.Children.map(children, (child) => {
				return child.type === components.Input ? child : null;
			})}
		</ValueContainer>
	);
};

const CustomMultiRemove = (props) => {
	const icono = props.data.label === "productora" && props.selectProps.esProductora ? "no-remove" : "fticon-close"
	const propiedades =  props.data.label === "productora" && props.selectProps.esProductora ? "" : {...props}
		return (
			<MultiValueRemove {...propiedades}>
				<i className={icono}></i>
			</MultiValueRemove>
		);
};

const customStyles = {
	container: (provided, state) => ({
		...provided,
	}),

	valueContainer: (provided, state) => ({
		...provided,
		overflow: 'visible',
		paddingTop: (state.hasValue || state.selectProps.inputValue) && 14,
	}),

	placeholder: (provided, state) => ({
		...provided,
		position: 'absolute',
		top: state.hasValue || state.selectProps.inputValue ? -14 : 0,
		transition: 'top 0.1s, font-size 0.1s',
		fontSize: (state.hasValue || state.selectProps.inputValue) && 11,
	}),

	multiValue: (provided, state) => ({
		...provided,
		border: `1px solid ${classCssToColor(
			state.data.color
				? state.data.color.claseCss
				: state.data.etiqueta
				? state.data.etiqueta.color.claseCss
				: 'gray'
		)}`,
	}),

	multiValueLabel: (provided, state) => ({
		...provided,
		color: `${classCssToColor(
			state.data.color
				? state.data.color.claseCss
				: state.data.etiqueta
				? state.data.etiqueta.color.claseCss
				: 'gray'
		)}`,
	}),

	multiValueRemove: (provided, state) => ({
		...provided,
		color: `${classCssToColor(
			state.data.color
				? state.data.color.claseCss
				: state.data.etiqueta
				? state.data.etiqueta.color.claseCss
				: 'gray'
		)}`,
	}),
};

const InputSticker = ({
	label,
	options,
	//Custom
	noForm,
	esProductora,
	//Fields
	help,
	createLabel,
	//Formik
	field,
	form,
	...props
}) => {
	useEffect(() => {
		if(esProductora){
			const prodIsList = _.reject(field.value, {etiqueta:{label:'productora'}  });
			const prod = [ _.find(options, {label: 'productora' })];
			form.setFieldValue(field.name, _.concat(prod, prodIsList));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const onChange = (e) => {
		if (noForm) {
			props.onChange(e);
		}	
		else {
			form.setFieldValue(field.name, e);
			form.setFieldTouched(field.name, true);
		}
	};

	const error = field ? _.get(form.errors, field.name) : null;

	const loadComponents = {
		ValueContainer: CustomValueContainer,
		MultiValueRemove: CustomMultiRemove,
	};

	return (
		<>
			<CreatableSelect
				classNamePrefix={`${error ? 'select-has-error' : ''} input-select`}
				noOptionsMessage={() => help || 'No hay opciones'}
				formatCreateLabel={(inputValue) =>
					`${createLabel || 'Crear etiqueta'}: ${inputValue}`
				}
				value={noForm ? props.value : field.value}
				options={options}
				onChange={onChange}
				isClearable={false}
				isMulti={true}
				components={loadComponents}
				styles={customStyles}
				isDisabled={props.disabled}
				getOptionLabel={(option) =>
					option.etiqueta ? option.etiqueta.label : option.label
				}
				getOptionValue={(option) =>
					option.etiqueta ? option.etiqueta.value : option.value
				}
				esProductora={esProductora}
				{...props}
			/>
		</>
	);
};

InputSticker.propTypes = {};
export default InputSticker;
