import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

//Componentes
import { Container, Row, Col } from 'reactstrap';

//components
import LoadingLayer from 'components/loaders/LoadingLayer';
import EventDashboard from './dashboard/EventDashboard';
import EventDBInvitados from './dashboard/EventDBInvitados';
import EventDBInvitaciones from './dashboard/EventDBInvitaciones';
import EventDBPlanos from './dashboard/EventDBPlanos';
import EventDBEntradas from './dashboard/EventDBEntradas';
import EventDBNotificaciones from './dashboard/EventDBNotificaciones';
import NotFoundPage from '../error/NotFoundPage';

//services
import * as eventsApi from 'api/eventsApi';

const EventPage = () => {
	let match = useRouteMatch('/evento/:idevent/:idsection?');
	const eventID = match.params ? match.params.idevent : null;
	const sectionPath = match.params ? match.params.idsection : null;

	const [pageLoading, setPageLoading] = useState(false);
	const [eventDataSimple, setEventDataSimple] = useState(null);

	useEffect(() => {
		getEventDataSimple(eventID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//dashboard data
	const getEventDataSimple = async (evID) => {
		setPageLoading(true);
		const data = await eventsApi.getEventSimple(evID);
		setEventDataSimple(data.data);
		setPageLoading(false);
	};

	return (
		<>
			{pageLoading && <LoadingLayer visible={pageLoading} />}

			{eventDataSimple &&
				(eventDataSimple.existeEvento ? (
					sectionPath &&
					!pageLoading && (
						<>
							{sectionPath === 'dashboard' ? (
								<Container fluid className="main-container bg-gray">
									<Row>
										<Col lg="12 py-4">
											<EventDashboard
												eventID={eventID}
												setDashboardLoading={setPageLoading}
											/>
										</Col>
									</Row>
								</Container>
							) : sectionPath === 'planos' ? (
									<EventDBPlanos
										eventID={eventID}
										eventDataSimple={eventDataSimple}
									/>
							) : (
								<div className="event-container bg-gray">
									<Row>
										<Col lg="12">
											{sectionPath === 'invitados' && (
												<EventDBInvitados eventID={eventID} eventDataSimple={eventDataSimple} />
											)}

											{sectionPath === 'invitaciones' && (
												<EventDBInvitaciones eventDataSimple={eventDataSimple} />
											)}

											{sectionPath === 'notificaciones' && (
												<EventDBNotificaciones eventDataSimple={eventDataSimple} />
											)}
												
											{sectionPath === 'entradas' && (
												<EventDBEntradas eventDataSimple={eventDataSimple} />
											)}
										</Col>
									</Row>
								</div>
							)}
						</>
					)
				) : (
					<NotFoundPage />
				))}
		</>
	);
};

export default EventPage;
