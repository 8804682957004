import React from 'react';

//components
import WhiteBox from 'components/common/elems/WhiteBox';

const EntradaSentHeader = ({
	categorias,
	noClick,
	filterStatus = "invitados",
	handleStatusFilter,
}) => {
	return (
		<div
			className={`filter-scroll ${
				filterStatus ? 'filtered' : ''
			}`}
		>
			{categorias &&
				categorias.length > 0 &&
				categorias.map((categoria, ixCat) => (
					<div className="filter-box" key={ixCat}>
                  <WhiteBox
								onClick={() =>
								handleStatusFilter && handleStatusFilter(categoria.css)
							}
							containerClass={`${
								filterStatus === categoria.css ? 'filter-active' : ''
							}`}
							>
								<div className="filter-card">
									<div className="status">{categoria.titulo}</div>
									<div className={`badge ft-${categoria.css}`}>
											{categoria?.count || 0}
										</div>
								</div>
							</WhiteBox>
					</div>
				))}
		</div>
	);
};

export default EntradaSentHeader;
