import React from 'react';
import { Row, Col } from 'reactstrap';

//Components
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';

//forms
import { Formik, Form, Field } from 'formik';

const MapsFilter = ({ tagList, submitFilter }) => {
	const initialValues = {
		nombreMapa: '',
		etiquetaId: [],
		etiqueta: {},
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={submitFilter}
		>
			{({ handleSubmit, setFieldValue }) => (
				<>
					<Form
						autoComplete="off"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmit();
							}
						}}
					>
						<div className="audit-filters">
							<Row>
								<Col md="3">
									<Field
										label="Nombre del mapa"
										placeholder="nombre del mapa"
										name="nombreMapa"
										component={InputForm}
										isClearable
										extraClearFunction={handleSubmit}
									/>
								</Col>
								<Col md="3">
									<Field
										label="Etiqueta"
										placeholder="Etiqueta"
										name="etiqueta"
										options={tagList}
										component={InputSelect}
										onChange={(e) => {
											setFieldValue('etiquetaId', e);
											handleSubmit();
										}}
										isClearable
										isMulti
										selectables
										grouped
										canSearch
									/>
								</Col>
							</Row>							
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default MapsFilter;
