import React from 'react';
import { Col, Row } from 'reactstrap';

//images
import { spanishDate } from 'utils/helpers';
import Image from '../elems/Image';

const NotificationMailPreview = ({ notificationEvent, notificationData }) => {
	let replaceTextoLegal =
		notificationData && notificationData.firmaTextoLegal
			? notificationData.firmaTextoLegal.replace('[br]', '')
			: '';
	replaceTextoLegal = replaceTextoLegal.replace('[UNSUSCRIBE_PAGE]', 'aquí');

	return (
		<div className="preview-container">
			<div className="preview-mail">
				<Row className="header-preview">
					<Col md="8">
						<div className="tit">
							<p>{notificationData?.titulo || '[Titulo]'}</p>
						</div>
					</Col>
					<Col md="4" className="text-end">
						<Image
							src={notificationData?.companyLogo?.logoHead}
							onErrorImageWidth={38}
							alt="logo header"
							className=""
						/>
					</Col>
				</Row>
				<Row className="body-preview my-5">
					<Col md="12">
						<h2>{notificationData?.textoPrincipal || '[Texto principal]'}</h2>
						{notificationData?.textoAdicional && (
							<p>{notificationData?.textoAdicional}</p>
						)}
					</Col>
					<Col md="12">
						<div className="mt-3">
							{notificationData?.base64 || notificationData?.urlImagen ? (
								<Image
									src={
										notificationData.base64
											? notificationData.base64
											: notificationData.urlImagen
											? notificationData.urlImagen
											: null
									}
									onErrorImageWidth={38}
									className="img-fluid"
								/>
							) : (
								<div className="imagen-vacia">
									<i className="fticon-image"></i>
								</div>
							)}
						</div>
					</Col>
				</Row>
				{notificationData?.incluirFechaLugarEvento && (
					<Row className="site-preview my-5">
						<Col md="6">
							<div className="grey-box">
								<div className="icon">
									<i className="fticon-calendar"></i>
								</div>
								<div className="info">
									<div className="tit">
										<p>{spanishDate(notificationEvent?.fechaEvento, true)}</p>
									</div>
									<div className="subtit">
										<p>{`${notificationEvent?.horaEvento} h`}</p>
									</div>
								</div>
							</div>
						</Col>
						<Col md="6">
							<div className="grey-box">
								<div className="icon">
									<i className="fticon-location"></i>
								</div>
								<div className="info">
									<div className="tit">{notificationEvent?.eventSite}</div>
									<div className="subtit">
										{notificationEvent?.direccionEvento}
									</div>
								</div>
							</div>
						</Col>
					</Row>
				)}
				<Row className="gest-preview mb-4">
					<Col md="12">
						{notificationData?.textoLimiteConfirm && (
							<p>{notificationData?.textoLimiteConfirm}</p>
						)}
					</Col>
				</Row>

				<Row className="sign-preview mt-5">
					<Col md="12">
						<div className="row-sign">
							<div className="logo-mds">
								<Image
									src={notificationData?.companyLogo?.logoHead}
									onErrorImageWidth={38}
									alt="logo footer"
									className=""
								/>
							</div>
							<div className="sign-text">
								<div className="rmt">{notificationData?.firmaCargo}</div>
								<div className="tel">{notificationData?.firmaTelefono}</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<div className="preview-legal">{replaceTextoLegal}</div>
		</div>
	);
};

export default NotificationMailPreview;
