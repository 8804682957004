import { callApiGet, callApiPost } from './apiUtils';
const url_base = '/contactos';

export const getTotalContacts = async () => {
	return await callApiGet(`${url_base}/totalContacts`);
};

export const getTags = async () => {
	return await callApiGet(`${url_base}/getTags`);
};

export const getCompanies = async () => {
	return await callApiGet(`${url_base}/getCompanies`);
};

export const getContacts = async (filters) => {
	return await callApiPost(`${url_base}/getContacts`, filters);
};

export const getProducers = async (filters) => {
	return await callApiPost(`${url_base}/getProducers`, filters);
};

export const setContact = async (contactData) => {
	return await callApiPost(`${url_base}/setContact`, contactData);
};

export const deleteContact = async (contactID) => {
	return await callApiPost(`${url_base}/deleteContact/${contactID}`,contactID);
};
