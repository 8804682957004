import React from 'react';

import { Button } from 'reactstrap';

const ButtonLoad = ({ isLoading, text, type = 'submit', className }) => {
	return (
		<Button className={className} type={type} disabled={isLoading}>
			{isLoading ? <div className="button-load"></div> : text}
		</Button>
	);
};

export default ButtonLoad;
