import React from 'react';
import { Button, Col, Row } from 'reactstrap';

//images
import { spanishDate } from 'utils/helpers';
import Image from '../elems/Image';

const InvitationMailPreview = ({
	invitationEvent,
	invitationData,
	previewEntrada = false,
}) => {
	let replaceTextoLegal =
		invitationData && invitationData.firmaTextoLegal
			? invitationData.firmaTextoLegal.replace('[br]', '\n\r')
			: '';
	replaceTextoLegal = replaceTextoLegal.replace('[UNSUSCRIBE_PAGE]', 'aquí');
	replaceTextoLegal = replaceTextoLegal.replace('[br]', '\n\r');

	return (
		<div className="preview-container">
			<div className="preview-mail">
				<Row className="header-preview">
					<Col md="8">
						<div className="tit">
							<i className="fticon-ticket"></i>
							<p>
								{`${previewEntrada ? 'Entrada' : 'Invitación'} ${
									invitationEvent?.nombreEvento
								}`}
							</p>
						</div>
					</Col>
					<Col md="4" className="text-end">
						<Image
							src={invitationData?.companyLogo?.logoHead}
							onErrorImageWidth={38}
							alt="logo header"
							className=""
						/>
					</Col>
				</Row>
				<Row className="body-preview my-5">
					<Col md="12">
						<h2>{invitationData?.textoPrincipal || '[TEXTO]'}</h2>
						{!previewEntrada && invitationData?.textoAdicional && (
							<p>{invitationData?.textoAdicional}</p>
						)}
					</Col>
					<Col md="12">
						<div className="mt-3">
							{invitationData?.base64 || invitationData?.urlImagen ? (
								<Image
									src={
										invitationData.base64
											? invitationData.base64
											: invitationData.urlImagen
											? invitationData.urlImagen
											: null
									}
									onErrorImageWidth={38}
									className="img-fluid"
								/>
							) : (
								<div className="imagen-vacia">
									<i className="fticon-image"></i>
								</div>
							)}
						</div>
					</Col>
				</Row>
				{!previewEntrada ? (
					invitationData?.incluirFechaLugarEvento && (
						<Row className="site-preview my-5">
							<Col md="6">
								<div className="grey-box">
									<div className="icon">
										<i className="fticon-calendar"></i>
									</div>
									<div className="info">
										<div className="tit">
											<p>{spanishDate(invitationEvent?.fechaEvento, true)}</p>
										</div>
										<div className="subtit">
											<p>{`${invitationEvent?.horaEvento} h`}</p>
										</div>
									</div>
								</div>
							</Col>
							<Col md="6">
								<div className="grey-box">
									<div className="icon">
										<i className="fticon-location"></i>
									</div>
									<div className="info">
										<div className="tit">{invitationEvent?.eventSite}</div>
										<div className="subtit">
											{invitationEvent?.direccionEvento}
										</div>
									</div>
								</div>
							</Col>
						</Row>
					)
				) : (
					<>
						<Row className="site-preview my-5">
							<Col md="6">
								<div className="grey-box">
									<div className="icon">
										<i className="fticon-calendar"></i>
									</div>
									<div className="info">
										<div className="tit">
											<p>{spanishDate(invitationEvent?.fechaEvento, true)}</p>
										</div>
										<div className="subtit">
											<p>{`${invitationEvent?.horaEvento} h`}</p>
										</div>
									</div>
								</div>
							</Col>
							<Col md="6">
								<div className="grey-box">
									<div className="icon">
										<i className="fticon-location"></i>
									</div>
									<div className="info">
										<div className="tit">{invitationEvent?.eventSite}</div>
										<div className="subtit">
											{invitationEvent?.direccionEvento}
										</div>
									</div>
								</div>
							</Col>
						</Row>
						{invitationData.showEntryHour && (
							<Row>
								<Col md="12">
									<div className="grey-box justify-content-center py-4">
										<div className="info text-center">
											<div className="tit">
												Apertura de puertas a las{' '}
												{invitationEvent?.horaApertura}h
											</div>
											<div className="subtit">
												No se podrá acceder a la sala después de las{' '}
												{invitationEvent?.horaLimite}h
											</div>
										</div>
									</div>
								</Col>
							</Row>
						)}
					</>
				)}
				{!previewEntrada && (
					<Row className="gest-preview mb-4">
						<Col md="12">
							{invitationData?.textoLimiteConfirm && (
								<p>{invitationData?.textoLimiteConfirm}</p>
							)}
							<Button className="mt-4 btn-plain-blue" type="button">
								Gestionar invitación
							</Button>
						</Col>
					</Row>
				)}

				<Row className="sign-preview mt-5">
					<Col md="12">
						<div className="row-sign">
							<div className="logo-mds">
								<Image
									src={invitationData?.companyLogo?.logoFooter}
									onErrorImageWidth={38}
									alt="logo footer"
									className=""
								/>
							</div>
							<div className="sign-text">
								<div className="rmt">{invitationData?.firmaCargo}</div>
								<div className="tel">{invitationData?.firmaTelefono}</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<div className="preview-legal">{replaceTextoLegal}</div>
		</div>
	);
};

export default InvitationMailPreview;
