import React, { useState } from 'react';
import _ from 'lodash';

import { FormGroup, Label } from 'reactstrap';
import { COLORS_NOCOLOR } from 'utils/helpers';

const InputColor = ({
	compact,
	label,
	colorZone,
	colorName,
	idValue,
	nameValue,
	colors = COLORS_NOCOLOR,
	//Formik
	field,
	form,
	//Others
	classContainer = '',
	...props
}) => {
	const error = form && form.errors && _.get(form.errors, field.name);
	const isDisabled = props.disabled ? true : false;

	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const handleClick = () => {
		setDisplayColorPicker(!displayColorPicker);
	};

	const handleClose = () => {
		setDisplayColorPicker(false);
	};

	return (
		<div
			className={`input-type-color ${
				compact ? 'is-compact' : ''
			} ${classContainer}`}
		>
			<FormGroup
				className={`form-group ${isDisabled ? ' disabled' : ''}${
					error && typeof error === 'string' ? ' has-error' : ''
				}`}
			>
				{label && <Label>{label}</Label>}
				<div className="custom-color-picker">
					<div className="custom-color-picker__swatch" onClick={handleClick}>
						<div
							className={`custom-color-picker__color${
								field.value === 'transparent' ? ' transparent' : ''
							}`}
							style={{
								backgroundColor: field.value,
								borderColor: field.value !== 'transparent' && field.value,
							}}
						></div>
						<div className="custom-color-picker__sublabel">
							<div className="info">
								<div className="color-zone">{colorZone}</div>
								<div className="color-name">{colorName}</div>
							</div>
							<div className="color-arrow">
								<i className="fticon-arrow-down"></i>
							</div>
						</div>
					</div>
					{displayColorPicker ? (
						<div className="custom-color-picker__popover">
							{/* <div
								className="custom-color-picker__cover"
								onClick={handleClose}
							/> */}
							<div className="custom-color-picker__selector">
								<div className="circle-picker">
									{colors &&
										colors.map((color, index) => (
											<span key={index}>
												<div
													className={`color ${
														color === 'transparent' ? 'transparent' : ''
													} ${
														field && field.value && field.value === color
															? 'active'
															: ''
													}`}
													style={{ backgroundColor: color }}
													onClick={() => {
														form.setFieldValue(field.name, color);
														form.setFieldTouched(field.name, true);
														idValue && form.setFieldValue(nameValue, idValue);
														handleClose();
													}}
												></div>
											</span>
										))}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</FormGroup>
		</div>
	);
};

export default InputColor;
