import React, { useRef } from 'react';
import _ from 'lodash';

import { Col, Row, Button } from 'reactstrap';

//componentes
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';

//forms
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

//notifications
import {
	unsavedChangesModal,
	bulkUpdateOptions,
} from 'components/common/modals/SwalModals';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const InvitadoBulkUpdate = ({
	isOpen,
	invitadosSelected = [],
	procedencias = [],
	closeModalFn,
	tipoAnunciante,
	saveModalFn,
	deleteModalFn,
}) => {
	const formikRef = useRef();

	const initialBulkData = {
		sitting: null,
		photocall: null,
		esVIP: null,
		procedencia: null,
		numSitting: '0',
		numNoSitting: '0',
	};

	const closeModal = () => {
		if (formikRef.current && formikRef.current.dirty) {
			unsavedChangesModal(() => closeModalFn());
		} else {
			closeModalFn();
		}
	};

	const evalFieldUpdate = (action, setFieldFn) => {
		if (action === 'sitting') {
			bulkUpdateOptions(
				setFieldFn,
				'Al modificar el sitting, este ',
				'excepto a aquellos que tengan una invitación confirmada.'
			);
		} else if (action === 'photocall') {
			bulkUpdateOptions(setFieldFn, 'Al modificar el photocall, este');
		} else if (action === 'esVIP') {
			bulkUpdateOptions(setFieldFn, 'Al modificar el destacado, este');
		} else if (action === 'procedencia') {
			bulkUpdateOptions(setFieldFn, 'Al modificar la procedencia, esta');
		}
	};

	const bulkUpdateContacts = (values) => {
		const formProcedencia = values.procedencia
			? values.procedencia.__isNew__
				? _.omit(values.procedencia, ['value'])
				: values.procedencia
			: null;

		const updateInvitados = {
			updateSitting: !_.isNull(values.sitting) ? true : false,
			//sittingValue: values.sitting || false,
			numSitting: values.numSitting,
			numNoSitting: values.numNoSitting,
			updatePhotocall: !_.isNull(values.photocall) ? true : false,
			photoCallValue: values.photocall || false,
			updateVip: !_.isNull(values.esVIP) ? true : false,
			vipValue: values.esVIP || false,
			updateProcedencia: !_.isNull(values.procedencia) ? true : false,
			procedenciaValue: formProcedencia || null,
			invitados: invitadosSelected,
		};

		saveModalFn(updateInvitados);
	};

	const MySwal = withReactContent(Swal);
	const removeGuestBulk = (invitadosSelected) => {
		MySwal.fire({
			title: `Estás a punto de eliminar a ${invitadosSelected.length} invitados`,
			text: 'Solo se podrán eliminar aquellos que no tengan una invitación enviada.',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const deleteInvitados = {
					invitados: invitadosSelected,
				};
				deleteModalFn(deleteInvitados);
			}
		});
	};

	const validationSchema = Yup.object().shape({
		sitting: Yup.boolean().nullable(),

		numSitting: Yup.string().when('sitting', {
			is: true,
			then: (schema) =>
				schema
					.transform((value) => (value === '' ? '0' : value))
					.required('El campo es obligatorio')
					.min(0, 'El valor mínimo es 0')
					.test(
						'sum-validation',
						'La suma de numSitting y numNoSitting debe ser mayor a 0',
						function (value) {
							const { numNoSitting } = this.parent;
							return parseInt(value) + parseInt(numNoSitting) > 0;
						}
					),
			otherwise: (schema) => schema.notRequired(),
		}),

		numNoSitting: Yup.string().when('sitting', {
			is: true,
			then: (schema) =>
				schema
					.transform((value) => (value === '' ? '0' : value))
					.required('El campo es obligatorio')
					.min(0, 'El valor mínimo es 0')
					.test(
						'sum-validation',
						'La suma de numSitting y numNoSitting debe ser mayor a 0',
						function (value) {
							const { numSitting } = this.parent;
							return parseInt(value) + parseInt(numSitting) > 0;
						}
					),
			otherwise: (schema) => schema.notRequired(),
		}),
	});

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize
			initialValues={initialBulkData}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={validationSchema}
			onSubmit={bulkUpdateContacts}
		>
			{({ values, setFieldValue, dirty }) => {
				return (
					<div className={`overlay-bottom-dashboard ${isOpen ? 'show' : ''}`}>
						<Form autoComplete="off">
							<div className="modal-header">
								<div className="title">
									Edición múltiple
									<span>{invitadosSelected.length} invitados</span>
								</div>

								{dirty ? (
									<div className="action-buttons">
										<Button
											type="button"
											onClick={closeModal}
											className="btn-sec-light"
										>
											Cancelar
										</Button>
										<Button type="submit" className="btn-sec-dark">
											Guardar
										</Button>
									</div>
								) : (
									<div className="close" onClick={closeModal}>
										<i className="fticon-close"></i>
									</div>
								)}
							</div>
							<div className="modal-body pt-3 px-2">
								<Row className="mt-2 mx-0">
									<Col md="2">
										{!_.isNull(values.sitting) ? (
											<div className="fields-sitting">
												<Field
													component={InputForm}
													name="numSitting"
													label="Con sitting"
													placeholder="0"
													type="number"
													maxLength={3}
													min={0}
												/>

												<Field
													component={InputForm}
													name="numNoSitting"
													label="Sin sitting"
													placeholder="0"
													type="number"
													maxLength={3}
													min={0}
												/>
											</div>
										) : (
											<div className="input-row">
												<div className="icon">
													<i className="fticon-sitting"></i>
												</div>
												<div
													className="editing"
													onClick={() =>
														evalFieldUpdate('sitting', () =>
															setFieldValue('sitting', true)
														)
													}
												>
													<div>Sitting</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											</div>
										)}
									</Col>
									<Col md="2">
										<div className="input-row">
											<div className="icon">
												<i className="fticon-camera"></i>
											</div>
											{!_.isNull(values.photocall) ? (
												<div className="field">
													<Field
														component={InputForm}
														baseLabel
														type="switch"
														label={'Photocall'}
														name="photocall"
														reverse
													/>
												</div>
											) : (
												<div
													className="editing"
													onClick={() =>
														evalFieldUpdate('photocall', () =>
															setFieldValue('photocall', false)
														)
													}
												>
													<div>Photocall</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											)}
										</div>
									</Col>
									<Col md="2">
										<div className="input-row">
											<div className="icon">
												<i className="fticon-star"></i>
											</div>
											{!_.isNull(values.esVIP) ? (
												<div className="field">
													<Field
														component={InputForm}
														baseLabel
														type="switch"
														label={'Destacado'}
														name="esVIP"
														reverse
													/>
												</div>
											) : (
												<div
													className="editing"
													onClick={() =>
														evalFieldUpdate('esVIP', () =>
															setFieldValue('esVIP', false)
														)
													}
												>
													<div>Destacado</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											)}
										</div>
									</Col>
									<Col md="2">
										{!_.isNull(values.procedencia) ? (
											<Field
												label="Procedencia"
												placeholder="Procedencia"
												name="procedencia"
												options={procedencias}
												component={InputSelect}
												className="input-create w-arrow"
												menuPlacement="top"
												createLabel={'Crear nueva'}
												isClearable
												canCreate
											/>
										) : (
											<div className="input-row">
												<div className="icon">
													<i className="fticon-user"></i>
												</div>
												<div
													className="editing"
													onClick={() =>
														evalFieldUpdate('procedencia', () =>
															setFieldValue('procedencia', false)
														)
													}
												>
													<div>Procedencia</div>
													<div>
														<i className="fticon-edit"></i>
													</div>
												</div>
											</div>
										)}
									</Col>
									<Col md="4" className="d-flex align-items-center">
										<Button
											onClick={() => {
												removeGuestBulk(invitadosSelected);
											}}
											className="btn-prim-light"
										>
											Eliminar invitados
										</Button>
									</Col>
								</Row>
							</div>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

export default InvitadoBulkUpdate;
