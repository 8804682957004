import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRouteMatch } from 'react-router-dom';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//services
import * as eventsApi from 'api/eventsApi';

//bs
import {
	Button,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';

//components
import TabsPage from 'components/common/elems/TabsPage';
import WhiteBox from 'components/common/elems/WhiteBox';
import TableLoader from 'components/loaders/TableLoader';
import EntradaPreviewModal from './modals/EntradaPreviewModal';
import EventDBnuevaEntrada from './EventDBnuevaEntrada';
import LoadingLayer from 'components/loaders/LoadingLayer';

// helpers
import { parseDateTimeFromString, statusSentClass } from 'utils/helpers';

const EventDBEntradas = ({ eventDataSimple }) => {
	let match = useRouteMatch('/evento/:idevent/entradas/:type?');
	const eventID = match.params ? match.params.idevent : null;
	const viewType =
		match && match.params.type !== undefined ? match.params.type : 'todas';

	const [pageLoading, setPageLoading] = useState(false);

	const MySwal = withReactContent(Swal);

	const tabsDef = [
		{ tabTitle: 'Todas', tabUrl: 'todas', tabCount: 0 },
		{ tabTitle: 'Borradores', tabUrl: 'borradores', tabCount: 0 },
		{ tabTitle: 'Enviadas', tabUrl: 'enviadas', tabCount: 0 },
	];

	const [tabList, setTabList] = useState(tabsDef);
	const [entradasData, setEntradasData] = useState([]);
	const [entradasTodas, setEntradasTodas] = useState([]);
	const [entradasEnviadas, setEntradasEnviadas] = useState([]);
	const [entradasBorrador, setEntradasBorrador] = useState([]);

	const [companiesLogoList, setCompaniesLogoList] = useState([]);

	const [entradasQR, setEntradasQR] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	const [modalNuevaOpen, setModalNuevaOpen] = useState(false);

	const [modalEntradaData, setModalEntradaData] = useState(null);
	const [showModalPDF, setshowModalPDF] = useState(false);

	useEffect(() => {
		getEntradas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		selectEntradas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const getEntradas = async () => {
		setIsLoading(true);
		const data = await eventsApi.getEntradas(eventID);
		if (data && data.succeeded) {
			setEntradasTodas(data.data.entradaList);
			setCompaniesLogoList(data.data.companyLogos);
			const invEnviadas = data.data.entradaList ? data.data.entradaList.filter(
				(x) => x.fechaEnvio !== null
			) : [];
			const invBorrador = data.data.entradaList ? data.data.entradaList.filter(
				(x) => x.fechaEnvio === null
			): [];
			setEntradasEnviadas(invEnviadas);
			setEntradasBorrador(invBorrador);
			setEntradasQR(data.data.totalQRCode);

			const updatedTabs = tabsDef.map((tab, index) => ({
				...tab,
				tabCount:
					index === 0
						? data.data.totalInvitations
						: index === 1
						? data.data.totalTemplate
						: data.data.totalSent,
			}));

			switch (viewType) {
				case 'borradores':
					setEntradasData(invBorrador);
					break;
				case 'enviadas':
					setEntradasData(invEnviadas);
					break;
				default:
					setEntradasData(data.data.entradaList);
			}
			setTabList(updatedTabs);
		}
		setIsLoading(false);
	};

	const selectEntradas = () => {
		switch (viewType) {
			case 'borradores':
				setEntradasData(entradasBorrador);
				break;
			case 'enviadas':
				setEntradasData(entradasEnviadas);
				break;
			default:
				setEntradasData(entradasTodas);
		}
	};

	const reloadEntradas = () => {
		getEntradas();
	};

	const toggleModalEntradaPreview = () => {
		setshowModalPDF(!showModalPDF);
	};

	const handelDuplicate = async (entradaID, eventoID) => {
		const data = await eventsApi.cloneEntrada(entradaID, eventoID);
		if (data && data.succeeded) {
			reloadEntradas();
			notify.success('La entrada se ha duplicado correctamente.');
		} else {
			notify.error(
				`Ha ocurrido un error duplicando la entrada. ${data.message}`
			);
		}
	};

	const handleRemove = (entradaID) => {
		MySwal.fire({
			title: '¿Quieres eliminar la entrada?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.deleteEntrada(entradaID);
				if (data && data.succeeded) {
					notify.success('La entrada se ha eliminado correctamente.');
					reloadEntradas();
				} else {
					notify.error('Ha ocurrido un error eliminando La entrada.');
				}
			}
		});
	};

	const showEntrada = (editData) => {
		setModalEntradaData(editData);
		toggleModalBar();
	};

	const toggleModalBar = () => {
		modalNuevaOpen && setModalEntradaData(null);
		setModalNuevaOpen(!modalNuevaOpen);
	};

	const handleSubmitForm = async (values, formProps) => {
		let formData = new FormData();
		setIsLoading(true);

		Object.keys(values).forEach((key) => {
			//filtra nulos
			if (values[key]) {
				if (_.isObject(values[key])) {
					//limpia objetos y file
					if (values[key].size) {
						formData.append(key, values[key]);
					} else {
						Object.keys(values[key]).forEach((subkey) => {
							//map subObjects
							if (_.isObject(values[key][subkey])) {
								Object.keys(values[key][subkey]).forEach((subkeyInto) => {																																		
									if (_.isArray(values[key][subkey][subkeyInto]) || _.isObject(values[key][subkey][subkeyInto]) ) {
										Object.keys(values[key][subkey][subkeyInto]).forEach((subkeyIntoAr) => {
											if (_.isObject(values[key][subkey][subkeyInto][subkeyIntoAr])) {

												Object.keys(values[key][subkey][subkeyInto][subkeyIntoAr]).forEach((subkeyInvitado) => {
													formData.append(
														`${key}[${subkey}][${subkeyInto}][${subkeyIntoAr}][${subkeyInvitado}]`,
														values[key][subkey][subkeyInto][subkeyIntoAr][subkeyInvitado]
													);
												});

											} else {
												formData.append(
													`${key}[${subkey}][${subkeyInto}][${subkeyIntoAr}]`,
													values[key][subkey][subkeyInto][subkeyIntoAr]
												);
											}	
										});
									} else {
										formData.append(
											`${key}[${subkey}][${subkeyInto}]`,
											values[key][subkey][subkeyInto]
										);
									}
								});
							} else {
								formData.append(`${key}[${subkey}]`, values[key][subkey]);
							}
						});
					}
				} else if (_.isArray(values[key])) {
					//limpia arrays
					values[key].map((elem, index) =>
						formData.append(`${key}[${index}]`, values[key][index])
					);
				} else {
					//single value
					formData.append(key, values[key]);
				}
			} else {
				if (key === 'firmaTextoLegal') formData.append(key, values[key]);
			}
		});

		var data = await eventsApi.setEntrada(formData);
		if (data && data.succeeded) {
			setModalEntradaData(data.data);
			reloadEntradas();
		} else {
			notify.error(`Ha ocurrido un error creando la entrada. ${data.message}`);
		}
		setIsLoading(false);
	};

	const handleSendEntrada = (totalDest, entradaID) => {
		MySwal.fire({
			title: `Estás a punto de enviar las entradas a ${totalDest} contacto${
				totalDest === 1 ? '' : 's'
			}`,
			text: 'Por favor, asegúrate de haberla comprobado y revisado cuidadosamente. No se podrá cancelar el envío una vez se haya iniciado.',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			confirmButtonText: 'Enviar entradas',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				container: 'send-invitation-modal',
				confirmButton: 'btn-sec-dark btn',
				cancelButton: 'btn-sec-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await eventsApi.sendEntrada(entradaID);
				if (data && data.succeeded) {
					notify.success('Las entradas se han enviado correctamente.');
					toggleModalBar();
					reloadEntradas();
				} else {
					notify.error(
						`Ha ocurrido un error enviando las entradas. ${data.message}`
					);
				}
			}
		});
	};

	return pageLoading ? (
		<LoadingLayer visible={pageLoading} />
	) : (
		<div className="dashboard-page dashboard-invitaciones">
			<Row className="header-dash">
				<Col md="8">
					<h3>Entradas</h3>
				</Col>
				<Col md="4" className="text-end">
					<Button
						className="btn-prim-light mt-1 me-3"
						onClick={() => toggleModalEntradaPreview()}
					>
						Previsualizar entrada
					</Button>
					<Button
						className="btn-prim-dark mt-1"
						onClick={() => toggleModalBar()}
					>
						Nueva entrada
					</Button>
				</Col>
			</Row>
			<Container fluid className="main-container bg-gray">
				<Row className="row-qr-header">
					<Col lg="8">
						<TabsPage
							selectedTab={viewType}
							tabList={tabList}
							baseUrl={`evento/${eventID}/entradas`}
						/>
					</Col>
					<Col lg="4">
						<div className="div-qr">
							<div className="qrs-generados">
								<span className="texto-qr">
									<i className="fticon-qr"></i>
									<span className="pl-4 ms-4 me-4">QR generados</span>
									<span>
										<strong>{entradasQR}</strong>
									</span>
								</span>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<WhiteBox containerClass="user-list">
							<Row className="table-header mt-2 pb-3">
								<Col md="3" className="">
									<div>Entrada</div>
								</Col>
								<Col md="2" className="text-center">
									Etiqueta
								</Col>
								<Col md="1" className="text-center">
									Enviadas
								</Col>
								<Col md="1" className="text-center">
									QR
								</Col>
								<Col md="1" className="text-center">
									Abiertas
								</Col>
								<Col md="1" className="text-center">
									Rebotadas
								</Col>
								<Col md="2" className="text-center">
									Fecha de envío
								</Col>
							</Row>
							<Row>
								<Col md="12">
									{isLoading ? (
										<TableLoader />
									) : (
										<Col lg="12">
											{entradasData ? (
												entradasData.map((entrada, index) => {
													const color = _.isNull(entrada.fechaEnvio)
														? 'st-borrador'
														: entrada.estadoEnvio && entrada.estadoEnvio.estado
														? statusSentClass(entrada.estadoEnvio.estado)
														: entrada.fechaEnvio
														? 'st-enviada'
														: 'st-sinenviar';
													const textoEtiqueta = _.isNull(entrada.fechaEnvio)
														? 'Borrador'
														: entrada.estadoEnvio && entrada.estadoEnvio.estado
														? entrada.estadoEnvio.estado
														: entrada.fechaEnvio
														? 'Enviada'
														: 'Sin enviar';

													return (
														<Row
															key={index}
															className="table-row"
															onClick={() => showEntrada(entrada)}
														>
															<Col md="3" className="col-name">
																<div className="name">{entrada.nombre}</div>
															</Col>
															<Col md="2" className="text-center">
																<div
																	className={`sticker sticker-${color}`}
																>
																	{textoEtiqueta}
																</div>
															</Col>
															<Col md="1" className="text-center">
																<div className="name">
																	{entrada.totalEnviadas !== 0
																		? entrada.totalEnviadas
																		: '-'}
																</div>
															</Col>
															<Col md="1" className="text-center">
																<div className="name">
																	{entrada.totalQR !== 0
																		? entrada.totalQR
																		: '-'}
																</div>
															</Col>
															<Col md="1" className="text-center">
																<div className="name">
																	{entrada.totalAbiertas !== 0
																		? entrada.totalAbiertas
																		: '-'}
																</div>
															</Col>
															<Col md="1" className="text-center">
																<div className="name">
																	{entrada.totalRebotadas !== 0
																		? entrada.totalRebotadas
																		: '-'}
																</div>
															</Col>
															
															<Col md="2" className="text-center">
																<div className="date">
																	{entrada.fechaEnvio
																		? parseDateTimeFromString(
																				entrada.fechaEnvio,
																				'dd/MM/yy - HH:mm'
																		  )
																		: '-'}
																</div>
															</Col>
															<Col md="1" className="text-end">
																<UncontrolledDropdown
																	onClick={(e) => e.stopPropagation()}
																>
																	<DropdownToggle color="submenu">
																		<i className="fticon-submenu"></i>
																	</DropdownToggle>
																	<DropdownMenu end>
																		<DropdownItem
																			onClick={() =>
																				handelDuplicate(
																					entrada.id,
																					entrada.eventoId
																				)
																			}
																		>
																			Duplicar
																		</DropdownItem>
																		{entrada.fechaEnvio == null && (
																			<DropdownItem
																				onClick={() => handleRemove(entrada.id)}
																			>
																				Eliminar
																			</DropdownItem>
																		)}
																	</DropdownMenu>
																</UncontrolledDropdown>
															</Col>
														</Row>
													);
												})
											) : (
												<Row>
													<Col className="text-center py-5">
														No se han encontrado entradas
													</Col>
												</Row>
											)}
										</Col>
									)}
								</Col>
							</Row>
						</WhiteBox>
					</Col>
				</Row>
			</Container>
			{showModalPDF && (
				<EntradaPreviewModal
					isOpen={showModalPDF}
					toggleModal={toggleModalEntradaPreview}
					previewData={eventDataSimple}
				/>
			)}

			{modalNuevaOpen && (
				<EventDBnuevaEntrada
					modalNuevaOpen={modalNuevaOpen}
					toggleModalBar={toggleModalBar}
					entradaData={modalEntradaData}
					setEntradaData={setModalEntradaData}
					handleSubmitForm={handleSubmitForm}
					handleSendEntrada={handleSendEntrada}
					eventDataSimple={eventDataSimple}
					companyLogos={companiesLogoList}
				/>
			)}
		</div>
	);
};

export default EventDBEntradas;
