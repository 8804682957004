import React from 'react';

import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

const TabsPage = ({ tabList, selectedTab, baseUrl, updateSelectedFn, noUrlChange }) => {
	
	return (
		<Row className="pt-3 pb-4">
			<Col lg="12">
				<Nav className="inpage-nav">
					{tabList &&
						tabList.length > 0 &&
						tabList.map((tab, index) => (
							<NavItem key={index}>
								{noUrlChange ? (
									<NavLink
										onClick={() => updateSelectedFn(tab.tabUrl)}
										className={selectedTab === tab.tabUrl ? 'active' : ''}
									>
										{tab.tabTitle}
										{tab.tabCount > 0 && <span>{tab.tabCount}</span>}
									</NavLink>
								) : (
									<NavLink
										to={`/${baseUrl}/${tab.tabUrl}`}
										tag={RRNavLink}
										className={selectedTab === tab.tabUrl ? 'active' : ''}
									>
										{tab.tabTitle}
										{tab.tabCount > 0 && <span>{tab.tabCount}</span>}
									</NavLink>
								)}
							</NavItem>
						))}
				</Nav>					
			</Col>
		</Row>
	);
};

export default TabsPage;
