import { toast } from 'react-toastify';

const notification = (text, icon, closeDelay=3000) => {
   toast(text, {
      icon: () =>  icon && <i className={icon}></i>,
      autoClose: closeDelay
    });
}

export const info = (message) => {
   notification(message);
};

export const pending = (message) => {
   notification(message, "fticon-pending", 5000);
};

export const success = (message) => {
   notification(message, "fticon-confirm");
};

export const error = (message) => {
   notification(message, "fticon-state-cancel", 6000);
};