import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

//Components
import InputForm from 'components/common/forms/InputForm';
import InputSelect from 'components/common/forms/InputSelect';

//forms
import { Formik, Form, Field } from 'formik';

const ContactsFilter = ({ filterType = 'personas', disabled=false, tagList, submitFilter, companies, statusFilter=null, fromOrigin=null, vipFilter=null, invitFilter=null, entradaFilter=null, respuestaFilter=null, orderFilter=null  }) => {

	const fmFilterRef = useRef();

	const initialValues = {
		nombreCompleto: '',
		etiquetaId: null,
		etiqueta: [],
		empresaId: 0,
		empresa: {},
		esPersona: filterType === 'personas' ? true : false,
		procedenciaId: 0,
		procedencia: {}
	};

	//genericos
	useEffect(() => {
		if(!_.isNull(vipFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('esVIP',vipFilter);
			fmFilterRef.current.submitForm();
		}
	}, [vipFilter]);

	//específicos
	useEffect(() => {
		if(!_.isNull(orderFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('order',orderFilter);
			fmFilterRef.current.submitForm();
		}
	}, [orderFilter]);

	useEffect(() => {
		if(!_.isNull(invitFilter) && fmFilterRef.current) {
			invitFilter?.label && fmFilterRef.current.setFieldValue('invitacion',invitFilter.value);
			invitFilter?.label && fmFilterRef.current.submitForm();
		}
	}, [invitFilter]);

	useEffect(() => {
		if(!_.isNull(entradaFilter) && fmFilterRef.current) {
			entradaFilter?.label && fmFilterRef.current.setFieldValue('entrada',entradaFilter.value);
			entradaFilter?.label && fmFilterRef.current.submitForm();
		}
	}, [entradaFilter]);

	useEffect(() => {
		if(!_.isNull(respuestaFilter) && fmFilterRef.current) {
			respuestaFilter?.label && fmFilterRef.current.setFieldValue('confirmacion',respuestaFilter.value);
			respuestaFilter?.label && fmFilterRef.current.submitForm();
		}
	}, [respuestaFilter]);

	useEffect(() => {
		if (!_.isNull(statusFilter) && fmFilterRef.current) {
			fmFilterRef.current.setFieldValue('estado', statusFilter);
			fmFilterRef.current.submitForm();
		}
	}, [statusFilter]);
 
	return (
		<Formik
			innerRef={fmFilterRef}
			enableReinitialize
			initialValues={initialValues}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={submitFilter}
		>
			{({ handleSubmit, setFieldValue }) => (
				<>
					<Form
						autoComplete="off"
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSubmit();
							}
						}}
					>
						<div className="contact-filters">
							{filterType === 'personas' ? (
								<Row>
									<Col md={!fromOrigin ? 4 : 3}>
										<Field
											label="Nombre y apellidos"
											placeholder="Nombre y apellidos"
											name="nombreCompleto"
											component={InputForm}
											isClearable
											extraClearFunction={handleSubmit}
											disabled={disabled}
										/>
									</Col>
									<Col md="3">
										<Field
											label="Etiqueta"
											placeholder="Etiqueta"
											name="etiquetaId"
											options={tagList}
											component={InputSelect}
											onChange={(e) => {
												setFieldValue('etiquetaId', e);
												handleSubmit();
											}}
											isClearable
											isMulti
											selectables
											canSearch
											grouped
											disabled={disabled}
										/>
									</Col>
									<Col md={!fromOrigin ? 3 : 2}>
										<Field
											label="Empresa"
											placeholder="Empresa"
											name="empresa"
											options={companies}
											component={InputSelect}
											onChange={(e) => {
												setFieldValue('empresaId', e ? e.value : 0);
												handleSubmit();
											}}
											isClearable
											canSearch
											disabled={disabled}
										/>
									</Col>
									{fromOrigin && (
										<Col md="2">
										<Field
											label="Procedencia"
											placeholder="Procedencia"
											name="procedencia"
											options={fromOrigin}
											component={InputSelect}
											onChange={(e) => {
												setFieldValue('procedenciaId', e ? e.value : 0);
												handleSubmit();
											}}
											isClearable
											canSearch
											disabled={disabled}
										/>
									</Col>
									)}
								</Row>
							) : (
								<Row>
									<Col md="4">
										<Field
											label="Nombre de la productora"
											placeholder="Nombre de la productora"
											name="nombreCompleto"
											component={InputForm}
											isClearable
											extraClearFunction={handleSubmit}
											disabled={disabled}
										/>
									</Col>
									<Col md="3">
										<Field
											label="Etiqueta"
											placeholder="Etiqueta"
											name="etiquetaId"
											options={tagList}
											component={InputSelect}
											onChange={(e) => {
												setFieldValue('etiquetaId', e);
												handleSubmit();
											}}
											isClearable
											isMulti
											selectables
											grouped
											canSearch
											disabled={disabled}
										/>
									</Col>
								</Row>
							)}
						</div>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default ContactsFilter;
