import React from 'react';
import { Col, Row } from 'reactstrap';

const PageHeader = ({ title,className='', ...props }) => {
	return (
		<Row className={`page-header ${className}`}>
			<Col md="6">
				<h1>{title || '404'}</h1>
			</Col>
			<Col md="6" className='text-end'>
            {props.children}
			</Col>
		</Row>
	);
};

export default PageHeader;
