import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import Image from './Image';

//helpers
import { parseDateTimeFromString } from 'utils/helpers';

const ItemEvent = ({ item, onClickFn, onActivate, ...props }) => {

	const onActivateFake = async () => {
	};

	return (
		<Col md="2" className='mb-4'>
			{item.activo || item.eventoPasado? (
				<Card className="event-item" onClick={onClickFn}>
					<div className="header-image">
						{item.urlImagen ? (
							<Image
								src={item.urlImagen ? item.urlImagen : 'no-image'}
								onErrorImageWidth={38}
								alt={item.nombre}
								className="card-img-top"
							/>
						) : (
							<i className="fticon-image"></i>
						)}
						
					</div>
					<CardBody>
						<div className="event-info">
							<div className="event-name">{item.nombre}</div>
							<div className="event-date">
								{item.fechaEvento &&
									parseDateTimeFromString(item.fechaEvento, 'dd-MM-yyyy')}
								<span>{item.horaEvento}</span>
							</div>
							<div className="event-site">
								{item.eventoLugar && item.eventoLugar.label}
							</div>
						</div>
						{item.eventoPasado && (
							<div className="event-past">
								<div className="asist">Asistencia</div>
								<div
									className={`asist-data ${item.asistencia === 100 ? 'tx-sucs-dark' : 'tx-pend'
										}`}
								>
									{item.asistencia} %
								</div>
							</div>
						)}
					</CardBody>
				</Card>
			):(
			<Card className="event-item-inactive">
				<div className="header-image">
					{item.urlImagen ? (
						<Image
							src={item.urlImagen ? item.urlImagen : 'no-image'}
							onErrorImageWidth={38}
							alt={item.nombre}
							className="card-img-top"
							onClick={onClickFn}
						/>
					) : (
						<i className="fticon-image"></i>
					)}
				</div>
				<CardBody>
					<div className="event-info">
						<div className="event-name">{item.nombre}</div>
						<div className="event-site">
							Este evento está inactivo
						</div>
						<div className="activate-event">
							<div className="activar-evento">Activar evento</div>
							<div className="Rectngulo-93" onClick={onActivate}>
								<input type="radio" className="check-activate" value="true" checked="" onChange={onActivateFake} />
								<span className="slider invers"></span>
							</div>
						</div>
					</div>				
					
				</CardBody>
			</Card>
			)}
			
		</Col>
	);
};

export default ItemEvent;
