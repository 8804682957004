import React from 'react';
import {
	Navbar,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

const NavBar = ({ isAdmin }) => {
	return (
		<div>
			<Navbar color="main-bar" light expand="md">
				<NavbarBrand to="/" tag={RRNavLink} className="logo">
					<div className="logo-full"></div>
				</NavbarBrand>
				<Nav className="ml-auto" navbar>
					<NavItem>
						<NavLink to="/eventos" tag={RRNavLink}>
							Eventos
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="/contactos" tag={RRNavLink}>
							Contactos
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="/lugares" tag={RRNavLink}>
							Lugares
						</NavLink>
					</NavItem>
					{isAdmin && (
						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle nav>Administración</DropdownToggle>
							<DropdownMenu>
								<DropdownItem to="/usuarios" tag={RRNavLink}>
									Usuarios
								</DropdownItem>
								{/* <DropdownItem to="/gestores" tag={RRNavLink}>
									Gestores
								</DropdownItem> */}
								<DropdownItem to="/auditoria" tag={RRNavLink}>
									Auditoría
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					)}
				</Nav>
			</Navbar>
		</div>
	);
};

export default NavBar;
