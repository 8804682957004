import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
	Col,
	Row,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';

//services
import * as sitesApi from 'api/sitesApi';
import * as mapsApi from 'api/mapasApi';
import { colorToClass, classCssToColor } from 'utils/helpers';

//notifications
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as notify from 'utils/notify';

//Components
import PageHeader from 'components/common/elems/PageHeader';
import WhiteBox from 'components/common/elems/WhiteBox';
import TableLoader from 'components/loaders/TableLoader';
import Medal from 'components/common/elems/Medal';
import SitesForm from './SitesForm';
import SiteButton from './SiteButton';
import TabsPage from 'components/common/elems/TabsPage';
import Sticker from 'components/common/elems/Sticker';
import MapsForm from './MapsForm';
import MapsFilter from './MapsFilter';

const SitesPage = () => {
	let match = useRouteMatch('/lugares/:type');
	const viewType = match ? match.params.type : 'lugares';

	const history = useHistory();

	const tabsDef = [
		{ tabTitle: 'Lugares', tabUrl: 'lugares', tabCount: 0 },
		{ tabTitle: 'Mapas', tabUrl: 'mapas', tabCount: 0 },
	];

	const [modalBarOpen, setModalBarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [modalData, setModalData] = useState({});
	const [modalEdit, setModalEdit] = useState(false);

	const [sitesList, setSitesList] = useState([]);

	const [mapList, setMapList] = useState([]);
	//const [mapListSelect, setMapListSelect] = useState([]);
	const [tagList, setTagList] = useState();
	const [currentFilter, setCurrentFilter] = useState({ pagina: 1 });

	const [maptagsList, setMapTagsList] = useState();

	const MySwal = withReactContent(Swal);

	useEffect(() => {
		getListActiveTab(viewType);
		getTags();
		getMapTags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewType]);

	const getListActiveTab = async (
		typeTab,
		filters = currentFilter,
		more = false
	) => {
		!more && setIsLoading(true);
		const data =
			typeTab === 'lugares'
				? await sitesApi.getSites()
				: await mapsApi.getMapList(filters);

		if (typeTab === 'lugares') {
			more
				? setSitesList(_.concat(sitesList, data.data))
				: setSitesList(data.data);
				setCurrentFilter({ pagina: 1 });
		} else if (typeTab === 'mapas') {
			more ? setMapList(_.concat(mapList, data.data)) : setMapList(data.data);
		}

		!more && setIsLoading(false);
	};

	const reloadPage = () => {
		getTags();
		getMapTags();
		getListActiveTab(viewType);
	};

	const toggleModalBar = () => {
		setModalBarOpen(!modalBarOpen);
	};

	const toggleModalEdit = (val) => {
		setModalEdit(val);
	};

	const getMapTags = async () => {
		const data = await sitesApi.getMapTags();
		setMapTagsList(data.data);
	};

	const handleRemoveSite = (siteID) => {
		MySwal.fire({
			title: '¿Quieres eliminar el lugar seleccionado?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await sitesApi.deleteSite(siteID);
				if (data && data.succeeded) {
					notify.success('El lugar se ha eliminado correctamente.');
					reloadPage();
				} else {
					notify.error(
						`Ha ocurrido un error eliminando el lugar. ${data.message}`
					);
				}
			}
		});
	};

	const showSite = (data, edit) => {
		const parsedAccesos =
			data.accesos && data.accesos.length > 0
				? data.accesos.map((acceso) => {
						const newSalas = acceso.salas.map((sala) => {
							const newAreas =
								sala.mapa && sala.mapa.areas && sala.mapa.areas.length > 0
									? sala.mapa.areas.map((area) => ({										
											...area,
											color: {
												...area.color,
												hex: area.color == null ? 'transparent' : classCssToColor(area.color.claseCss),
											},
									  }))
									: [];

							return {
								...sala,
								color: {
									...sala.color,
									hex: classCssToColor(sala.color.claseCss),
								},
								etiquetaTemp:sala.mapa.mapaEtiquetas[0].etiqueta || null,
								mapa: { ...sala.mapa, areas: newAreas },
							};
						});

						return { ...acceso, salas: newSalas };
				  })
				: [];

		const updatedValues = {
			...data,
			accesos: parsedAccesos,
		};

		setModalData(updatedValues);

		setModalEdit(edit);
		setModalBarOpen(true);
	};

	const handleSubmitForm = async (values, formProps) => {
		const parsedAccesos = values.accesos.map((acceso) => {
			const newSalas = acceso.salas.map((sala) => {
				const updatedAreas =
					sala.mapa && sala.mapa.areas && sala.mapa.areas.length > 0
						? sala.mapa.areas.map((area) => ({
								...area,
								color: {
									...area.color,
									claseCss: colorToClass(area.color.hex),
								},
						  }))
						: [];

				return {
					...sala,
					color: { claseCss: colorToClass(sala.color.hex) },
					mapa: { ...sala.mapa, areas: updatedAreas },
				};
			});

			return { ...acceso, salas: newSalas };
		});

		const updatedValues = {
			...values,
			accesos: parsedAccesos,
		};

		const data = await sitesApi.setSite(updatedValues);
		if (data && data.succeeded) {
			if (values.reload) {
				formProps.resetForm({ values: defModelFormMapa });
				setModalData({});
			} else {
				setModalBarOpen(false);
			}

			reloadPage();
			notify.success(
				`El lugar se ha ${values.id ? 'actualizado' : 'creado'} correctamente.`
			);
		} else {
			notify.error(
				`Ha ocurrido un error ${
					values.id ? 'actualizando' : 'creando'
				} el lugar. ${data.message}`
			);
		}
	};

	const handleSubmitFormMap = async (values, formProps) => {
		const formEtiquetas = values.mapaEtiquetas
			? values.mapaEtiquetas.map((item) =>
					item.__isNew__ ? _.omit(item, ['value']) : item
			  )
			: null;
		if (formEtiquetas == null || (formEtiquetas && formEtiquetas.length===0)) {
			notify.error(`Tiene que seleccionar una etiqueta para poder guardar `);
			return;
		}

		const updatedValues = {
			...values,
			etiquetas: formEtiquetas,
			mapaEtiquetas: [],
		};

		const data = await mapsApi.saveBasicMap(updatedValues);

		if (data && data.succeeded) {
			if (values.transfer) {
				history.push(`/mapa/${data.data.value}`);
			} else {
				setModalBarOpen(false);
				reloadPage();
			}
			notify.success(`El mapa se ha ${values.value ? 'actualizado' : 'creado'} correctamente.`);
		} else {
			notify.error(
				`Ha ocurrido un error ${
					values.value ? 'actualizando' : 'creando'
				} el mapa. ${data.message}`
			);
		}
	};

	const searchMaps = async (filters = currentFilter) => {
		setIsLoading(true);
		const data = await mapsApi.getMapList(filters);
		setMapList(data.data);
		setCurrentFilter(filters);
		setIsLoading(false);
	};

	const handleSubmitFilter = (filters) => {
		searchMaps({ ...filters, pagina: 1 });
	};

	const getTags = async () => {
		const data = await mapsApi.getTags();
		setTagList(data.data);
	};

	const defModelFormLugar = {
		nombre: '',
		direccion: '',
		accesos: [
			{
				nombre: '',
				direccion: '',
				salas: [
					{
						nombre: '',
						etiquetaTemp:null,
						mapa: {areas: []},
						color: { hex: '', claseCss: '' },
					},
				],
			},
		],
	};

	const defModelFormMapa = {
		label: '',
		mapaEtiquetas: []
	};

	const showMap = (data, edit) => {
		if (edit) {
			setModalData(data);
			setModalEdit(edit);
			setModalBarOpen(true);
		} else {
			history.push(`/mapa/${data.value}`);
		}
	};

	const handleRemoveMap = (idMap) => {
		MySwal.fire({
			title: '¿Quieres eliminar el Mapa?',
			cancelButtonText: 'Conservar',
			showCancelButton: true,
			confirmButtonText: 'Eliminar',
			focusConfirm: false,
			focusCancel: false,
			reverseButtons: true,
			buttonsStyling: false,
			customClass: {
				confirmButton: 'btn-prim-dark btn',
				cancelButton: 'btn-prim-light btn',
			},
		}).then(async (result) => {
			if (result && result.isConfirmed) {
				const data = await mapsApi.deleteMap(idMap);
				if (data && data.succeeded) {
					notify.success('El mapa se ha eliminado correctamente.');
					reloadPage();
				} else {
					notify.error(`Ha ocurrido un error eliminando el mapa. ${data.message}`);
				}
			}
		});
	};

	return (
		<div className="sites-page">
			{viewType === 'mapas' && (
				<PageHeader title={'Lugares'}>
					<Button className="btn-prim-dark" onClick={() => showMap({}, true)}>
						Nuevo mapa
					</Button>
				</PageHeader>
			)}

			{viewType === 'lugares' && (
				<PageHeader title={'Lugares'}>
					<Button onClick={() => showSite({}, true)} className="btn-prim-dark">
						Nuevo lugar
					</Button>
				</PageHeader>
			)}
			<TabsPage selectedTab={viewType} tabList={tabsDef} baseUrl="lugares" />
			{viewType === 'lugares' && (
				<Row className="mt-4">
					{isLoading ? (
						<TableLoader />
					) : (
						<Col lg="12">
							{sitesList && sitesList.length > 0 ? (
								sitesList.map((site, index) => {
									return (
										<div key={index} onClick={() => showSite(site, false)}>
											<WhiteBox containerClass="sites-list">
												<Row
													className={
														site.accesos && site.accesos.length > 0
															? 'site-name-row'
															: 'mb-0'
													}
												>
													<Col md="11" className="info">
														<Medal
															text={site.nombre}
															color={site.color.claseCss}
														/>
														<div className="box">
															<div className="nombre">{site.nombre}</div>
															<div className="aforo">
																<i className="fticon-user"></i>
																<span>{site.aforo}</span>
															</div>
														</div>
													</Col>
													<Col md="1" className="text-end">
														<UncontrolledDropdown
															onClick={(e) => e.stopPropagation()}
														>
															<DropdownToggle color="submenu">
																<i className="fticon-submenu"></i>
															</DropdownToggle>
															<DropdownMenu end>
																<DropdownItem
																	onClick={() => showSite(site, true)}
																>
																	Editar
																</DropdownItem>
																<DropdownItem
																	onClick={() => handleRemoveSite(site.id)}
																>
																	Eliminar
																</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													</Col>
												</Row>
												<Row>
													<Col md="12" className="site-rooms">
														{site.accesos &&
															site.accesos.length > 0 &&
															site.accesos.map(
																(acc) =>
																	acc.salas &&
																	acc.salas.length > 0 &&
																	acc.salas.map((sala, index) => (
																		<SiteButton
																			key={index}
																			roomName={sala.nombre}
																			roomCapacity={sala.aforo}
																		/>
																	))
															)}
													</Col>
												</Row>
											</WhiteBox>
										</div>
									);
								})
							) : (
								<WhiteBox>
									<Col className="text-center py-5">Sin resultados</Col>
								</WhiteBox>
							)}
						</Col>
					)}
				</Row>
			)}

			{viewType === 'mapas' && (
				<Row>
					<Col lg="12" className="mt-4">
						<WhiteBox containerClass="base-list">
							<Row className="pb-3">
								<Col md="12">
									<MapsFilter
										tagList={tagList}
										submitFilter={handleSubmitFilter}
									/>
								</Col>
							</Row>
							<Row className="table-header pb-3">
								<Col md="4">
									<div>Nombre del Mapa</div>
								</Col>
								<Col md="2" className="text-center">
									Etiqueta
								</Col>
								<Col md="2" className="text-center">
									Capacidad
								</Col>
								<Col md="2" className="text-left"></Col>
							</Row>
							{isLoading ? (
								<TableLoader />
							) : (
								<Col lg="12">
									{mapList && mapList.length > 0 ? (
										mapList.map((mapa, index) => {
											return (
												<Row key={index} className="table-row no-pointer">
													<Col md="4">{mapa.label}</Col>
													<Col className="text-center">
														{mapa.mapaEtiquetas &&
															mapa.mapaEtiquetas.length > 0 &&
															mapa.mapaEtiquetas.map((etq, index) => (
																<Sticker
																	key={index}
																	text={etq.etiqueta.label}
																	color={etq.etiqueta.color.claseCss}
																/>
															))}
													</Col>
													<Col md="2" className="col-icon">
														<i className="fticon-sitting"></i>
														<span>{mapa.seats}</span>
													</Col>
													<Col
														md="4"
														className="col-actions justify-content-end"
													>
														<div>
															<Button
																className="btn-sec-link btn-icon"
																onClick={(e) => showMap(mapa, false)}
															>
																<i className="fticon-map"></i>
															</Button>
															<Button
																className="btn-sec-link btn-icon"
																onClick={(e) => showMap(mapa, true)}
															>
																<i className="fticon-edit"></i>
															</Button>
															<Button
																className="btn-sec-link btn-icon"
																onClick={(e) => handleRemoveMap(mapa.value)}
															>
																<i className="fticon-trash"></i>
															</Button>
														</div>
													</Col>
												</Row>
											);
										})
									) : (
										<WhiteBox>
											<Col className="text-center py-5">Sin resultados</Col>
										</WhiteBox>
									)}
								</Col>
							)}
						</WhiteBox>
					</Col>
				</Row>
			)}

			{viewType === 'lugares' && modalBarOpen && (
				<SitesForm
					modalBarOpen={modalBarOpen}
					toggleModalBar={toggleModalBar}
					modalEdit={modalEdit}
					modalData={modalData}
					defModelForm={defModelFormLugar}
					handleSubmitForm={handleSubmitForm}
					//mapList={mapListSelect}
					toggleModalEdit={toggleModalEdit}
					mapTagsList={maptagsList}
				/>
			)}

			{viewType === 'mapas' && (
				<MapsForm
					modalBarOpen={modalBarOpen}
					toggleModalBar={toggleModalBar}
					modalData={modalData}
					defModelForm={defModelFormMapa}
					handleSubmitFormMap={handleSubmitFormMap}
					tagList={tagList}
				/>
			)}
		</div>
	);
};

export default SitesPage;
