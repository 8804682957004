import React, { useState, useRef } from 'react';
import _ from 'lodash';

//Components
import Image from '../elems/Image';
import WhiteBox from '../elems/WhiteBox';

//Controls
import { Button } from 'reactstrap';


const InputImageUpload = ({
	field_base64,
	field_filename,
	image,
	errorImageWidth = '35',
	//Formik
	field,
	form,
	...props
}) => {

	const [tmpImage, setTmpImage] = useState(null);

	const isStoredImage = typeof image === 'object' ? false : true;
	const hiddenFileInput = useRef(null);
	
	const error = field ? _.get(form.errors, field.name) : null;
	const hasImage = (tmpImage || isStoredImage) ? true : false

	const [fieldImage, setfieldImage] = useState(image);

	const handleClick = (e) => {
		e.preventDefault();
		hiddenFileInput.current.click();		
	};

	const deleteImage = async (e) => {
		e.preventDefault();
		await setTmpImage(null);
		setfieldImage(null);
		form.setFieldValue(field.name, null);
		form.setFieldValue(field_base64, null);
		form.setFieldValue(field_filename, null);
		hiddenFileInput.current.value = null;
	}

	const onFileChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			const f = e.target.files[0];
			form.setFieldValue(field.name, f);
		if (field_base64) {
			const reader = new FileReader();
			reader.onload = (_e) => {
				setTmpImage(_e.target.result);
				setfieldImage(_e.target.result);
			  
			  form.setFieldValue(field_base64, _e.target.result);
			};
			reader.readAsDataURL(f);
		  }
		  if (field_filename) {
			form.setFieldValue(field_filename, f.name);
		  }
		} else {
		  form.setFieldValue(field.name, null);
		  form.setFieldValue(field_base64, null);
		  form.setFieldValue(field_filename, null);
		}
	};

	return (
		<div className="form-group upload-image">
			<div className={`image-container${hasImage ? ' has-image':''}`}>
				<WhiteBox>
					<div className="noimage-cont">
						{tmpImage ? (
							<Image
								src={fieldImage || tmpImage}
								onErrorImageWidth={errorImageWidth}
								className="img-fluid"
							/>							
						) : isStoredImage ? (
							<Image
								src={image}
								onErrorImageWidth={errorImageWidth}
								className="img-fluid"
							/>
						) : (
							<i className='fticon-image'></i>
						)}
					</div>
				</WhiteBox>
				<div className="actions">
					<Button className="btn btn-prim-light" onClick={handleClick}>
						Subir imagen
					</Button>
					<Button className="btn btn-prim-light btn-icon" disabled={!hasImage} onClick={deleteImage}>
						<i className="fticon-trash"></i>
					</Button>
				</div>
			</div>

			<div className="ui-input">
				<input
					type="file"
					ref={hiddenFileInput}
					onChange={onFileChange}
					{...props}
				/>
			</div>
			<div className="ui-error d-none">{error}</div>
		</div>
	);
};

export default InputImageUpload;
