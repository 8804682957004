import React, { useState, useRef } from 'react';
import _ from 'lodash';

//Components

//Controls
import { Button } from 'reactstrap';

const InputUploadBtn = ({
	label = 'Seleccionar archivo',
   onChangeFn,
	//Formik
	field,
	form,
	noForm,
	...props
}) => {
	const hiddenFileInput = useRef(null);
	const [tmpFileName, setTmpFileName] = useState(null);

	const error = field ? _.get(form.errors, field.name) : null;

	const handleClick = (e) => {
		e.preventDefault();
		hiddenFileInput.current.click();
	};

	const onFileChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			const f = e.target.files[0];
			!noForm && form.setFieldValue(field.name, f);
			setTmpFileName(f.name);
         onChangeFn && onChangeFn(e);
		} else {
			!noForm && form.setFieldValue(field.name, null);
			setTmpFileName(null);
		}
	};

	return (
		<div className="form-group upload-button">

			<div className="actions">
				<Button className="btn btn-prim-dark" onClick={handleClick}>
					{label}
				</Button>
			</div>

         <div className="file-name">{tmpFileName}</div>

			<div className="ui-input">
				<input
					type="file"
					ref={hiddenFileInput}
					onChange={onFileChange}
					{...props}
				/>
			</div>
			<div className="ui-error d-none">{error}</div>
		</div>
	);
};

export default InputUploadBtn;
