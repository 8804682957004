import React from 'react';
import { Button, ModalBody, Row, Col } from 'reactstrap';

//forms
import { Form, Field } from 'formik';
import InputForm from 'components/common/forms/InputForm';

const MapEditAreaForm = ({
	toggleModal,
	areaData,
	areaIndex,
	isOpen,
	formikValues,
	formikSetField,
	updateRotation,
}) => {
	return (
		<>
			<div className={`overlay-bottom-map ${isOpen ? 'show' : ''}`}>
				<Form autoComplete="off">
					<div className="modal-header">
						<div className="title">Edición de la zona</div>
						<div className="action-buttons">
							<Button
								type="button"
								onClick={toggleModal}
								className="btn-sec-light"
							>
								Cancelar
							</Button>
							<Button type="submit" className="btn-sec-dark">
								Guardar
							</Button>
						</div>
					</div>

					<ModalBody>
						<div className="area-edit-form pt-4 px-4">
							<Row>
								<Col md="3">
									<Field
										label="Nombre de la zona*"
										placeholder="Nombre de la zona*"
										name="title"
										component={InputForm}
									/>
								</Col>

								<Col md="9">
									<div className="custom-inputs">
										{areaData.type && areaData.type.type === 'seat' && (
											<>
												<div className="box-input">
													<i className="fticon-sitting"></i>
													<Field
														component={InputForm}
														baseLabel
														type="switch"
														label={'Numerar filas automaticamente'}
														name="autonumericRows"
														reverse
													/>
												</div>

												<div className={`order-inputs ${formikValues.autonumericRows ? 'inputs-enabled':'inputs-disabled'}`}>
													<Button
														onClick={() => formikSetField('orderAsc', false)}
														className={`btn-inmap btn-icon ${
															!formikValues.orderAsc ? 'active' : ''
														}`}
													>
														<i className="fticon-sort-dsc"></i>
													</Button>
													<Button
														onClick={() => formikSetField('orderAsc', true)}
														className={`btn-inmap btn-icon ${
															formikValues.orderAsc ? 'active' : ''
														}`}
													>
														<i className="fticon-sort-asc"></i>
													</Button>
												</div>
											</>
										)}

										<div className="rotation-input">
											<Field
												label="Rotación"
												placeholder="Rotación"
												name="pos.z"
												component={InputForm}
												type="number"
												min="0"
												max="360"
												onChange={(e) => {
													formikSetField('pos.z', e.target.value);
													updateRotation(e.target.value, areaIndex);
												}}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</ModalBody>
				</Form>
			</div>
		</>
	);
};

export default MapEditAreaForm;
