import React from 'react';

import { NavLink as RRNavLink } from 'react-router-dom';

import { Col, Row, Button } from 'reactstrap';

const NotFoundPage = () => {
	return (
		<>
			<div className="denied-page">
				<Row>
					<Col lg="12" className="mt-2 mb-2">
						<div className='ball'><i className='fticon-close'></i></div>
						<h2>404</h2>
						<h4>Página no encontrada</h4>
						<Button to="/eventos" tag={RRNavLink} className="btn-prim-light mt-4">
							Volver
						</Button>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default NotFoundPage;
